import React, {useEffect, useState} from 'react'
import { ModelWrapper } from '../../../component/ModelWrapper'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { WorkOrderApplicationStatus } from 'sr/i18n/messages/setting'
import { useNavigate, useParams } from 'react-router-dom'
import { updateApplication } from 'sr/utils/api/cleaner/updateApplication'
import { toast } from 'react-toastify'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getApplication } from 'sr/utils/api/cleaner/getApplication'
import { addNotes } from 'sr/utils/api/company/addNotes'
import { getNotes } from 'sr/utils/api/company/getNotes'
import { updateApplyWorkOrder } from 'sr/utils/api/company/workorder/updateApplyWorkOrder'
import { createContractor } from 'sr/utils/api/company/workorder/createContractor'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { updateWorkorder } from 'sr/utils/api/company/workorder/updateWorkorder'
import { getContractor } from 'sr/utils/api/company/workorder/getContractor'
import { DownloadResume } from 'sr/helpers/functions/ApiFunction.tsx/DownloadResume'
import { Interested } from '../Interested'
export const WorkOrderRightSidebar = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [status, setstatus] = useState()
  const [id, setId] = useState<string>()
  const [notes, setNotes] = useState('')
  const params = useParams()
  const [companyId, setCompanyId] = useState()
  const navigate = useNavigate()
  const HandleModelByProps = (data) => {
    setShowModal(data)
   }
   useEffect(()=> {
    const getdetails = getUserDetails()
    setCompanyId(getdetails?.user_id)
   }, [])

   useEffect(()=> {
      const {id} = params
      setId(id)
   },[params])

   useEffect(()=> {
    console.log(props);
    
setstatus(props?.status)
   },[props?.status])

  // useEffect(()=> {
  //   if(id) {
  //   fetchNotes(undefined, props?.candidate?.id)
  //   }
  // }, [id])

   const intl = useIntl()

   
      const HandleApply = async (status)=> {
        if(status==='accept') {
          const params = new URLSearchParams()
        params?.append('cleaner_id', props?.candidate?.cleaner_id?._id)
        params.append('status!','deleted')
        params.append('company_id', companyId)
        const res = await getContractor(params)
        if(res?.success) {
          const workorderpayload = {
            id:id,
            type:'individual',
          }
            let workorderres;
          if(res?.data?.length>0) {
            workorderres = await updateWorkorder({contractor_id:res?.data[0]?.id, ...workorderpayload})
          }
          else {
            const PAYLOAD = {
              cleaner_id:props?.candidate?.cleaner_id?._id,
              company_id:companyId,
              status:'active'
           }
            const result = await createContractor(PAYLOAD)
            if(result?.success===true){
              workorderres = await updateWorkorder({contractor_id:result?.data?.id, ...workorderpayload})
            }
          }
          if(workorderres?.success===true) {
            const data = {
              id: props?.candidate?.id,    
              status:status
          }
          const resultworkorder = await updateApplyWorkOrder(data)
          if(resultworkorder?.success===true) {
            setstatus(resultworkorder?.data?.status)
            navigate(`/work-order-progress/${resultworkorder?.data?.workorder_id}`)
          }
        }
            
             
              }

              } 
            else {
              const data = {
                id: props?.candidate?.id,    
                status:status
            }
            const result = await updateApplyWorkOrder(data)
        if(result?.success===true) {
          setstatus(result?.data?.status)
        }
            }
      }
  return (
    <div className='bg-[#FAF9F8] py-10 px-5'>
      <p className='pb-2 text-gray-600'><FormattedMessage  id='RECRUITER.CANDIDATE.ACTION.INTERESTED.TITLE' defaultMessage='Interested?'/></p>
<Interested status={status} modelonClick = {()=> setShowModal(!showModal)} acceptClick={()=> HandleApply('accept')} rejectClick={()=> HandleApply('reject')}  activeClick = {()=> HandleApply('active')}>
               
                {showModal ? (
    <>
<ModelWrapper rightPosition = '-right-20' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps} showModel = {showModal}>
<ul className='px-3 pb-2 font-satoshiBold text-base font-bold text-[#595959]'>
<li className='py-3' onClick={()=> DownloadResume({resumeName:'bbnett.pdf', resumeKey:props?.resumeKey})}>
          <FormattedMessage
            id='RECRUITER.CANDIDATE.ACTION.DOWNLOAD_CV'
            defaultMessage='Download CV'
          />
        </li>
        <li onClick={()=> HandleApply('reject')} className='py-3'>
          <FormattedMessage
            id='RECRUITER.CANDIDATE.ACTION.REJECT_CANDIDATE'
            defaultMessage='Reject Candidate'
          />
        </li>
</ul>
</ModelWrapper>
</>): null }
</Interested>       
     <p className='mt-3 pb-2 text-gray-600'><FormattedMessage  id='RECRUITER.CANDIDATE.ACTION.HIRINGSTATUS.TITLE' defaultMessage='Hiring Status'/></p>
                <DropDown onChange= {(e)=> HandleApply(e?.target?.value)} dropdowndiv='mt-0' value={status} className='rounded-lg' >
                  {WorkOrderApplicationStatus?.map((item, index)=> (
                <option key={index} value={item?.value}>
          {item?.label}
        </option>
))}
                </DropDown>
           
<hr className='border border-gray-200 my-5' />
<div className='text-gray-600 text-sm pb-2'>{DateFormat(props?.candidate?.createdAt)}</div>
      </div>
  )
}
