import React, { useEffect, useState } from 'react'
import { MainWrapper } from '../component/MainWrapper'
import ClicksGraph from 'sr/helpers/ui-components/ClicksGraph'
import { Button } from 'sr/helpers';
import DropDown from 'sr/helpers/ui-components/DropDown.tsx';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs';
import Job from 'app/pages/component/types/Job';
import { getApplication } from 'sr/utils/api/cleaner/getApplication';
import { DateFormat } from 'sr/helpers/functions/DateFormat';
import { SocialShare } from 'app/pages/component/SocialShare';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { jobStatus } from "sr/i18n/messages/setting";
import { updateBasicDetails } from "sr/utils/api/cleaner/company/jobPost/updateBasicDetails";

export const PostedJobDetails = () => {
  const [id, setId] = useState('')
  const [job, setJob] = useState<Job | null>(null);
  const [applicant, setApplicant] = useState<[]>()
  const [candidateCount, setCandidateCount] = useState<{}>({})
  const [totalCandidate, setTotalCandidate] = useState(null)
  const [loader, setLoader] = useState(false)
  const param = useParams()
  useEffect(()=> {
    const {id} = param
    setId(id)
  },[])
  useEffect(()=> {
    const fetchJob = async ()=> {
      try {
      setLoader(true)
      const queryParams = new URLSearchParams();  
      if (id) queryParams.append('id', id.toString());
      const result = await getJobs(queryParams)
      if(result.success===true) {
          const queryParams = new URLSearchParams();
          queryParams.append('job_id', id.toString());
        const resultC = await getApplication(queryParams)
        if(resultC) {
            setApplicant(resultC?.data)
            setCandidateCount(resultC?.pagination?.statusCounts)
            setLoader(false)
        }
      setJob(result?.data)
      }
      else {
        setLoader(false)
      }
    } catch(error) {
      setLoader(false)
    }
    }
    if(id) {
    fetchJob()
    }
  },[id])
  const intl = useIntl()
  const JobDescriptionComponent = ({ jobDescription }) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
    );
  };

  useEffect(()=> {
    console.log(candidateCount);
    const countsArray = Object.values(candidateCount);
    const sum = countsArray.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
    setTotalCandidate(sum)
  },[candidateCount])
  
  const HandleJobStatus = async (e: any) => {
    const data = {
      id: id,
      status: e.target.value,
    };
    const res = await updateBasicDetails(data);
    if (res.success === true) {
      setJob(res?.data);
    }
  };

  const path = `/job-detail/${id}`
  const fullLink = `https://${process.env.REACT_APP_URL_DEV}${path}`
  const addressLine1 = job?.job_advanced_id?.reporting_address?.address_line_1;
  const addressLine2 = job?.job_advanced_id?.reporting_address?.address_line_2;
  const city = job?.job_advanced_id?.reporting_address?.city;
  return (
    <MainWrapper>
      <ContentWithSpinner loader={loader} height='h-screen'>
      <div className='py-3'>
    <div className='grid grid-cols-12 gap-4'>
   <div className='col-span-9 bg-white rounded-[20px] p-6'>
  <Link to='/jobs-listing' className='flex items-center text-[#2557A7] font-satoshiBold space-x-2'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6"><path d="M21 11.5a.5.5 0 00-.5-.5H6.622l3.598-3.598a.5.5 0 000-.707l-.707-.707a.5.5 0 00-.707 0l-5.659 5.658a.498.498 0 000 .708l5.659 5.658a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.707L6.622 13H20.5a.5.5 0 00.5-.5v-1z"></path></svg>
<span className='text-sm'>
  <FormattedMessage
    id='RECRUITER.POSTED.JOB.DETAILS.BACK.BUTTON'
    defaultMessage='Back to jobs'
  />
</span>

</Link>
<div className='flex items-center justify-between py-3'>
  <div>
  <h1 className='text-2xl font-satoshiBold'>{job?.job_title}</h1>
  <p>{addressLine1}{addressLine1 ? "," : ""} {addressLine2}{addressLine2 ? "," : ""} {city}</p>
  </div>

    </div>
    <hr  className='border border-neutral-200 mb-3'/>
    <div className='h-full'>
      <div>
      <h2 className='text-xl font-satoshiBold mb-3'> <FormattedMessage id="RECRUITER.POSTED.JOB.CANDIDATES.TITLE" defaultMessage="Candidates" />  </h2>
<div className='flex items-center justify-center space-x-6'>
<Button className='font-satoshiBold capitalize text-[#2557A7] w-full justify-center'
       hovertext = 'hover:text-[#2557A7]'
       color = 'neutral-200'
       bordersize='border'>
          <Link to={`/candidates/awaiting-reviews/${job?.id}`}>
        <div className='my-3'>
        <p><FormattedMessage id="RECRUITER.POSTED.JOB.CANDIDATES.AWAITING.REVIEWS" defaultMessage="Awaiting Reviews" />  </p>
       <span className='text-2xl font-satoshiBold'>{ candidateCount && candidateCount['awaiting reviews'] || 0}</span>
       </div>
       </Link>
       </Button>
      
       <Button className='font-satoshiBold w-full capitalize text-[#2557A7] justify-center'
       hovertext = 'hover:text-[#2557A7]'
       color = 'neutral-200'
       bordersize='border'
       >
              <Link to={`/candidates/active/${job?.id}`}>
        <div className='my-3'>
       <p><FormattedMessage id="RECRUITER.POSTED.JOB.CANDIDATES.TOTAL.TITLE" defaultMessage="Total (Excluding Rejected)" /></p>
      <span className='text-2xl font-satoshiBold'>{totalCandidate || 0}</span>
      </div>
      </Link></Button>
</div>

      </div>
      <div className='mt-3'>
        <h1 className='text-xl font-satoshiBold'><FormattedMessage id="JOBDETAILS.JOBDESCRIPTION" defaultMessage="Job Description" /></h1>
        <JobDescriptionComponent jobDescription={job?.job_description} />
        <br />
        <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold font-satoshiBold text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.BENIFITS'
                                    defaultMessage='Benefits'
                                    />:</h3>
                                     <div className='flex flex-wrap'>
                                    {job?.benefits?.map((item,index )=> (
                                        <div className='mr-2' key={index}>{item},</div>
                                 )   )}
                                 </div>
                                    
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold font-satoshiBold text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.JOBTYPE'
                                    defaultMessage='Job Type'
                                    />: </h3>
                                        <div className='flex flex-wrap'>
                                    {job?.job_type?.map((item,index )=> (
                                        <div className='mr-2' key={index}>{item},</div>
                                 )   )}
                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold font-satoshiBold text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.EMPLOYMENTTYPE'
                                    defaultMessage='Employment Type'
                                    />: </h3>
                                       <div className='flex flex-wrap'>
                                    {job?.employment_type?.map((item,index )=> (
                                        <div className='mr-2' key={index}>{item},</div>
                                 )   )}
                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold font-satoshiBold text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.SCHEDULE'
                                    defaultMessage='Schedule'
                                    />: </h3>
                                     <div className='flex flex-wrap'>
                                    {job?.schedule?.map((item,index )=> (
                                        <div className='mr-2' key={index}>{item},</div>
                                 )   )}
                                 </div>

                                 
                                    
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold font-satoshiBold text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.SUPPLEMENTALPAY'
                                    defaultMessage='Supplemental Pay'
                                    />: </h3>
                                     <div className='flex flex-wrap'>
                                    {job?.job_advanced_id?.supplemental_pay?.map((item,index )=> (
                                        <div className='mr-2' key={index}>{item},</div>
                                 )   )}
                                 </div></div>
      </div>
    </div>
   </div>

   <div className='col-span-3 bg-white rounded-[20px] p-5 h-fit'>
<div className='flex'>
   <Link className="text-center w-full rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" to={`/job-post/review/${job?.id}`}>{intl.formatMessage({id:'RECRUITER.POSTED.JOB.ACTION.EDITJOB', defaultMessage:'Edit Job'})}</Link>
   </div>
   <DropDown
      onChange={HandleJobStatus}
      value={job?.status}
      dropdowndiv="my-3"
      label={intl.formatMessage({
        id: "RECRUITER.POSTED.JOB.JOBSTATUS.TITLE",
        defaultMessage: "Job Status",
      })}
      labelMargin="mb-1"
      className="rounded-lg"
    >
      {jobStatus.map((item, index) => (
        <option key={index} value={item?.value}>
          {" "}
          {item?.label}
        </option>
      ))}
    </DropDown>
        <Link target="_blank" to={`/job-detail/${id}`}>
    <Button className='font-satoshiBold capitalize text-[#2557A7] w-full justify-center'
       hovertext = 'hover:text-[#2557A7]'
       color = 'neutral-200'
       bordersize='border'
       ><FormattedMessage
       id="RECRUITER.POSTED.JOB.VIEWPUBLICPAGE.BUTTON"
       defaultMessage="View Public Job Page"
     /></Button>
     </Link>
       <hr  className='border border-neutral-200 my-5'/>
       <div className='mb-5'>
        <h2 className='font-satoshiBold mb-4'><FormattedMessage id='RECRUITER.POSTED.JOB.DETAILS.TITLE' defaultMessage='Details' /></h2>
        <p className='mb-1'><span className='font-satoshiBold text-[#424242]'><FormattedMessage id='RECRUITER.POSTED.JOB.POSTED.TIME.TITLE' defaultMessage='Posted' />:</span> {DateFormat(job?.createdAt)} </p>
        <p className='mb-1'><span className='font-satoshiBold text-[#424242]'><FormattedMessage  id='RECRUITER.POSTED.JOB.DETAILS.CANDIDATES' defaultMessage='Candidates'/>:</span><Link className='text-[#2557A7] font-satoshiBold' to={`/candidates/job/${job?.id}`}> {applicant?.length} </Link></p>
       </div>
       {/* <Button className='font-satoshiBold capitalize text-[#2557A7] w-full justify-center'
       hovertext = 'hover:text-[#2557A7]'
       color = 'neutral-200'
       bordersize='border'
       > <FormattedMessage id="RECRUITER.POSTED.JOB.DETAILS.ADD_A_CANDIDATE.BUTTON" defaultMessage="Add A Candidate" />  </Button> */}
        {/* <hr className='border border-neutral-200 my-5' /> */}
        {/* <div className='mb-5'>
        <h2 className='font-satoshiBold mb-4'><FormattedMessage id="RECRUITER.POSTED.JOB.BUDGET.TITLE" defaultMessage="Budget" /></h2>
        <p className='mb-1'><span className='font-satoshiBold text-[#424242]'><FormattedMessage id="RECRUITER.POSTED.JOB.JOB.BUDGET.TITLE" defaultMessage="Job Budget" />:</span>  <FormattedMessage id="RECRUITER.POSTED.JOB.JOB.BUDGET.VALUE" defaultMessage="Not sponsered" />  </p>
       </div> */}
       {/* <Button className='font-satoshiBold capitalize text-[#2557A7] w-full justify-center'
       hovertext = 'hover:text-[#2557A7]'
       color = 'neutral-200'
       bordersize='border'
       > <FormattedMessage id="RECRUITER.POSTED.JOB.BUDGET.SPONSER_NOW.BUTTON" defaultMessage="Sponsor Now" />  </Button> */}
        <hr  className='border border-neutral-200 my-5'/>
        <div>
          <div className='w-full text-center'>
          <FormattedMessage id="RECRUITER.POSTED.JOB.BUDGET.SUGGESTED_TEXT" defaultMessage="Promote this job for more candidates:" />
          <div className='flex space-x-2 my-4 justify-center items-center'>
                                  <SocialShare fullLink={fullLink} title={job?.job_title}/>
          </div>
        </div></div>
   </div>
    </div>
    </div>
    </ContentWithSpinner>
    </MainWrapper>
  )
}
