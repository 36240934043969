import { toast } from "react-toastify";
import getCardDetail from "./getCardDetails";

interface ParamsType {
    cardnumberElement:any
    stripe:any
    elements:any
    clientSecret:string
    email:string
    customerId?:string
    setCardDetails?:(params:any)=> void
    setIsProcessing?:(params:any)=> void
    stripId:string
    cardcvv:any, 
    cardExpiry:any
    setNameOnCard:any
}

const addCreditCard = async (params:ParamsType)=> {
    params?.setIsProcessing(true)
    if (!params?.stripe || !params?.elements) {
      console.error("Stripe or elements is not loaded");
      return;
  }
  if (!params?.cardnumberElement) {
      console.error( "CardNumberElement is not available");
      return;
  }
      const { setupIntent, error } = await params?.stripe.confirmCardSetup(params?.clientSecret, {
          payment_method: {
            card: params?.cardnumberElement,
            billing_details: { email:params?.email },
          },
        });
  
      if (error) {
          params?.setIsProcessing(false);
          return;
        }
      if (setupIntent?.status==='succeeded') {
        toast.success('Card Added Successfully')
        params?.cardnumberElement?.clear();
        params?.cardExpiry?.clear();
        params?.cardcvv?.clear();
          params?.setNameOnCard('')
        getCardDetail({setIsProcessing:params?.setIsProcessing, setCardDetails:params?.setCardDetails, customerId:params?.stripId})
        params?.setIsProcessing(false)
      }
  

  }
  export default addCreditCard;