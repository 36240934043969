import React, { useEffect, useState } from "react";
import { MainWrapper } from "../component/MainWrapper";
import { TopBar } from "../component/TopBar";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { WorkOrderRightSidebar } from "./component/workOrderView/WorkOrderRightSidebar";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import CleanerType from "app/pages/cleaner/component/type/CleanerType";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { WorkOrderLeftSidebar } from "./component/workOrderView/WorkOrderLeftSidebar";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import EmailLink from "sr/helpers/ui-components/EmailLink";
import { DownloadResume } from "sr/helpers/functions/ApiFunction.tsx/DownloadResume";
import { Document, pdfjs } from 'react-pdf'
import { useGet } from "../Hooks/Api/useGet";
import { getapplyWorkOrder } from "sr/utils/api/company/workorder/getapplyWorkOrder";
import { getFile } from "sr/utils/api/getFile";
import { getTraining } from "sr/utils/api/cleaner/getTraining";
import { getMedicalCondition } from "sr/utils/api/cleaner/getMedicalCondition";
import { getEmployment } from "sr/utils/api/cleaner/getEmployment";
import { getFiles } from "sr/utils/api/getFiles";
import { Empty } from "../candidates/component/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faEnvelope, faMapMarker, faPhone } from "@fortawesome/free-solid-svg-icons";
import PhoneNumberFormat from "sr/helpers/functions/PhoneNumberFormat";
import { EmpoymentDuration } from "sr/helpers/functions/EmpoymentDuration";
import { FindCurrentEmployer } from "sr/helpers/functions/FindCurrentEmployer";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getTask } from "sr/utils/api/company/customer/getTask";
export const WorkOrderView = () => {
    const [id, setId] = useState<string>()
  const [allApplicant, setAllApplicant] = useState(null)
  const [candidate, setCandidate] = useState(null)
  const [cleaner, setCleaner] = useState<CleanerType>(null)
  const [cleanerId, setCleanerId] = useState('')
  const [candidates, setCandidates] = useState(null)
  const [companyId, setCompanyId] = useState()
  const [totalCandidate, setTotalCandidate] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [resume, setResume] = useState(null)
  const [resumeKey, setResumeKey] = useState(null)
  const [profileImg, setProfileImg] = useState<string>()
  const [currentEmployer, setCurrentEmployer] = useState(null)
  const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
  const params = useParams()
  const navigate = useNavigate()
  const {data:data, loader:wloader, getData:getWorkOrderDetail}= useGet()
  const {data:application, getData:getApplication, loader:loader, pageination:pageination}:any = useGet()
  const {data:training, getData:getTrainingdata, loader:trainingLoader}  = useGet()
  const {data:medical, getData:getMedicaldata, loader:medicalLoader} = useGet()
  const {data:employment, getData:getEmploymentData, loader:employmentLoader} = useGet()
  const {data:files, getData:getFileData, loader:fileLoader} = useGet()
  const [experienceCount, setExperienceCount] = useState(null)
  const HandlePageChange = async (index)=> {
        if(index>allApplicant?.length-1 || index < 0){
          return
        }
        const id = allApplicant[index]
        navigate(`/work-order-details/${id}`)
    }
    useEffect(()=> {
        const userData =  getUserDetails()
        setCompanyId(userData?.user_id)
      }, [])

      const getappliedworkorder = async (workorder_id)=> {
        const params = new URLSearchParams()
        if (workorder_id) params.append('workorder_id', workorder_id)
          await getApplication(()=> getapplyWorkOrder(params))  
}

useEffect(()=>{
    if(id) {
        getappliedworkorder(id)
    }
},[id])

useEffect(()=> {
    setCandidate(application && application[0])
    setCleanerId(application && application[0]?.cleaner_id?._id)
},[application])
    
      useEffect(()=> {
        const {id} = params
        setId(id)
      }, [params])

      useEffect(()=> {
       setTotalCandidate(pageination?.total) 
      },[pageination])
      useEffect(()=> {
        if(files?.length>0) {
        const filterfile = files?.filter((item)=> item?.file?.fieldname==='resume')
        const filterprofileimg = files?.filter((item)=> item?.file?.fieldname==='profile_img')
        if(filterfile?.length>0) {
        setResumeKey(filterfile[0]?.file?.key)
        }
        const fetchResume = async ()=> {
          const getfile = await getFile(filterfile && filterfile[0]?.file?.key)
        
          if (getfile) {
            const pdfBlob = new Blob([getfile], { type: 'application/pdf' });
            const newPdfUrl = URL.createObjectURL(pdfBlob);
            console.log(newPdfUrl);
            setResume(newPdfUrl);
          }
        if(filterprofileimg?.length>0){
          const profileimg = await getFile(filterprofileimg && filterprofileimg[0]?.file?.key)
          const newImageUrl = URL.createObjectURL(profileimg);
      setProfileImg(newImageUrl)
        }
        }
          
          if(filterfile?.length>0) {
          fetchResume()
          }
        }
      },[files])
useEffect(()=> {
         const fetchAllData = async (cleaner_id)=> {
            await getTrainingdata(()=> getTraining(cleaner_id, undefined))
            await getMedicaldata(()=> getMedicalCondition(cleaner_id, undefined))
            await getEmploymentData(()=> getEmployment(cleaner_id, undefined))
            await getFileData(()=> getFiles({cleaner_id:cleaner_id}))
          }
         setCandidate(application?.find((item)=> item?.cleaner_id?._id === cleanerId))
         if(cleanerId) {
          fetchAllData(cleanerId)
         }
},[cleanerId])
useEffect(()=> {
  if(employment?.length>0){
  const totalex = EmpoymentDuration(employment)
  setExperienceCount(totalex)
  setCurrentEmployer(FindCurrentEmployer(employment))
  }

},[employment])
useEffect(()=> {
  const fetchDetails = async (id)=> {
              const queryParams = new URLSearchParams()
              if (id) queryParams.append('id', id)
                  await getWorkOrderDetail(()=> getWorkorder(queryParams))
  }
  if(id) {
      fetchDetails(id)
  }
},[id])
useEffect(()=> {
  const fetchChecklist = async (id)=> {
      const queryParams = new URLSearchParams()
      if (id) queryParams.append('checklist_id', id)
          await getChecklists(()=> getTask(queryParams))
}
if(data?.checklist_id) {
fetchChecklist(data?.checklist_id?._id)

}     
},[data?.checklist_id])
  return (    
    <MainWrapper>
      <ContentWithSpinner loader={loader} height="h-screen">
        <div className="py-3">
    <TopBar title = {<FormattedMessage
          id='RECRUITER.WORKORDER.DETAILS.TITLE'
          defaultMessage='Work Order'
        />} 
        btntitle = {<FormattedMessage
          id='RECRUITER.WORKORDER.ALL.TITLE'
          defaultMessage='All Work Orders'
        />}
         btntitlelink = '/work-order' postButton={<Link  to="/schedule-work-order" className='rounded-lg font-bold bg-[#2557A7] px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.WORKORDER.ADD.WORK.ORDER" defaultMessage="Add Work Order" />
         </Link>}  className='px-10'></TopBar>
    </div>
   
    <div className='pl-10'>
    <div className='flex items-center justify-between'>
<div className='flex items-center space-x-4'>
<Link to={`/wok-order`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#2557A7] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
</Link>
<div>
    <h2 className='font-satoshiBold'>{candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.last_name}</h2>
    <Link to={`/work-order`}>
      <p className='text-base font-satoshiBold text-[#2557A7]'> <FormattedMessage id="RECRUITER.WORKORDER.BACK.BUTTON" defaultMessage="Back" /> </p>
      </Link>
</div>

</div>
<nav className=' flex flex-wrap justify-center items-center lg:space-x-8'>
    {/* <p className='font-bold text-sm text-[#717b9e]'><FormattedMessage id="RECRUITER.CANDIDATES.PAGEINATION.TITLE" defaultMessage="Candidate {activeNumber} of {totalNumber}" values={{activeNumber:currentPage+1, totalNumber:allApplicant?.length}} /></p>
  <ul className="items-center inline-flex lg:space-x-2 text-base h-10 justify-between w-full lg:w-fit">
    <li onClick={()=> HandlePageChange(currentPage-1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg> <FormattedMessage id="PAGEINATION.PREVIOUS.BUTTON" defaultMessage="Previous" />  </Link>
    </li>

    <li onClick={()=> HandlePageChange(currentPage+1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700"><FormattedMessage id="PAGEINATION.NEXT.BUTTON" defaultMessage="Next" /> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
</Link>
    </li>
  </ul> */}
</nav>
    </div>
    <div className='grid grid-cols-12 gap-7 mt-10'>
   <div className='col-span-3 bg-white rounded-[18px] h-fit'>
    <WorkOrderLeftSidebar candidates = {application} total={totalCandidate} currentPage = {currentPage} allApplicant = {allApplicant} setCleanerId = {setCleanerId}/>
  <div>

 <div>
</div>
</div>
   </div>
  
   <div className='col-span-9'>
   {application?.length>0 ?
    <div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem] shadow-boxshadowpage'>
   {/* <WarningRed heading =  {<FormattedMessage id="RECRUITER.JOBPOST.WARNING.TITLE" defaultMessage="Job post has missing information" /> }  paragraph = {<FormattedMessage id="RECRUITER.JOBPOST.WARNING.TEXT" defaultMessage="To improve this post, add information that's important to people applying." />  }></WarningRed> */}
{/* <div className='flex space-x-3'>
  <div className='bg-[#2557A7] hover:bg-[#1a478f] text-white py-3 rounded-lg font-satoshiBold text-xl px-3 cursor-pointer'>
<div className='flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<span><FormattedMessage id='RECRUITER.CANDIDATE.INTERVIEW.SET.BUTTON' defaultMessage='set up interview' /></span>
</div>
  </div>
  <div className='text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-3 shadow-lg rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200'>
<div className='flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2557A7" className="w-6 h-6">
  <path fillRule="evenodd" d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
</svg>

<span><FormattedMessage id='RECRUITER.CANDIDATES.TABLE.INTEREST.MESSAGE' defaultMessage='Message'  /></span>
</div>
  </div>
  <div className='text-[#2557A7] py-3 shadow-lg rounded-lg font-satoshiBold hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] text-base px-3 cursor-pointer border border-neutral-200'>
<div className='flex space-x-2 items-center'>
<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="#2557A7" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.364 9.89A18.007 18.007 0 013 3.5a.49.49 0 01.493-.5h4.502c.276 0 .5.224.51.5.048 1.204.27 2.394.659 3.534a.513.513 0 01-.12.528l-2.438 2.439a16.007 16.007 0 007.391 7.392l2.438-2.437a.513.513 0 01.528-.12c1.14.39 2.332.612 3.537.66.276.011.5.234.5.51v4.5a.49.49 0 01-.5.494A18.01 18.01 0 014.364 9.89z"></path></svg>
<span><FormattedMessage id='RECRUITER.CANDIDATE.CALL.BUTTON' defaultMessage='Call'  /></span>
</div>
  </div>
</div> */}
<div className='flex space-x-5 mb-5 items-start'>
       <div className='w-40 h-40 flex items-center'>
        <div className='transform lg:transform-none -translate-x-1/2'>
        <img alt="Profile" className="rounded" src={profileImg ? profileImg : "/media/icons/displayProfilePlaceholder.png"} />
    </div></div>
<div className='flex-1'>
  <div className="border-b-2 border-gray-300 w-[100%] py-2">
  <h1 className='text-3xl font-satoshiBold text-[#595959]'>{candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.last_name}</h1>
  </div>
  <div className="flex justify-between flex-wrap pt-2 items-center">
  <div className="flex space-x-2 items-center pb-2">
  <FontAwesomeIcon icon={faEnvelope}/>
  <p><EmailLink email={candidate?.cleaner_id?.email} subject="Hello" body="Hi, how are you?"></EmailLink></p>

  </div>
  <div className="flex space-x-2 items-center pb-2">
  <FontAwesomeIcon icon={faPhone} />
  <p>{PhoneNumberFormat(candidate?.cleaner_id?.mobile_number)}</p>
  </div>
  <div className="flex space-x-2 items-start  pb-2">
  <FontAwesomeIcon icon={faMapMarker} />
  <p> {candidate?.cleaner_id?.address?.address_line_1} {candidate?.cleaner_id?.address?.address_line_2} {candidate?.cleaner_id?.address?.city}  {candidate?.cleaner_id?.address?.state}</p>
  </div>
  <div className="flex space-x-2 items-start  pb-2">
  <FontAwesomeIcon icon={faBriefcase} />
  <p>{experienceCount && `${experienceCount?.totalYears} Years ${experienceCount?.totalMonths} Months`}</p>
  </div>

  </div>

</div>
</div>
<div>
        <h1 className='text-xl font-satoshiBold pb-2'>Training</h1>
        {training !='' ? training?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>{item?.training}</span>
              <div className='text-base'>{DateFormat(item?.date_attended)}
              <p>{item?.description}</p>
            </div>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Training'}}></FormattedMessage></p>
}
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Medical Condition</h1>
        {
          medical !='' ? medical?.map((item, index)=> (
        <div className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>Condition - {item?.condition}</span>
              <div className='text-base'>Since When - {DateFormat(item?.since_when)}  </div>
              <p>{item?.description}</p>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Medical Condition'}}></FormattedMessage></p>

}
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Employment</h1>
        {
        employment != '' ?
        employment?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4'>
            <span className='text-base font-satoshiBold text-black'>{item?.job_title} </span>
              <div className='text-base'>{item?.company_name}</div>
              <div className='text-base'>{item?.company_location}</div>
              <div className='text-base'>{item?.currently_employed ? DateFormat(item?.working_since_start) : DateFormat(item?.working_since_start) + '-' + DateFormat(item?.working_since_end) }</div>
        
       </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Employment'}}></FormattedMessage></p>

}
       
       </div></div>
 </div> 
{/* <ApplicantQualification /> */}
{/* <div className='border-b border-neutral-200 py-5 flex justify-between items-center'>
<span className='text-base font-satoshiBold'> <FormattedMessage id="RECRUITER.CANDIDATE.ASSESSMENTS.TITLE" defaultMessage="Assessments" />  </span>
<div className='text-[#2557A7] font-satoshiBold border cursor-pointer border-neutral-200 px-3 py-1.5 text-base rounded-lg'>
<FormattedMessage id="RECRUITER.CANDIDATE.ASSESSMENTS.BUTTON" defaultMessage="Send assessment" /> 
</div>
</div> */}
<div className='border-b border-neutral-200 py-5 flex justify-between items-center'>
<span className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.CANDIDATE.CV.TITLE" defaultMessage="CV" /> </span>
<div onClick={()=> DownloadResume({resumeName:'bbnett.pdf', resumeKey:resumeKey})} className='text-[#2557A7] font-satoshiBold cursor-pointer border border-neutral-200 px-3 py-1.5 text-base rounded-lg'>
<FormattedMessage id="RECRUITER.CANDIDATE.CV.BUTTON" defaultMessage="Download PDF" /> 
</div>
</div>
{/* { resume &&
        <iframe src={resume} style={{ width: '100%', height: '500px' }} title="Resume Viewer" />

} */}
{/* {resume &&
 <Document
  file={{ url: resume }}
  onLoadSuccess={() => console.log('PDF loaded successfully')}
  onLoadError={(error) => console.error('Error loading PDF:', error)}
>
</Document>
} */}

   </div>
   <div className='w-full'>
<WorkOrderRightSidebar status = {candidate?.status} candidate={candidate} resumeKey={resumeKey}/>
   </div>
   
   </div>
:
<div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem]'>
    <div>
    <div><span className='font-bold font-satoshiBold text-sm'><FormattedMessage id="RECRUITER.WORKORDER.DETAILS..COL.TITLE" defaultMessage="Title" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.title}</span>
                                </div>
                                <div>
            <span className='font-bold font-satoshiBold text-sm'> <FormattedMessage id="PENDING.JOBS.JOB.LOCATION.TITLE" defaultMessage="Location" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.customer_location_id.address?.address_line_1} {data?.customer_location_id?.address?.address_line_2} {data?.customer_location_id?.address?.city}</span>
                                </div>
                                <div><span className='font-bold font-satoshiBold text-sm'><FormattedMessage id="MYJOB.TABLE.COL.NAME" defaultMessage="Customer Name" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.customer_id?.name}</span>
                                </div>
                                <div><span className='font-bold font-satoshiBold text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.ENTRYTIME" defaultMessage="Entry Time" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.entry_time}</span>
                                </div>
                                <div><span className='font-bold font-satoshiBold text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.EXITTIME" defaultMessage="Exit Time" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.exit_time}</span>
                                </div>
                                <div><span className='font-bold font-satoshiBold text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.PAYTYPE" defaultMessage="GENERAL.TEXT.TITLE.PAYTYPE" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.pay_type} (${data?.pay_type_rate})</span>
                                </div></div>
    <div className='job-description mt-6'>
                                <h1 className='font-bold font-satoshiBold text-base'>
                                <FormattedMessage 
                                    id='JOBDETAILS.JOBDESCRIPTION'
                                    defaultMessage='Job Description'
                                    />
                                </h1>
                                <div><HTMLStringRender htmlContent={data?.description}></HTMLStringRender></div>
                            </div>
    
                         
                            <div className='mt-6 mb-6 border border-neutral-200 rounded-[20px]'>
                                <table className="w-full text-left condidate-table">
    <thead className="border-collapse border-b border-neutral-200 capitalize">
        <tr>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.TASK" defaultMessage="Task" />  
            </th>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.DESCRIPTION" defaultMessage="Description" /> 
            </th>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="PENDING.JOBS.TEXT.TYPE" defaultMessage="Type" /> 
            </th>
        </tr></thead>
        <tbody>
        {checklistData?.map((data, index)=> (
                           <tr className="bg-white border-b  hover:bg-gray-50">
                           <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          
                               <div className='text-base'>{data.name}</div>
                               
                           </th>
                           <td className="px-6 py-4">
                               {data.description}
                           </td>
                         
                   
                           <td className="px-6 py-4">
                           {data.type}
                           </td>
                          
                       </tr>
                  ))}
        </tbody>
        </table></div>
      </div> </div>
      
}
   </div>
   
   </div>
    </div>
    </ContentWithSpinner>
    </MainWrapper>
    );}
    ;
