import React, {useEffect, useState} from 'react'
import { ModelWrapper } from '../../../component/ModelWrapper'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Interested } from '../Interested'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApplicationStatus } from 'sr/i18n/messages/setting'
import { useParams } from 'react-router-dom'
import { updateApplication } from 'sr/utils/api/cleaner/updateApplication'
import { toast } from 'react-toastify'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getApplication } from 'sr/utils/api/cleaner/getApplication'
import { addNotes } from 'sr/utils/api/company/addNotes'
import { getNotes } from 'sr/utils/api/company/getNotes'
import { DownloadResume } from 'sr/helpers/functions/ApiFunction.tsx/DownloadResume'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
export const CondidateRightSidebar = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [status, setstatus] = useState()
  const [id, setId] = useState<string>()
  const [notes, setNotes] = useState('')
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const HandleModelByProps = (data) => {
    setShowModal(data)
   }

   useEffect(()=> {
setstatus(props?.status)
   },[props?.status])

   useEffect(()=> {
    const {id} = params
    setId(id)
  }, [params])
  useEffect(()=> {
    if(id) {
    fetchNotes(undefined, props?.candidate?.id)
    }
  }, [id])
   const intl = useIntl()

   
      const HandleApply = async (status)=> {
        const data = {
            id: id,    
            status:status
        }
          const result = await updateApplication(data)
          if(result?.success===true) {
            setstatus(status)
            props?.setBackStatus(status)
          }
      }

      const fetchNotes = async (id, applicant_id) => {
        const queryParams = new URLSearchParams();  
        if (id) queryParams.append('id', id)
        if (applicant_id) queryParams.append('applicant_id', applicant_id)
          const result = await getNotes(queryParams)
          if(result?.success===true) {
            setNotes(result?.data?.notes)
          }
      }
      const HandleNotes = async () => {
            const payload = {
              company_id:props?.candidate?.job_id?.company_id?.id,
              applicant_id:id,
              notes:notes
            }
            const result = await addNotes(payload)
            if(result?.success===true) {
                return result
            }
      }

  return (
    <div className='bg-[#FAF9F8] py-10 px-5'>
{loader && <LoaderFullScreen />}
      <p className='pb-2 text-gray-600'><FormattedMessage  id='RECRUITER.CANDIDATE.ACTION.INTERESTED.TITLE' defaultMessage='Interested?'/></p>
<Interested modelonClick = {()=> setShowModal(!showModal)} shortlistonClick={()=> HandleApply('shortlist')} rejectonClick={()=> HandleApply('rejected')}  maybeonClick = {()=> HandleApply('awaiting-reviews')}>
               
                {showModal ? (
    <>
<ModelWrapper rightPosition = '-right-20' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps} showModel = {showModal}>
<ul className='px-3 pb-2 font-satoshiBold text-base font-bold text-[#595959]'>
{props?.resumeKey &&
<li onClick={()=> DownloadResume({resumeName:'bbnett.pdf', resumeKey:props?.resumeKey, setLoader:setLoader})} className='py-3'>
          <FormattedMessage
            id='RECRUITER.CANDIDATE.ACTION.DOWNLOAD_CV'
            defaultMessage='Download CV'
          />
        </li>
}
        <li onClick={()=> HandleApply('rejected')} className='py-3'>
          <FormattedMessage
            id='RECRUITER.CANDIDATE.ACTION.REJECT_CANDIDATE'
            defaultMessage='Reject Candidate'
          />
        </li>
</ul>
</ModelWrapper>
</>): null }
</Interested>       
     <p className='mt-3 pb-2 text-gray-600'><FormattedMessage  id='RECRUITER.CANDIDATE.ACTION.HIRINGSTATUS.TITLE' defaultMessage='Hiring Status'/></p>
                <DropDown onChange= {(e)=> HandleApply(e?.target?.value)} dropdowndiv='mt-0' value={status} className='rounded-lg' >
                  {ApplicationStatus?.map((item, index)=> (
                <option key={index} value={item?.value}>
          {item?.label}
        </option>
))}
                </DropDown>
                <hr className='border border-gray-200 my-5' />
                <label htmlFor="notes" className=" mb-2 text-sm font-satoshiBold flex items-center"><svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-4 h-4"><path fill-rule="evenodd" d="M4.5 8.995H6V6a4 4 0 118 0v2.995h1.5a.5.5 0 01.5.5V17.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5V9.495a.5.5 0 01.5-.5zM12.5 6v2.995h-5V6a2.5 2.5 0 015 0zM10 14.998a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" clip-rule="evenodd"></path></svg><FormattedMessage id='INPUT.TEXT.AREA.TITLE' defaultMessage='Notes' /></label>
<textarea value={notes} onChange={(e:any)=> setNotes(e.target.value)}  id="notes" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0"
 placeholder={intl.formatMessage({
  id: 'INPUT.TEXT.AREA.PLACEHOLDER',
  defaultMessage: 'Write your note here...',
})}
></textarea>
<button onClick={HandleNotes} type="submit" className='mt-5 font-satoshiBold text-[#2557A7] border border-neutral-400 bg-white px-4 py-1 rounded-lg'><FormattedMessage id='RECRUITER.CANDIDATE.ACTION.SAVENOTE.BUTTON' defaultMessage='Save note' /></button>
<hr className='border border-gray-200 my-5' />
<div className='text-gray-600 text-sm pb-2'>{DateFormat(props?.candidate?.createdAt)}</div>
      </div>
  )
}
