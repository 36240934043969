import React, { useEffect, useState } from "react";
import { MainWrapper } from "../component/MainWrapper";
import { TextArea } from "sr/helpers";
import { WhiteBtn } from "./component/WhiteBtn";
import { Input } from "sr/helpers";
import { Link, useNavigate, useParams } from "react-router-dom";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { RadioInput } from "sr/helpers/ui-components/company/RadioInput";
import { ModelWrapper } from "../component/ModelWrapper";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleCalendarBottom } from "./ManageSchedule/components/ScheduleCalendarBottom";
import { FormattedMessage, useIntl } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { getChecklist } from "sr/utils/api/company/customer/getChecklist";
import { useFormik } from "formik";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import workorderSchema from "sr/utils/schemas/company/workOrder/workorderSchema";
import { createWorkorder } from "sr/utils/api/company/workorder/createWorkorder";
import { useValidationErrorHandle } from "sr/helpers/functions/validationErrorHandle";
import {
  differenceInCalendarWeeks,
  startOfMonth,
} from "date-fns";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import { ClickForAdd } from "./component/ClickForAdd";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { useAuth } from "app/pages/module/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { getDayname } from "./component/getDayname";
import { toggleDay } from "./component/toggleDay";
import { fetchCustomerData } from "sr/helpers/apiFunctions/fetchCustomerData";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { Days } from "./component/Days";
import formatNumber from "sr/helpers/functions/formatNumber";
import { getHours } from "sr/helpers/functions/getHours";
import { flushSync } from "react-dom";
import { jobTypes } from "../jobpost/component/Jsonalldata";
import { getConJobType } from "sr/utils/api/company/contractor/getConJobType";
import { ModelWrapperWithButton } from "../component/ModelWrapperWithButton";
import GeneralTab from "../Customer/components/AddCustomer/GeneralTab";
import { GeneralForm } from "../Customer/components/forms/GeneralForm";
import { AddCustomer } from "./component/AddCustomer";
import { AddLocation } from "./AddLocation";
import { AddChecklistD } from "./component/AddChecklistD";
import { AddressDisplay } from "sr/helpers/functions/AddressDisplay";
import { ClickForAdd1 } from "./component/ClickForAdd1";
import { fetchBalance } from "sr/helpers/apiFunctions/fetchBalance";
import { toast } from "react-toastify";
export const ScheduleWorkOrder = () => {
  const [selectedCustomer, SetSelectedCustomer] = useState("");
  const [location, setLocation] = useState("");
  const [checklist, setChecklist] = useState("");
  const [type, setType] = useState("");
  const [contractor, setContractor] = useState("");
  const [workHours, setWorkHours] = useState(null);
  const [selectpay, setSelectpay] = useState("Fixed Rate");
  const [fixPrice, setFixPrice] = useState(null);
  const [scheduleType, setScheduleType] = useState("One Time");
  const [recurrence, setRecurrence] = useState("");
  const [days, setDays] = useState<any[]>([]);
  const [entryTime, setEntryTime] = useState(null);
  const [exitTime, setExitTime] = useState(null);
  const [currentDate, setcurrentDate] = useState<any>();
  const [currentDay, setCurrentDay] = useState<any>();
  const [currentweek, setCurrentWeek] = useState<any>();
  const [customer, setCustomer] = useState(null);
  const [customerLocations, setCustomerLocations] = useState(null);
  const [checklistdetails, setchecklistDetails] = useState(null);
  const [cleanerId, setCleanerId] = useState();
  const [description, setDescription] = useState();
  const [onetimeDate, setOneTimeDate] = useState(new Date());
  const [repeatEvery, setRepeatEvery] = useState();
  const [ends, setEnds] = useState<any>({});
  const [rmdt, setRmdt] = useState("DayNumber");
  const [customError, setCustomerError] = useState<string>()
  const [loader, setLoader] = useState(false)
  const [showModel, setShowModel] = useState(false)
  const [stepperIndex, setStepperIndex] = useState(0);
  const [title, setTitle] = useState()
  const [userId, setUserId] = useState<string>('')
  const [customerLocationId, setCustomerLocationId] = useState<string>()
  const [contractors, setContractors]= useState([])
  const [isDraft, setIsDraft] = useState(false)
  const [jobtype, setJobType] = useState()
 const {userType}  = useAuth()
 const [showAddModel, setShowAddModal] = useState('')
 const [balance, setBalance] = useState(0)
 const dispatch = useDispatch<AppDispatch>()
 const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
 useEffect(()=> {
  if(userId && userType==='supervisor') {
    if(!supervisorData){
      dispatch(fetchSupervisor({id:userId}))
    }
  }
},[userId, userType, dispatch])
useEffect(()=>{
if(userId){
  fetchBalance({userId:userId, setData:setBalance, setLoader:setLoader})
}
},[userId])
useEffect(()=> {
if(((entryTime && workHours) && !exitTime)) {
let entryhours = Number(entryTime?.slice(0,2))+Number(workHours)
if(entryhours>=24){
entryhours = entryhours-24
}
const exitHours = formatNumber(entryhours)
const exitTime = exitHours+entryTime?.slice(2)
setExitTime(exitTime)
}
if (((entryTime && exitTime) && !workHours)) {
  
  const hours = getHours(entryTime, exitTime)
  setWorkHours(String(hours))
}
},[entryTime, workHours, exitTime])


const fetchAllLocation = async (companyId)=> {
  const data = await fetchCustomerLocations({userType:userType, companyId:companyId})
  setCustomerLocationId(data?.map((item)=> item?.id)?.join())
}
useEffect(()=> {
  
  if(supervisorData){
  const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
  
  if(id?.length>0){
  setCustomerLocationId(id?.join());
  }
  else {
  fetchAllLocation(supervisorData?.company_id?._id)
}
}
  
 },[supervisorData])
 useEffect(()=> {
 },[customerLocationId])
  const navigate = useNavigate();
  const endsHandle = (data) => {
    setEnds(data);
  };
  const validationErrorHandle = useValidationErrorHandle();

  useEffect(() => {
    const date = new Date();
    setcurrentDate(date.getDate());
    setCurrentDay(date.getDay());
    const start = startOfMonth(date);
    const week = differenceInCalendarWeeks(date, start) + 1;
    setCurrentWeek(week);
  }, []);
  const intl = useIntl();
  useEffect(()=> {
    const {user_id} = getUserDetails()
    setUserId(user_id)
},[])
 const loadCustomerData = async ()=> {
  await fetchCustomerData({userType:userType, userId:userId, setData:setCustomer, page:1, limit:1000, customerLocationId:customerLocationId, shoudActive:true, setShowModel:setShowModel, setStepperIndex:setStepperIndex})
 }

  const fetchLocation = async (customerId) => {
    try {
      const queryParams = new URLSearchParams();
      if (customerId) queryParams.append("customer_id", customerId.toString());
      queryParams.append('status!','deleted')
      const result = await getCustomerLocation(queryParams);
      if (result?.success === true) {
        setCustomerLocations(result?.data);
          if(result?.data?.length>0) {
            setStepperIndex(2)
          }
      } else {
      
      }
    } catch (e: any) {

    }
  };
  const HandleModelByProps = ()=> {
    setShowModel(false)
  }
  const fetchChecklist = async (customerId) => {
    try {
      const queryParams = new URLSearchParams();
      if (customerId) queryParams.append("customer_id", customerId);
      queryParams.append("status", "active");
      queryParams.append("limit", "100");
      const result = await getChecklist(queryParams);
      if (result.success === true) {
        setchecklistDetails(result?.data);
        setStepperIndex(3)
      } else {
      }
    } catch (e) {
    }
  };

const loadContractor = async ()=> {
  const queryParams = new URLSearchParams()
  queryParams.append('job_type', jobtype)
  queryParams.append('company_id', userId)
  try {
const res = await getConJobType(queryParams)
if(res?.success===true) {
  setContractors(res?.data)
}
  }
  catch(e) {
    
  }
} 
const fetchSupervisors = async (companyId)=>{
  try {
const queryParams = new URLSearchParams()
if (companyId) queryParams.append('company_id', companyId)
  queryParams.append('status!', 'deleted')
 const res = await getSupervisor(queryParams)
 if(res?.success===true) {
  if(res?.data?.length>0) {
  setStepperIndex(5)
  }}
}catch(e) {
}}
  useEffect(() => {
    if(userId && userType) {
      if(userType==='supervisor'){
        if(customerLocationId){
          loadCustomerData();
          fetchSupervisors(userId)
        }
      }
      else {
          loadCustomerData();
          fetchSupervisors(userId)
      }

    }
  }, [userId, userType, customerLocationId]);
  useEffect(() => {
    if (selectedCustomer) {
      fetchLocation(selectedCustomer);
      fetchChecklist(selectedCustomer);
    }
  }, [selectedCustomer]);
 
  const initialValueOneTime = {
    title:title,
    company_id: userType === 'company' ? userId : supervisorData?.company_id?._id,
    customer_id: selectedCustomer,
    customer_location_id: location,
    checklist_id: checklist,
    cleaner_id: cleanerId,
    type: type,
    time_for_work_completion: workHours,
    pay_type: selectpay,
    pay_type_rate: fixPrice,
    job_type:jobtype,
    entry_time: entryTime,
    exit_time: exitTime,
    description: description,
    recurring: false,
    one_time_date: onetimeDate,
    workorder_status:"scheduled",
  };

  const initialValueRecurring = {
    title:title,
    company_id: userType === 'company' ? userId : supervisorData?.company_id?._id,
    customer_id: selectedCustomer,
    customer_location_id: location,
    checklist_id: checklist,
    type: type,
    time_for_work_completion: workHours,
    pay_type: selectpay,
    pay_type_rate:fixPrice,
    job_type:jobtype,
    entry_time: entryTime,
    exit_time: exitTime,
    description: description,
    recurring: scheduleType === "Recurring" && true,
    repeat_count: repeatEvery,
    repeat_type: recurrence,
    repeat_month_day_of_week: rmdt === "DayName" ? currentDay : null,
    repeat_month_week: rmdt === "DayName" ? currentweek : null,
    repeat_month_day_of_month: rmdt === "DayNumber" ? currentDate : null,
    repeat_week_day: days,
    ends: ends,
    workorder_status: "scheduled",
  };
  const { errors, handleSubmit } = useFormik({
    initialValues:
      scheduleType === "One Time" ? contractor ? {contractor_id:contractor, ...initialValueOneTime}:initialValueOneTime : contractor ?{contractor_id:contractor, ...initialValueRecurring}: initialValueRecurring,
    enableReinitialize: true,
    validationSchema: workorderSchema,
    onSubmit: async (values) => {
      if(values?.pay_type_rate > balance){
            toast.error('Insufficient Fund in your Wallet')
            return
      }
      const newValue = {status:isDraft?'draft':'publish', ...values}
        setLoader(true)
      const res = await createWorkorder(newValue);
      if (res?.success === true) {
        setLoader(false)
        navigate("/work-order");
      }
    },
  });
  useEffect(() => {
    setDays([]);
    setEnds(null);
  }, [recurrence]);


const HandleScheduleWorkOrder = ()=> {
  if(scheduleType) {
    handleSubmit()
  }
  else {
    setCustomerError('INPUT.VALIDATION.WORKORDER.SELECT.TYPE')
  }
}

useEffect(()=> {
if(jobtype) {
  loadContractor()
}

},[jobtype])

useEffect(()=> {
  console.log(workHours);
  console.log(contractor);
  
  const Rate = contractors?.find((item)=> item?.contractor_id?._id===contractor)?.rate
  console.log(Rate);
  
setFixPrice(parseInt(workHours)*Number(Rate))
},[workHours])
const onCheckModel = ()=>{
  setShowAddModal('')
}

  return (<>
    <MainWrapper>
        {loader && <LoaderFullScreen />}
      <div className="py-3">
        <div className="mb-2">
          <h1 className="text-2xl font-satoshiBold text-xl border-r border-neutral-200 pr-4">
            <FormattedMessage
              id="RECRUITER.SCHEDULEWORKORDER.TITLE"
              defaultMessage="Schedule Work Order"
            />
          </h1>
          <p>
            <FormattedMessage
              id="RECRUITER.SCHEDULEWORKORDER.TEXT"
              defaultMessage="Fill out the requiered fields to schedule a service work order."
            />
          </p>
        </div>
        <div className="flex my-3">
          <Link to="/work-order">
            <WhiteBtn
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              }
              title={
                <FormattedMessage
                  id="AUTH.GENERAL.BACK_BUTTON"
                  defaultMessage="Back"
                />
              }
            ></WhiteBtn>
          </Link>
        </div>
        
        <div className="px-10">
       
          <div className="flex space-x-2 items-center pr-2">
         
            <DropDown
              error={errors?.customer_id}
              labelClass="text-base"
              value={selectedCustomer}
              label={
                <FormattedMessage
                  id="RECRUITER.WORKORDER.FILTER.CUSTOMER.TITLE"
                  defaultMessage="Customer"
                />
              }
              dropdowndiv="mt-3"
              onChange={(e) => SetSelectedCustomer(e.target.value)}
              className="rounded-lg"
            >

              <option value="">
                <FormattedMessage
                  id="RECRUITER.WORKORDER.FILTER.SELECTCUSTOMER"
                  defaultMessage="Select Customer"
                />
              </option>
              {customer?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </DropDown>
            <svg onClick={()=> setShowAddModal('Customer')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mt-[32px] border cursor-pointer border-gray-border rounded-lg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>
          </div>
          {selectedCustomer.length > 0 ? (
            
            <>
                <div className="flex space-x-2 items-center  pr-2">
        <Input
                  error={errors?.title}
                  label={
                    intl.formatMessage({
                      id:"RECRUITER.SCHEDULEWORKORDER.INPUT.WORKORDER.TITLE",
                      defaultMessage:"Title"
                    })
                  }
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-0 w-42 pr-2 pb-2"
                  placeholder={intl.formatMessage({id:"RECRUITER.SCHEDULEWORKORDER.INPUT.WORKORDER.TITLE.PLACEHOLDER" })}
                  value={title}
                />
                </div>
                <div className="flex space-x-2 items-center  pr-2 pb-2">
                <DropDown
              error={errors?.job_type}
              labelClass="text-base"
              value={jobtype}
              label={
                <FormattedMessage
                  id="CLEANER.JOBS.FILTER.JOBTYPE"
                  defaultMessage="Job Type"
                />
              }
              dropdowndiv="mt-3"
              onChange={(e) => setJobType(e.target.value)}
              className="rounded-lg"
            >
              <option value="">
                <FormattedMessage
                  id="CLEANER.PROFILE.EMPLOYMENT.SELECT.JOBTYPE.TITLE"
                  defaultMessage="Select Job Type"
                />
              </option>
              {jobTypes?.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </DropDown>
            </div>
              <div className="max-w-xl">
                <div className="flex w-full space-x-3">
                  <div className="flex items-center justify-center">
                  <DropDown
                    error={errors?.customer_id}
                    labelClass="text-base"
                    value={location}
                    label={
                      <FormattedMessage
                        id="RECRUITER.WORKORDER.FILTER.LOCATION"
                        defaultMessage="Location"
                      />
                    }
                    dropdowndiv="mt-1 pr-2 pb-2"
                    onChange={(e) => setLocation(e.target.value)}
                    className="rounded-lg"
                  >
                    <option value="">
                      <FormattedMessage
                        id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION"
                        defaultMessage="Select Location"
                      />
                    </option>
                    {customerLocations?.map((item, index) => (
                      <option value={item?.id}>{AddressDisplay(item?.address)}</option>
                    ))}
                  </DropDown>
                  <svg onClick={()=> setShowAddModal('Location')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mt-[20px] border cursor-pointer border-gray-border rounded-lg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>
                  </div>
                  <div className="flex items-center justify-center">
                  <DropDown
                    error={errors?.checklist_id}
                    labelClass="text-base"
                    value={checklist}
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.CHECKLIST.TITLE"
                        defaultMessage="Checklist"
                      />
                    }
                    dropdowndiv="mt-1 pr-2 pb-2"
                    onChange={(e) => setChecklist(e.target.value)}
                    className="rounded-lg"
                  >
                    <option value="">
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.CHECKLIST.SELECT"
                        defaultMessage="Select Checklist"
                      />
                    </option>
                    {checklistdetails?.map((item, index) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </DropDown>
                  <svg onClick={()=> setShowAddModal('Checklist')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mt-[20px] border cursor-pointer border-gray-border rounded-lg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center pb-3 mb-3 space-x-3">
                    <label
                      className="font-satoshiBold inline-block"
                      htmlFor="Type"
                    >
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.TITLE"
                        defaultMessage="Type"
                      />
                    </label>
                  <div className="flex items-center group relative">
                    <RadioInput
                    inputcss="pl-4 pr-2"
                      name="Type"
                      value="open"
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.OPEN"
                          defaultMessage="Open"
                        />
                      }
                      checked={type === "open"}
                      onChange={() => setType("open")}
                    ></RadioInput>
                    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
  <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
</svg>
<span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
Select this option if the work order want open-ended and can be apply anyone
</span>

                    </div>
                    <div className="flex items-center group relative">
                    <RadioInput
                    inputcss="pl-4 pr-2"
                      name="Type"
                      value="individual"
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.INDIVIDUAL"
                          defaultMessage="Individual"
                        />
                      }
                      checked={type === "individual"}
                      onChange={() => setType("individual")}
                    ></RadioInput>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
  <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
</svg>
<span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
Select this option if the work order want assign to your added Contractor
</span>
                    </div>
                  </div>
                  {type === "individual" && (
                    <DropDown
                      labelClass="text-base"
                      value={contractor}
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE"
                          defaultMessage="Contractor"
                        />
                      }
                      dropdowndiv="mt-1 w-26 pr-2 pb-2"
                      onChange={(e) => setContractor(e.target.value)}
                      className="rounded-lg"
                    >
                      <option value="">
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.SELECT"
                          defaultMessage="Select Contractor"
                        />
                      </option>
                      {contractors?.map((item, index) => (
                        <option key={index} value={item?.contractor_id?._id}>
                          {item?.contractor_id?.first_name} {item?.contractor_id?.last_name} ${item?.rate}/hr
                        </option>
                      ))}
                    </DropDown>
                  )}
                  {errors?.type ? (
                    <p className="mt-1 text-red-400 md:text-sm sm:text-[.65rem]">
                      {validationErrorHandle(errors?.type)}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  error={errors?.time_for_work_completion}
                  label={
                    <FormattedMessage
                      id="RECRUITER.SCHEDULEWORKORDER.INPUT.TITLE"
                      defaultMessage="Time for work completion"
                    />
                  }
                  onChange={(e) => setWorkHours(e.target.value)}
                  type="number"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-0 w-26 pr-2 pb-2"
                  placeholder="Number of hours"
                  value={workHours}
                />

                <div className="flex items-center pb-3 mb-3 space-x-3">                                     
                    <Input
                      inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.FIXPRICE.INPUT.PLACEHOLDER"
                          defaultMessage="Enter the fixed rate"
                        />
                      }
                      onChange={(e) => setFixPrice(e.target.value)}
                      type="number"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-0 w-26 pr-2 pb-2"                        
                      prefix="$"
                      value={fixPrice}
                    />
                  {/* {selectpay === "Hourly Rate" && (
                    <Input
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.HOURLYPRICE.INPUT.PLACEHOLDER"
                          defaultMessage="Enter the hourly rate"
                        />
                      }
                      onChange={(e) => sethourlyRate(e.target.value)}
                      type="number"
                      inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-0 w-26 pr-2 pb-2"
                      placeholder=" per hour"
                      prefix="$"
                      value={hourlyRate}
                    />
                  )} */}
                  {errors?.pay_type ? (
                    <p className="mt-1 text-red-400 md:text-sm sm:text-[.65rem]">
                      {validationErrorHandle(errors?.pay_type)}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex items-center w-full">
                  <Input
                    error={errors?.entry_time}
                    onChange={(e) => setEntryTime(e.target.value)}
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                        defaultMessage="Entry Time"
                      />
                    }
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                        defaultMessage="Entry Time"
                      />
                    }
                  ></Input>
                  <Input
                    error={errors?.exit_time}
                    onChange={(e) => setExitTime(e.target.value)}
                    value={exitTime}
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.EXITTIME"
                        defaultMessage="Exit Time"
                      />
                    }
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.EXITTIME"
                        defaultMessage="Exit Time"
                      />
                    }
                  ></Input>
                </div>
                <div className="w-26 font-satoshiBold">
                  <TextArea
                    error={errors?.description}
                    onChange={(e: any) => setDescription(e.target.value)}
                    value={description}
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.DESCRIPTION.TITLE"
                        defaultMessage="Work Description"
                      />
                    }
                    placeholder={intl.formatMessage({
                      id: "RECRUITER.SCHEDULEWORKORDER.INPUT.DESCRIPTION.PLACEHOLDER",
                      defaultMessage: "Write your note here...",
                    })}
                    rows={3}
                    className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0"
                  ></TextArea>
                </div>
                <div className="flex items-center pb-3 mb-3 space-x-3">
                  <label className="font-satoshiBold" htmlFor="ScheduleType">
                    <FormattedMessage
                      id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE"
                      defaultMessage="Type"
                    />
                  </label>
                  <RadioInput
                    name="ScheduleType"
                    value="One Time"
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE.ONETIME"
                        defaultMessage="One Time"
                      />
                    }
                    checked={scheduleType === "One Time"}
                    onChange={() => setScheduleType("One Time")}
                  ></RadioInput>
                  <RadioInput
                    name="ScheduleType"
                    value="Recurring"
                    label={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE.RECURRING"
                        defaultMessage="Recurring"
                      />
                    }
                    checked={scheduleType === "Recurring"}
                    onChange={() => setScheduleType("Recurring")}
                  ></RadioInput>
                      {(customError && !scheduleType) ? <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(customError)}</p> : ''}
                </div>
                {scheduleType === "One Time" && (
                  <>
                    <ReactDatePicker
                      onChange={(date) => setOneTimeDate(date)}
                      selected={onetimeDate}
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.INPUT.SCHEDULEDATE"
                          defaultMessage="Schedule Date"
                        />
                      }
                      inputClass="rounded-lg"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-0 w-26 pr-2 pb-2"
                      type="date"
                      placeholder="Schedule date"
                    ></ReactDatePicker>
                  </>
                )}
                {scheduleType === "Recurring" && (
                  <>
                    <div className="flex space-x-2 items-center">
                      <Input
                        onChange={(e) => setRepeatEvery(e.target.value)}
                        value={repeatEvery}
                        topinputdiv="w-16"
                        label={
                          <FormattedMessage
                            id="RECRUITER.SCHEDULEWORKORDER.INPUT.REPEATEVERY"
                            defaultMessage="Repeat every"
                          />
                        }
                        inputClass="rounded-lg"
                        labelClass="mt-0 text-black"
                        InputDivClass="mt-0 pr-2 flex space-x-2 items-center"
                        type="number"
                      ></Input>
                      <DropDown
                        value={recurrence}
                        dropdowndiv="mt-0"
                        onChange={(e) => setRecurrence(e.target.value)}
                        className="rounded-lg"
                      >
                        <option value="">
                          <FormattedMessage
                            id="RECRUITER.SCHEDULEWORKORDER.INPUT.SELECT.RECURRENCE"
                            defaultMessage="Select Recurrence"
                          />
                        </option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                      </DropDown>
                    </div>
                    {recurrence !== "Daily" && recurrence != "" && (
                      <div className="py-3">
                        {recurrence === "weekly" ? (
                          <div className="mb-4">
                            <div>
                              <p className="pb-2 font-satoshiBold">
                                <FormattedMessage
                                  id="RECRUITER.SCHEDULEWORKORDER.INPUT.REPEATON"
                                  defaultMessage="Repeat on"
                                />
                              </p>
                              <div className="flex space-x-2">
                                {Days.map((day) => (
                                  <span
                                    onClick={() => toggleDay(day?.id, days, setDays)}
                                    className={`${
                                      days.includes(day.id)
                                        ? "bg-[#2557A7] text-white"
                                        : "bg-white border border-neutral-200"
                                    } cursor-pointer rounded-full h-10 w-10 flex justify-center items-center`}
                                  >
                                    {day.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <ScheduleCalendarBottom endsHandle={endsHandle} />
                          </div>
                        ) : null}
                        {recurrence === "monthly" && (
                          <div className="mb-4">
                            <div className="flex">
                              <DropDown
                                value={rmdt}
                                dropdowndiv="mt-0"
                                className="rounded-lg"
                                onChange={(e) => setRmdt(e.target.value)}
                              >
                                <option
                                  value={"DayNumber"}
                                >{`Monthly on day ${currentDate}`}</option>
                                <option value={"DayName"}>
                                  Monthly on the last {getDayname(currentDay)}
                                </option>
                              </DropDown>
                            </div>
                            <ScheduleCalendarBottom endsHandle={endsHandle} />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex space-x-10 ">
              <WhiteBtn
                onClick={()=> HandleScheduleWorkOrder()}
                mainClass="my-3 w-50 flex justify-center"
                title={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.SAVEANDPUBLISHBUTTON"
                    defaultMessage="Publish Work Order"
                  />
                }
              ></WhiteBtn>
              <WhiteBtn
                onClick={()=> {setIsDraft(true);HandleScheduleWorkOrder()}}
                mainClass="my-3 w-50 flex justify-center"
                title={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.SAVEBUTTON"
                    defaultMessage="Save as Draft Work Order"
                  />
                }
              ></WhiteBtn>
              </div>

            </>
          ) : null}{" "}
        </div>
      </div>
    </MainWrapper>
    {showModel && 
      <ModelWrapper noCancel={true} HandleModelByProps={HandleModelByProps} rightPosition='inset-0' rounded="rounded-[10px]" background="bg-[#00000099]" opacity="opacity-100" showModal={showModel} width='w-[45%] mx-auto' position="fixed" topPosition="top-[10%]">
          <div className="flex items-center justify-between rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                    <span onClick={()=> setShowModel(false)} className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <p className="text-xl font-satoshiBold mx-6"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.TITLE"></FormattedMessage></p>
                <div className='lg:flex lg:flex-col w-full block p-6 items-center rounded-xl h-fit mb-4 mx-5 '>
            <ol className="relative border-l border-gray-800 w-full">
              <li  className="mb-6 ml-6 cursor-pointer">
                <span className={`absolute mt-1 flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 0 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 0 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-satoshiBold text-xl"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.CREATECUSTOMER"   defaultMessage='Add Customer'/></h3>
            <p><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.CREATECUSTOMER.DESCRIPTION" defaultMessage=''></FormattedMessage></p>
              <ClickForAdd1  onClick={()=> {setShowModel(false); setShowAddModal('Customer')}}></ClickForAdd1>
              </li>
              <li  className="mb-6 ml-6 cursor-pointer">
                <span className={`absolute mt-1 flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-satoshiBold text-xl"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCUSTOMERLOCATION" defaultMessage="Add Customer Location" /></h3>
                <p><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCUSTOMERLOCATION.DESCRIPTION" /></p>
                {/* <ClickForAdd link={'/add-customer'}></ClickForAdd> */}
              </li>
              <li  className="mb-6 ml-6 cursor-pointer">
                <span className={`absolute mt-1 flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 3 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 2 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-satoshiBold text-xl"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCHECKLIST" defaultMessage="Add Checklist" /></h3>
               <p><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCHECKLIST.DESCRIPTION" ></FormattedMessage></p>
               {/* <ClickForAdd link={'/add-customer'}></ClickForAdd> */}
              </li>
              <li className="mb-6 ml-6 cursor-pointer">
                <span className={`absolute mt-1 flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 4 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 3 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-satoshiBold text-xl"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCONTRACTOR" defaultMessage="Add Contractor" /></h3>
                <p><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDCONTRACTOR.DESCRIPTION"></FormattedMessage></p>
                <ClickForAdd link={'/add-contractor'}></ClickForAdd>
              </li>
              <li className="mb-6 ml-6 cursor-pointer">
                <span className={`absolute mt-1 flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 5 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 4 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-satoshiBold text-xl"><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDSUPERVISOR" defaultMessage="Add Supervisor" /></h3>
                <p><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.STEPGUIDE.ADDSUPERVISOR.DESCRIPTION"></FormattedMessage></p>
                <ClickForAdd link={'/add-supervisor'}></ClickForAdd>
              </li>
          
            </ol>
          </div>
      </ModelWrapper>
}
{showAddModel==='Customer' &&
  <AddCustomer SetSelectedCustomer={SetSelectedCustomer} onCheckModel={onCheckModel} showAddModel={showAddModel} loadCustomerData={loadCustomerData}></AddCustomer>
}
{showAddModel==='Location' &&
  <AddLocation fetchLocation={fetchLocation} setLocation={setLocation} customerId={selectedCustomer} onCheckModel={onCheckModel} showAddModel={showAddModel}></AddLocation>
}
{showAddModel==='Checklist' &&
  <AddChecklistD fetchChecklist={fetchChecklist} setChecklist={setChecklist} customerId={selectedCustomer} onCheckModel={onCheckModel} showAddModel={showAddModel} ></AddChecklistD>
}
</>
  );
};
