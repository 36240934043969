import React, {ForwardRefRenderFunction, forwardRef, useEffect, useState} from 'react'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useFormik } from 'formik'
import { createChecklist } from 'sr/utils/api/company/customer/createChecklist'
import ChecklistSchema from 'sr/utils/schemas/company/customer/ChecklistSchema'
import { getChecklist } from 'sr/utils/api/company/customer/getChecklist'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateChecklist } from 'sr/utils/api/company/customer/updateChecklist'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
interface TabRef {
  submitForm: () => void;
}
export interface ChecklistFormType {
  dataAddedHandle?: (arg: any) => void | null;
  customer_Id?:any
  
}
const ChecklistForm: ForwardRefRenderFunction<TabRef, ChecklistFormType> = ({dataAddedHandle=null, customer_Id=''}, ref) => {
const navigate = useNavigate()
  const intl = useIntl()
    const [ChecklistName, setChecklistName] = useState()
    const [checklistType, setChecklistType] = useState('')
    const [subtype, setsubtype] = useState('')
    const [companyId, setCompanyId] = useState(null)
    const [customerId, setCustomerId] = useState(null)
    const [checklistId, setchecklistId] = useState(null)
    const [loader, setLoader] = useState('')
    const params = useParams()
  const location = useLocation()
    useEffect(()=> {
        const userDetails = getUserDetails()
        setCompanyId(userDetails?.user_id)
    },[])
    useEffect(()=> {
            const {id, checklistId} = params
            if(id){
            setCustomerId(id)
            }
            setchecklistId(checklistId)
    },[params])
useEffect(()=> {
console.log(location?.pathname);

},[location.pathname])
    const InitialValues = {
      company_id:companyId,
      customer_id:customerId,
     name:ChecklistName,
     type:checklistType,
     subtype:subtype,
     status:'draft'
   }
   const InitialValuesPut = {
    company_id:companyId,
      customer_id:customerId,
      id:checklistId,
     name:ChecklistName,
     type:checklistType,
     subtype:subtype,
     status:'active'
   }
   const FetchChecklist = async ()=> {
try {
  setLoader('fetch')
  const queryParams = new URLSearchParams()
  if (checklistId) queryParams.append('id', checklistId.toString())
  const res = await getChecklist(queryParams)
if(res.success===true){
  setChecklistName(res?.data?.name)
  setChecklistType(res?.data?.type)
  setsubtype(res?.data?.subtype)
  setLoader('')
}
else {
  setLoader('')
}
}
catch(e) {
  setLoader('')
}
   }

   useEffect(()=> {
      if(checklistId){
        FetchChecklist()
      }
   },[checklistId])
   const {errors, submitForm} = useFormik({
    initialValues:checklistId ? InitialValuesPut : InitialValues,
    enableReinitialize:true,
    validationSchema:ChecklistSchema,
    onSubmit:async (values)=> {
      setLoader('submit')
      try {
        let res;
          if(checklistId) {
             res = await updateChecklist(values)
          }
          else {
          res = await createChecklist(values)
          }
          if(res.success===true) {
            setLoader('')
            if(!checklistId){
            navigate(`${location.pathname}/${res?.data?.id}`) 
            }
            dataAddedHandle('Checklist') 
          }
      }
      catch(e) {
setLoader('')
      }
  }
  })
React.useImperativeHandle(ref, () => ({
  submitForm,
}));
useEffect(()=> {
if(customer_Id){
  setCustomerId(customer_Id)
}
},[customer_Id])
  return (

   <ContentWithSpinner loader={loader==='fetch'}>
    {loader==='submit' && <LoaderFullScreen />}
    <Input error={errors.name} onChange={(e)=> setChecklistName(e.target.value)} value={ChecklistName} label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CHECKLIST.TITLE" defaultMessage="Checklist Name" />}
                        placeholder={intl.formatMessage({id:"RECRUITER.CUSTOMER.INPUT.CHECKLIST.PLACEHOLDER", defaultMessage:"Enter Checklist Name" }) }
                        type={'text'}
                        inputClass = 'border border-zinc-800 rounded-lg h-11'>
                    </Input>
    <DropDown error={errors.type} dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CHECKLISTTYPE.TITLE" defaultMessage="Checklist Type" />} value={checklistType} onChange={(e) => setChecklistType(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''>{<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CHECKLISTTYPE.SELECT" defaultMessage="Select Checklist Type" />}</option>
                        <option value='Daily'>Daily</option>
                        <option value='Weekly'>Weekly</option>
                    </DropDown>
                  
                    <DropDown error={errors.subtype} dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.SUBTYPES.TITLE" defaultMessage="Sub Types" />} value={subtype} onChange={(e) => setsubtype(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''>{<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.SUBTYPES.PLACEHOLDER" defaultMessage="Select Sub Types" />}</option>
                        <option value='Hospital'>Hospital</option>
                        <option value='Office'>Office</option>
                    </DropDown>
                    
                    </ContentWithSpinner>
  )
}
export default forwardRef(ChecklistForm)