import React, { useRef, useState } from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { LevelBar } from 'sr/helpers/ui-components/LevelBar'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { CustomerTab } from './components/CustomerTab'
import GeneralTab  from './components/AddCustomer/GeneralTab'
import { LocationTab } from './components/AddCustomer/LocationTab'
import { ChecklistTab } from './components/AddCustomer/ChecklistTab'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ModelWrapper } from '../component/ModelWrapper'
import { CutConfirmation } from './components/AddCustomer/component/CutConfirmation'
export const AddCustomer = () => {
    const navigate = useNavigate();
    const [tab, settab] = useState('General')
    const [showDelete, setShowDelete] = useState(false)
    const TabHandle = (data:any) => {
      settab(data)
    }
    interface TabRef {
      submitForm: () => void;
    }
    
    const childRef = useRef<TabRef>(null)
    const HandleButtonClick = ()=> {
      if (childRef.current && childRef.current.submitForm) {
        childRef.current.submitForm()
      }
    //  TabHandle('Location')
    }
    const dataAddedHandle = (data)=> {
      if(data===true) {
        TabHandle('Location')
      }
    }
    const HandleModelByProps = ()=> {
      setShowDelete(false)
    }
    const handleDelete = ()=> {
navigate(-1)
    }
    const handleShowDelete = ()=> {
        if(tab==='Checklist'){
          navigate(-1)
        }
        else {
          setShowDelete(true)
        }
    }
  return (
    <MainWrapper>
      <div className='bg-white'>
        <div className='flex items-center space-x-4'>
          <button
            className="p-2 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleShowDelete}>
            <span className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
    <div className='pt-10 container mx-auto px-10'>
      <CustomerTab tab={tab} TabHandle={TabHandle} tabChange={false}/>
    {tab === 'General' &&
                <>
                   <GeneralTab ref={childRef} dataAddedHandle={dataAddedHandle}/>
                   <Button onClick={HandleButtonClick} text={<FormattedMessage id="RECRUITER.CUSTOMER.NEXT.BUTTON" defaultMessage="Next" />}></Button>
                  
                </>

            }
            {tab === 'Location' &&
                <>
                   <LocationTab TabHandle={TabHandle} />
                </>

            }
              {tab ===  'Checklist' &&
                <>
                   <ChecklistTab/>
                </>

            }
            </div></div>
            {showDelete &&
            <ModelWrapper rounded="rounded-[10px]"
                background="bg-[#00000099]"
                position="fixed"
                opacity="opacity-100"
                topPosition="top-[10%]"
                rightPosition="inset-0"
                width="w-[50%] mx-auto" showModal={showDelete} 
                HandleModelByProps={HandleModelByProps}>
                          <CutConfirmation  onCancel={()=> HandleModelByProps()} onDelete={handleDelete}/>
                    

                </ModelWrapper>
            }
            </MainWrapper>
  )
}
