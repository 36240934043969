const getTimeFromDate = (date)=> {
    if(date){
        const newDate =  new Date(date)
  const hours = String(newDate?.getHours()).padStart(2, '0');
const minutes = String(newDate?.getMinutes()).padStart(2, '0');
const seconds = String(newDate?.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
    }
    else {
        return ''
    }
}
export default getTimeFromDate;