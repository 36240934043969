import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import { getFile } from "sr/utils/api/getFile";
import { ChatLoader } from "./ChatLoader";

export const ChatImg = ({item}) => {
    const [imageUrl, setImageUrl] = useState<string>()
    const [loader, setLoader] = useState(true)
    useEffect(()=> {
            const fetchImg = async (key)=> {
try {
const fileimg = await getFile(key)
  if(fileimg) {
    const newImageUrl = URL.createObjectURL(fileimg);
    setImageUrl(newImageUrl)
    setLoader(false)
  }
  else {
    setLoader(false)
  }
}
catch(e) {
setLoader(false)
}
            }
            if(item?.file_id) {
                fetchImg(item?.file_id)
            }
    },[item?.file_id])
  return (
    <div className="w-96">
    <ContentWithLoader loading={loader} 
    content={<ChatLoader />}>
    <img alt="Profile" className={`cursor-pointer`}
        src={imageUrl}/>
       </ContentWithLoader>
        </div>
    );};
