import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation"

export const fetchCustomerLocations = async (params)=> {
    try {
   if (params?.setLoader) params?.setLoader(true)
  const queryParams = new URLSearchParams()
   if(params?.customerId) queryParams?.append('customer_id', params?.customerId)
 if (params?.companyId) queryParams?.append('company_id', params?.companyId)
 if (params?.customerLocationId) queryParams.append('id', params?.customerLocationId)
  queryParams.append('status!','deleted')
      const res = await getCustomerLocation(queryParams)
      if(res?.success===true) {
        console.log(params?.customerLocationId, res?.data);
        
      if (params?.setCustomerLocations) params?.setCustomerLocations(res?.data)
        if (params?.setLoader) params?.setLoader(false)
        return res?.data
      }
      else {
        if (params?.setLoader) params?.setLoader(false)
      }
    }
    catch(e:any){
      if (params?.setLoader) params?.setLoader(false)
    }
}