import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateJob = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/individual/job', putData).then((res)=> {
            if(res.success===true){
                return res
            }
            else {
                return null
            }
  })
}
catch(e){
HandleApiError(e)
}
};
