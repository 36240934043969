import React, { useState } from "react";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { useNavigate } from "react-router-dom";
import { AddChecklist } from "../../Customer/components/AddCustomer/component/AddChecklist";

export const AddChecklistD = (props) => {
      const [isEditCheckList, setIsEditCheckList] = useState(false);
      const [TaskPopup, setTaskPopup] = useState(false);
      const navigate = useNavigate();
  const onCheckModel = (data) => {
    setIsEditCheckList(false);
    props?.onCheckModel()
    navigate(`/schedule-work-order`) 
  };
  const HandleAddChecklist = () => {
    setTaskPopup(false)
    props?.onCheckModel()
    props?.fetchChecklist(props?.customerId)
    navigate(`/schedule-work-order`) 
  };
  return (
    <>
      <div className="py-4">
        <ModelWrapperWithButton
          showModal={props?.showAddModel}
          Save={HandleAddChecklist}
          onCheckModel={onCheckModel}
        >
          <AddChecklist isEditCheckList={isEditCheckList} customer_Id={props?.customerId}></AddChecklist>
        </ModelWrapperWithButton>
      </div>
     
    </>
);
};
