import { getFile } from "sr/utils/api/getFile";
interface params {
  resumeName:any, 
  resumeKey:any, 
  setLoader?:any
}
export const DownloadResume = async (params:params) => {
  params?.setLoader && params?.setLoader(true)
    const resumeData = await getFile(params?.resumeKey)
    if(resumeData) {
      const fileUrl =  URL.createObjectURL(resumeData);
      
          const fileName = params?.resumeName;
      const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
  
    // Append the anchor to the body
    document.body.appendChild(link);
  
    // Trigger the click event to start the download
    link.click();
    params?.setLoader && params?.setLoader(false)
    // Remove the anchor from the body
    document.body.removeChild(link);
    }
  }