import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const transferBalance = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/wallet/fabric/transfer', postData).then((res)=>{
    if(res?.success) {
            return res
    }
    else {
        return null
    }
  })
}
catch(e){
HandleApiError(e)
}
};
