import { Empty } from "app/pages/company/candidates/component/Empty";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TableRow } from "./history/TableRow";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { Button } from "sr/helpers/ui-components/Button";
import { Filter } from "app/pages/company/work-order/component/Filter";

export const History = () => {
    const intl = useIntl()
  return (

    <>
    <div className="flex space-x-3 justify-normal mb-5">
    <Filter width="w-96" name={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.DATE.RANGE" defaultMessage='Date Range' /> } label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.DATE.RANGE" defaultMessage='Date Range' /> }>
    <div className="px-3">
        <div className="font-satoshiBold text-base ">Date Range</div>
    <DropDown dropdowndiv={'mt-5'} label = {<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.MONTH.LABEL"></FormattedMessage>}>
        <option>Jan 2024</option>
        <option>Feb 2024</option>
    </DropDown>
    <div className="flex justify-between space-x-5">
        <ReactDatePicker InputDivClass={'mt-5'} label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.FROM"></FormattedMessage>}></ReactDatePicker>
        <ReactDatePicker InputDivClass={'mt-5'} label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.TO"></FormattedMessage>}></ReactDatePicker>
    </div>
    </div>
    <div className="bg-gray-200 border-b my-2"></div>
    <div className="flex justify-end my-3">
    <Button text={<FormattedMessage id="CLEANER.WALLET.DATE.RANGE.FILTER.APPLY" defaultMessage="Apply" />} className={'bg-[#2557A7] text-white capitalize '} hoverbg={'bg-[#2557A7]'}></Button>
       </div>
    </Filter>
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px] border border-neutral-200">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  Date
</th>
              
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
 Activity
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  Description
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
 From
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
               Order
                </th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
 Amount
</th>

            </tr>
        </thead>
        <tbody>
        <TableRow/>
          
        </tbody>
    </table>  
    </div>
    </>
);
};
