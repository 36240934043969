import React from 'react';

export const RadioInput = ({ name, value, checked, onChange, label, icon=null }) => {
  return (
    <div className={`${icon ?'flex space-x-3 items-center' : ''}`}>
      {icon && 
      icon
      }
    <label className="flex items-center py-1.5 relative cursor-pointer">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className=""
      />
      <span className="ml-2 text-sm	 text-gray-500 font-satoshiBold font-bold">{label}</span>

    </label>
    </div>
  );
};