import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createStripCustomer = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/stripepayments/customer', postData).then((res) => {
    if(res?.success) {
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
