import React, { useEffect, useState } from "react";
import { ModelWrapper } from "../../component/ModelWrapper";
import { WhiteBtn } from "./WhiteBtn";
import { Checkbox, TextArea } from "sr/helpers";
import { Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { RadioInput } from "sr/helpers/ui-components/company/RadioInput";
import { FormattedMessage, useIntl } from "react-intl";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getChecklist } from "sr/utils/api/company/customer/getChecklist";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { ScheduleCalendarBottom } from "../ManageSchedule/components/ScheduleCalendarBottom";
import { useFormik } from "formik";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { useGet } from "../../Hooks/Api/useGet";
import { getWorkOrderModel } from "sr/utils/api/company/workorder/getWorkOrderModel";
import { updateWorkOrderModel } from "sr/utils/api/company/workorder/updateWorkOrderModel";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { useAuth } from "app/pages/module/auth";
import { fetchCustomerData } from "sr/helpers/apiFunctions/fetchCustomerData";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { Days } from "./Days";
import { getDayname } from "./getDayname";
import { toggleDay } from "./toggleDay";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks";
import startOfMonth from "date-fns/startOfMonth";
export const WorkOrderPopup = (props) => {
  const [type, setType] = useState("");
  const [contractor, setContractor] = useState("");
  const [location, setLocation] = useState("");
  const [selectedCustomer, SetSelectedCustomer] = useState<any[]>([]);
  const [workHours, setWorkHours] = useState();
  const [selectpay, setSelectpay] = useState("");
  const [fixPrice, setFixPrice] = useState("");
  const [checklist, setChecklist] = useState("");
  const intl = useIntl();
  const [companyId, setCompanyId] = useState<string>();
  const [userId, setUserId] = useState()
  const [loader, setLoader] = useState(false);
  const [entryTime, setEntryTime] = useState(null);
  const [exitTime, setExitTime] = useState(null);
  const [description, setDescription] = useState("");
  const [currentDate, setcurrentDate] = useState<any>();
  const [onetimeDate, setOneTimeDate] = useState();
  const [repeatEvery, setRepeatEvery] = useState();
  const [ends, setEnds] = useState<any>({});
  const [rmdt, setRmdt] = useState();
  const [scheduleType, setScheduleType] = useState("");
  const [recurrence, setRecurrence] = useState();
  const [days, setDays] = useState<any[]>([]);
  const [endsData, setEndsData] = useState<any>({});
  const [cleanerId, setCleanerId] = useState<string>();
  const [status, setStatus] = useState('');
  const [currentDay, setCurrentDay] = useState<any>();
  const [currentweek, setCurrentWeek] = useState<any>();
  const [saveLoader, setSaveLoader] = useState(false)
  const [customer, SetCustomer] = useState<any>()
  const [customerLocationId, setCustomerLocationId] = useState<string>()
  const {userType} = useAuth()
  const [contractors, setContractors] = useState<any>()
  const [workOrderStatus, setWorkOrderStatus]= useState()
  const [title, setTitle] = useState<string>('')
  useEffect(() => {
    const { user_id } = getUserDetails();
    if(userType==='supervisor'){
      setUserId(user_id)
    }
    if(userType==='company'){
      setCompanyId(user_id);
      setUserId(user_id)
    }
  }, []);
  useEffect(() => {
    const date = new Date();
    setcurrentDate(date.getDate());
    setCurrentDay(date.getDay());
    const start = startOfMonth(date);
    const week = differenceInCalendarWeeks(date, start) + 1;
    setCurrentWeek(week);
  }, []);
  
  const initialValueOneTime = {
    id:props?.editId,
    company_id: companyId,
    customer_id: selectedCustomer,
    customer_location_id: location,
    checklist_id: checklist,
    type: type,
    time_for_work_completion: workHours,
    pay_type: selectpay,
    pay_type_rate: fixPrice,
    entry_time: entryTime,
    exit_time: exitTime,
    description: description,
    recurring: scheduleType === "One Time" && false,
    one_time_date: onetimeDate,
    title:title,
    workorder_status: "scheduled",
    status:'publish'
  }; 
  useEffect(()=> {
if(type==='open'){
  setContractor('')
}
  },[type])

  const initialValueRecurring = {
    id:props?.editId,
    company_id: companyId,
    customer_id: selectedCustomer,
    customer_location_id: location,
    checklist_id: checklist,
    cleaner_id: cleanerId,
    type: type,
    time_for_work_completion: workHours,
    pay_type: selectpay,
    pay_type_rate:fixPrice,
    entry_time: entryTime,
    exit_time: exitTime,
    description: description,
    recurring: scheduleType === "Recurring" && true,
    repeat_count: repeatEvery,
    repeat_type: recurrence,
    repeat_month_day_of_week: rmdt === "DayName" ? currentDay : null,
    repeat_month_week: rmdt === "DayName" ? currentweek : null,
    repeat_month_day_of_month: rmdt === "DayNumber" ? currentDate : null,
    repeat_week_day: days,
    ends: ends,
    title:title,
    workorder_status:"scheduled",
   status:'publish'
  };

  const { errors, handleSubmit, setFieldValue } = useFormik({
    initialValues:
    props?.page==='Workordermodel' ? contractor ? {contractor_id:contractor, ...initialValueRecurring}:initialValueRecurring : contractor ?{contractor_id:contractor, ...initialValueOneTime}: initialValueOneTime,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSaveLoader(true)
      let res;
      if(props?.page==='Workordermodel'){
        res = await updateWorkOrderModel(values)
      }
      else {
        res = await updateWorkorder(values);
      }
      if (res?.success === true) {
        SuccessMessage('API.SUCCESS.WORKORDER.UPDATE')
        setLoader(false)
        props?.HandleModelByProps(true);
   
      }
    },
  });
  const endsHandle = (data) => {
    setEnds(data);
  };

  const dispatch = useDispatch<AppDispatch>();
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    useEffect(()=> {
      if(userId && userType==='supervisor') {
        if(!supervisorData){
          dispatch(fetchSupervisor({id:userId}))
        }
      }
},[userId, userType, dispatch])
useEffect(()=> {
  const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
  setCustomerLocationId(id?.join());
  
 },[supervisorData])
  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setLoader(true);
        const queryParams = new URLSearchParams();
        if (id) queryParams.append("id", id);
        let res;
        if (props?.page==='Workordermodel') {
            res = await getWorkOrderModel(queryParams)
        }
        else {
        res = await getWorkorder(queryParams);
        }
        if (res?.success) {
          SetSelectedCustomer(res?.data?.customer_id?._id);
            console.log(res?.data?.customer_id?._id);
            
          setLocation(res?.data?.customer_location_id?._id);
          setChecklist(res?.data?.checklist_id?._id);
          setContractor(res?.data?.contractor_id?._id)
          setType(res?.data?.type?.toLowerCase());
          setWorkHours(res?.data?.time_for_work_completion);
          setSelectpay(res?.data?.pay_type);
          if (res?.data?.pay_type === "Fixed Rate") {
            setFixPrice(res?.data?.pay_type_rate);
          }
          setEntryTime(res?.data?.entry_time);
          setExitTime(res?.data?.exit_time);
          setDescription(res?.data?.description);
          setTitle(res?.data?.title)
          if(res?.data?.recurring === true){
            setScheduleType('Recurring');
          }
          else {
            setScheduleType('One Time')
          }
          setOneTimeDate(res?.data?.one_time_date);
          setRepeatEvery(res?.data?.repeat_count);
          setRecurrence(res?.data?.repeat_type);
          setRmdt(res?.data?.repeat_month_day_date);
          setDays(res?.data?.repeat_week_day);
          setEndsData(res?.data?.ends);
          setLoader(false);
          setStatus(res?.data?.status)
          setWorkOrderStatus(res?.data?.workorder_status)
        } else {
          setLoader(false);
        }
      } catch (e) {
        setLoader(false);
      }
    };
    if (props?.editId) {
      fetchData(props?.editId);
    }
  }, [props?.editId]);
  const {
    getData: getLocation,
    loader: LocationLoader,
    data: customerLocations,
  } = useGet();
  const {
    getData: getChecklistData,
    loader: ChecklistLoader,
    data: checklistdetails,
  } = useGet();

  const loadCustomerData = async ()=> {
    await fetchCustomerData({
      userId:userId, userType:userType,  customerLocationId:customerLocationId,
       setData:SetCustomer}
    );
  }

  const fetchLocation = async (customerId) => {
    const queryParams = new URLSearchParams();
    if (customerId) queryParams.append("customer_id", customerId.toString());
    queryParams.append("limit", "100");
    await getLocation(() => getCustomerLocation(queryParams));
  };
  const fetchChecklist = async (customerId) => {
    const queryParams = new URLSearchParams();
    if (customerId) queryParams.append("customer_id", customerId);
    queryParams.append("status", "active");
    queryParams.append("limit", "100");
    await getChecklistData(() => getChecklist(queryParams));
  };
  useEffect(() => {
    if (userId && userType) {
      if(userType==='supervisor'){
        if(customerLocationId){
      loadCustomerData();
      loadContractor();
    }}
    else {
      loadCustomerData()
      loadContractor();
    }}
  }, [userId, customerLocationId]);
  useEffect(() => {
    if (selectedCustomer) {
      fetchLocation(selectedCustomer);
      fetchChecklist(selectedCustomer);
    }
  }, [selectedCustomer]);

  const loadContractor = async () => {
    await fetchContractor({userType:userType, userId:userId, customerLocationId:customerLocationId, setData:setContractors, limit:1000})
  };


const HandleDraft = ()=> {
    setFieldValue('status', 'draft')
    handleSubmit()
}
useEffect(()=>{
console.log(props);

},[props])

  return (
    <ModelWrapper
      rounded="rounded-[10px]"
      background="bg-[#00000099]"
      position="fixed"
      opacity="opacity-100"
      topPosition="top-[10%]"
      rightPosition="inset-0"
      width="w-[50%] mx-auto"
      showModal={props?.showModal || props?.editId}
      HandleModelByProps={props.HandleModelByProps}
    >
      {saveLoader && <LoaderFullScreen />}
      <ContentWithSpinner loader={loader} height="h-80">
        <div className="flex justify-end space-x-2 py-3 px-3 mb-3 border-b border-solid border-slate-200">
    
          <WhiteBtn
            onClick={() => {
              HandleDraft()
            }}
            title={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.EDIT.BUTTON.DRAFT"
                defaultMessage="Draft"
              />
            }
          ></WhiteBtn>

          <WhiteBtn
            onClick={() => {
              handleSubmit();
            }}
            title={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.EDIT.BUTTON.PUBLISH"
                defaultMessage="Publish"
              />
            }
          ></WhiteBtn>

          <WhiteBtn
            onClick={() => props.HandleModelByProps(false)}
            title={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.EDIT.BUTTON.CANCEL"
                defaultMessage="Cancel"
              />
            }
          ></WhiteBtn>
        </div>
        <div className="px-5">
        <Input
                  error={errors?.title}
                  label={
                    intl.formatMessage({
                      id:"RECRUITER.SCHEDULEWORKORDER.INPUT.WORKORDER.TITLE",
                      defaultMessage:"Title"
                    })
                  }
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-0 w-26 pr-2 pb-2"
                  placeholder={intl.formatMessage({id:"RECRUITER.SCHEDULEWORKORDER.INPUT.WORKORDER.TITLE.PLACEHOLDER" })}
                  value={title}
                />
               
          <DropDown
            labelClass="text-base"
            value={selectedCustomer}
            label={
              <FormattedMessage
                id="RECRUITER.WORKORDER.FILTER.CUSTOMER.TITLE"
                defaultMessage="Customer"
              />
            }
            dropdowndiv="mt-3"
            onChange={(e) => SetSelectedCustomer(e.target.value)}
            className="rounded-lg"
          >
            <option value="">
              <FormattedMessage
                id="RECRUITER.WORKORDER.FILTER.SELECTCUSTOMER"
                defaultMessage="Select Customer"
              />
            </option>
            {customer?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </DropDown>
          <DropDown
            labelClass="text-base"
            value={location}
            label={
              <FormattedMessage
                id="RECRUITER.WORKORDER.FILTER.LOCATION"
                defaultMessage="Location"
              />
            }
            dropdowndiv="mt-1 pr-2 pb-2"
            onChange={(e) => setLocation(e.target.value)}
            className="rounded-lg"
          >
            <option value="">
              <FormattedMessage
                id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION"
                defaultMessage="Select Location"
              />
            </option>
            {customerLocations?.map((item, index) => (
              <option value={item?.id}>{item?.address?.city}</option>
            ))}
          </DropDown>
          <DropDown
            labelClass="text-base"
            value={checklist}
            label={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.INPUT.CHECKLIST.TITLE"
                defaultMessage="Checklist"
              />
            }
            dropdowndiv="mt-1 pr-2 pb-2"
            onChange={(e) => setChecklist(e.target.value)}
            className="rounded-lg"
          >
            <option value="">
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.INPUT.CHECKLIST.SELECT"
                defaultMessage="Select Checklist"
              />
            </option>
            {checklistdetails?.map((item, index) => (
              <option value={item?.id}>{item?.name}</option>
            ))}
          </DropDown>
          <div className="flex items-center mt-3">
            <div className="flex items-centerspace-x-3">
              <label className="font-satoshiBold inline-block" htmlFor="Type">
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.TITLE"
                  defaultMessage="Type"
                />
              </label>
              <RadioInput
                name="Type"
                value="open"
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.OPEN"
                    defaultMessage="Open"
                  />
                }
                checked={type?.toLowerCase() === "open"}
                onChange={() => setType("open")}
              ></RadioInput>
              <RadioInput
                name="Type"
                value="individual"
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.TYPE.INDIVIDUAL"
                    defaultMessage="Individual"
                  />
                }
                checked={type?.toLowerCase() === "individual"}
                onChange={() => setType("individual")}
              ></RadioInput>
            </div>
            {type?.toLowerCase() === "individual" && (
              <DropDown
                labelClass="text-base"
                value={contractor}
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE"
                    defaultMessage="Contractor"
                  />
                }
                dropdowndiv="mt-1 w-26 pr-2 pb-2"
                onChange={(e) => setContractor(e.target.value)}
                className="rounded-lg"
              >
                <option value="">
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.SELECT"
                    defaultMessage="Select Contractor"
                  />
                </option>
                {contractors?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.first_name} {data?.last_name}
                  </option>
                ))}
              </DropDown>
            )}
          </div>
          <Input
            label={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.INPUT.TITLE"
                defaultMessage="Time for work completion"
              />
            }
            onChange={(e) => setWorkHours(e.target.value)}
            type="number"
            inputClass="rounded-lg"
            labelClass="mt-2 text-black"
            InputDivClass="mt-0 w-26 pr-2 pb-2"
            placeholder={intl.formatMessage({
              id: "RECRUITER.SCHEDULEWORKORDER.INPUT.TITLE.PLACEHOLDER",
              defaultMessage: "Number of hours",
            })}
            value={workHours}
          />
          <div className="flex items-center mt-3 space-x-3">
            <div className="flex space-x-3">
              <label className="font-satoshiBold" htmlFor="Pay">
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.TITLE"
                  defaultMessage="Pay Type"
                />
              </label>
              <RadioInput
                name="Pay"
                value="Fixed Rate"
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.FIXPRICE"
                    defaultMessage="Fixed Rate"
                  />
                }
                checked={selectpay === "Fixed Rate"}
                onChange={() => setSelectpay("Fixed Rate")}
              ></RadioInput>
            
            </div>
            {selectpay === "Fixed Rate" && (
              <Input
                inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.FIXPRICE.INPUT.PLACEHOLDER"
                    defaultMessage="Enter the fixed rate"
                  />
                }
                onChange={(e) => setFixPrice(e.target.value)}
                type="number"
                labelClass="mt-2 text-black"
                InputDivClass="mt-0 w-26 pr-2 pb-2"
                prefix="$"
                value={fixPrice}
              />
            )}
            {/* {selectpay === "Hourly Rate" && (
              <Input
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.PAYTYPE.HOURLYPRICE.INPUT.PLACEHOLDER"
                    defaultMessage="Enter the hourly rate"
                  />
                }
                onChange={(e) => sethourlyRate(e.target.value)}
                type="number"
                inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                labelClass="mt-2 text-black"
                InputDivClass="mt-0 w-26 pr-2 pb-2"
                placeholder=" per hour"
                prefix="$"
                value={hourlyRate}
              />
            )} */}
          </div>
          <div className="flex items-center w-full mt-3">
            <Input
              value={entryTime}
              onChange={(e) => setEntryTime(e.target.value)}
              label={
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                  defaultMessage="Entry Time"
                />
              }
              inputClass="rounded-lg"
              labelClass="mt-2 text-black"
              InputDivClass="mt-0 pr-2 pb-2 w-[50%]"
              type="time"
              placeholder={
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                  defaultMessage="Entry Time"
                />
              }
            ></Input>
            <Input
              value={exitTime}
              onChange={(e) => setExitTime(e.target.value)}
              label={
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.INPUT.EXITTIME"
                  defaultMessage="Exit Time"
                />
              }
              inputClass="rounded-lg"
              labelClass="mt-2 text-black"
              InputDivClass="mt-0 pr-2 pb-2 w-[50%]"
              type="time"
              placeholder={
                <FormattedMessage
                  id="RECRUITER.SCHEDULEWORKORDER.INPUT.EXITTIME"
                  defaultMessage="Exit Time"
                />
              }
            ></Input>
          </div>

          <TextArea
            rows={3}
            placeholder={intl.formatMessage({
              id: "RECRUITER.SCHEDULEWORKORDER.INPUT.DESCRIPTION.PLACEHOLDER",
              defaultMessage: "Write your note here...",
            })}
            className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></TextArea>

          {props?.page==='Workordermodel' ? 
          <>
          {/* <div className='flex items-center pb-3 mb-3 space-x-3'>
                    <label className='font-satoshiBold' htmlFor='ScheduleType'><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE" defaultMessage="Type" /></label>
<RadioInput name="ScheduleType" value="One Time" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE.ONETIME" defaultMessage="One Time" />} checked={scheduleType === 'One Time'} onChange={() => setScheduleType('One Time')}></RadioInput>
<RadioInput name="ScheduleType" value="Recurring" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.SCHEDULETYPE.RECURRING" defaultMessage="Recurring" />} checked={scheduleType === 'Recurring'} onChange={() => setScheduleType('Recurring')}></RadioInput>
                </div> */}

          {/* {scheduleType=== 'One Time' && 
<ReactDatePicker onChange={(date)=> setOneTimeDate(date)} selected={onetimeDate} label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTERDATE" defaultMessage="Enter Date" />} inputClass="rounded-lg" labelClass="mt-2 text-black" InputDivClass="mt-0 w-26 pr-2 pb-2" type="date" placeholder="Enter date"></ReactDatePicker>
} */}
{scheduleType=== 'Recurring' && 
<>

<div className='flex space-x-2 items-center'>
<Input onChange={(e)=> setRepeatEvery(e.target.value)} value={repeatEvery} topinputdiv = "w-16" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.INPUT.REPEATEVERY" defaultMessage="Repeat every" />} inputClass="rounded-lg" labelClass="mt-0 text-black" InputDivClass="mt-0 pr-2 flex space-x-2 items-center" type="number"></Input>
<DropDown value={recurrence} dropdowndiv='mt-0' onChange={(e) => setRecurrence(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.INPUT.SELECT.RECURRENCE" defaultMessage="Select Recurrence" /></option>
    <option value='weekly'>Weekly</option>
    <option value='monthly'>Monthly</option>
  </DropDown>
  </div>
{(recurrence !== 'Daily' && recurrence != '') && 
            <div className='py-3'>          
           {recurrence === 'weekly' ? 
              <div className='mb-4'>
               <div>
                <p className='pb-2 font-satoshiBold'><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.INPUT.REPEATON" defaultMessage="Repeat on" /></p>
                <div className='flex space-x-2'>
                {Days?.map((day, index) => (
                <span key={index} onClick={() => toggleDay(day.id, days, setDays)} className={`${days?.includes(day.id) ? 'bg-[#2557A7] text-white':'bg-white border border-neutral-200'} cursor-pointer rounded-full h-10 w-10 flex justify-center items-center`}>{day.name}</span>
                ))}
                </div>

</div>
<ScheduleCalendarBottom endsHandle={endsHandle} ends = {endsData}/>
              </div>  :null}
              {recurrence === "monthly" && (
                          <div className="mb-4">
                            <div className="flex">
                              <DropDown
                                value={rmdt}
                                dropdowndiv="mt-0"
                                className="rounded-lg"
                                onChange={(e) => setRmdt(e.target.value)}
                              >
                                <option
                                  value={"DayNumber"}
                                >{`Monday on day ${currentDate}`}</option>
                                <option value={"DayName"}>
                                  Monthly on the last {getDayname(currentDay)}
                                </option>
                              </DropDown>
                            </div>
                            <ScheduleCalendarBottom endsHandle={endsHandle} />
                          </div>
                        )}
           </div>
}</>} </> : 
<>
<ReactDatePicker
            onChange={(date) => setOneTimeDate(date)}
            selected={onetimeDate}
            label={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.INPUT.SCHEDULEDATE"
                defaultMessage="Schedule Date"
              />
            }
            inputClass="rounded-lg"
            labelClass="mt-2 text-black"
            InputDivClass="mt-0 w-26 pr-2 pb-2"
            type="date"
            placeholder="Enter date"
          ></ReactDatePicker>
      
          </>}
        </div>
      </ContentWithSpinner>
    </ModelWrapper>
  );
};
