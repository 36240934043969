import React, { useEffect, useState } from "react";
import { GeneralForm } from "../../Customer/components/forms/GeneralForm";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { useFormik } from "formik";
import CustomerSchema from "sr/utils/schemas/company/customer/CustomerSchema";
import { createCustomer } from "sr/utils/api/company/customer/createCustomer";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";

export const AddCustomer = (props) => {
      const [companyId, setCompanyId] = useState()
        const [data, setdata] = useState<any>({})
        const [predata, setpredata] = useState<any>({})
        const [loader, setLoader] = useState(false)
      useEffect(()=> {
const {user_id} = getUserDetails()
setCompanyId(user_id)
      },[])
      const initialValues = {
        company_id:companyId,
        name:data?.customerName,
        mobile_number:data?.phoneNo,
        email:data?.email, 
        type:data?.type,
        remarks:data?.remark,
        status:'active'
    }
    const getData = (data)=> {
            setdata(data)
    }

    const {errors, handleSubmit} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        validationSchema:CustomerSchema,
        onSubmit:async (values)=> {
                setLoader(true)
            try {
            const result = await createCustomer(values)
            if(result?.success===true){
                props?.onCheckModel()
                props?.loadCustomerData()
                props?.SetSelectedCustomer(result?.data?.id)
                setLoader(false)
            }
            }   
            catch(e){
                setLoader(false)
            } 
        }
    })

  return (
    <ModelWrapperWithButton Save={handleSubmit} onCheckModel={props?.onCheckModel} showModal={props?.showAddModel} >
  <GeneralForm getData={getData} errors={errors} allData={predata} ></GeneralForm>
  </ModelWrapperWithButton>
);
};
