import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapper } from '../ModelWrapper'
import { ChatBottom } from './ChatBottom'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import Message from 'app/pages/component/types/message/message'
import Application from 'app/pages/component/types/cleaner/application'
import getTimeFromDate from 'sr/helpers/functions/getTimeFromDate'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { io } from 'socket.io-client'
import { formatISO } from 'date-fns'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { scrollToBottom } from 'sr/helpers/functions/scrollToBottom'
import { useSocket } from 'app/pages/component/SocketContext'
import { uploadFile } from 'sr/utils/api/uploadFile'
import { ChatImg } from 'app/pages/individual/jobs/component/ChatImg'
interface PropsType {
  data:Application
}
export const ChatBox = (props:PropsType) => {
    const [companyId, setCompanyId] = useState()
    const [allmessage, setAllMessage] = useState<any[]>([])
    const [loader, setLoader] = useState(false)
    const socket = useSocket()
    const [message, setMessage] = useState<string>('')
    const [imageId, setImageId] = useState('')
      const [imageUrl, setImageUrl] = useState('')
    const messagesEndRef = useRef(null);
    useEffect(()=>{
        const {user_id} = getUserDetails()
        setCompanyId(user_id)
    },[companyId])
    useEffect(()=> {
      scrollToBottom(messagesEndRef)
      },[allmessage])
    useEffect(() => { 
      if(socket){
        socket.emit('register', companyId);
        socket.off('sendmessage');
      }
      socket.on('sendmessage', ({ from, message }) => {
        if(message?.recipient_id===companyId && message?.sender_id===props?.data?.cleaner_id?._id && message?.job_id===props?.data?.job_id?._id){

        const currentDateTime = formatISO(new Date());
        const newmessage = {createdAt:currentDateTime, ...message}
        setAllMessage((preview)=> [...preview, newmessage])    
        }
      });
      return () => {
        socket.off('sendmessage');
      };
    }, [companyId, props]);
    const fetchChat = async (job_id, sender_id, recipient_id)=> {
        setLoader(true)
        const filter =  {
          "$and":[{"$or":[{"$and":[{"recipient_id":recipient_id}, {"sender_id":sender_id}]},  {"$and":[{"recipient_id":sender_id}, {"sender_id":recipient_id}]}]}]
        }
        const queryParams = new URLSearchParams()
        queryParams?.append('job_id', job_id)
        queryParams.append('filter', JSON.stringify(filter))
    try {
    const res = await getMessage(queryParams)
    if(res?.success===true) {
    setAllMessage(res?.data)
      setLoader(false)
    }
    else {
    setLoader(false)
    }
    }
    catch(e) {
    setLoader(false)
    }
       }
       useEffect(()=> {
          if(props?.data?.job_id?._id) {
            fetchChat(props?.data?.job_id?._id, companyId, props?.data?.cleaner_id?._id)
          }
       },[props?.data])
       const sendMessage = () => {
        const payload = {
            message: message,
            recipient_id: props?.data?.cleaner_id?._id,
            sender_id:companyId,
            job_id:props?.data?.job_id?._id,
            file_id:imageId,
            type:'chat'
        };
        console.log(payload);
    
        socket.emit('sendmessage', payload); // Match the event name in the backend
        setMessage(''); // Clear the input
        const currentDateTime = formatISO(new Date());
        const newmessage = {createdAt:currentDateTime, ...payload}
        setAllMessage((preview)=> [...preview, newmessage])
        setImageId('')
        setImageUrl('')

    };
    useEffect(()=> {
      scrollToBottom(messagesEndRef)
      },[allmessage])
      const handleImageClick = ()=> {
        setImageId('')
        setImageUrl('')
      }
      const handleImage = async (e)=> {
        setLoader(true)
      const formData = new FormData()
      formData.append('company_id', companyId);
      formData.append('chat_img', e.target.files[0]);
      const result = await uploadFile(formData);
          if(result?.success===true) {
            setImageId(result?.data?.files[0].key)
            setImageUrl(URL.createObjectURL(e.target.files[0]))
            setLoader(false)
          }
          else {
            setLoader(false)
          }
      
      
      }
      useEffect(()=>{
console.log(props);

      },[props])
      
  return (
    <>
    {loader && <LoaderFullScreen></LoaderFullScreen>}
    <div>
    <div className='flex justify-between items-center py-3 px-3'>
        <div>
        <h1 className='text-2xl font-satoshiBold text-[#595959]'>{props?.data?.cleaner_id?.first_name} {props?.data?.cleaner_id?.last_name}</h1>
        <p className='font-satoshi'>Applied to {props?.data?.job_id?.job_title} in {props?.data?.job_id?.job_advanced_id?.reporting_address?.city}</p>
        </div>
        <div className='flex items-center justify-center'>
        <div className='mr-8 flex space-x-4 items-center justify-center rounded-lg font-satoshiBold px-5 py-2  text-[#2557A7] hover:bg-[#ecf5ff] hover:border-[#2557A7] cursor-pointer bg-white border border-neutral-200'>      
 <FormattedMessage id="RECRUITER.MESSAGES.VIEWFULL.DETAILS"/>
</div>
{/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-[#2557A7] mr-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<div onClick={()=> setModelshow(!modelShow)} className='mr-8 p-2 hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] hover:rounded-r-lg rounded-lg cursor-pointer relative'>
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-label="More actions" className="w-6 h-6 text-[#2557A7]"><path d="M12 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
                {modelShow &&
              <ModelWrapper rightPosition = 'right-0' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps}>
              <ul className='px-3 pb-2 font-satoshiBold text-base font-bold text-[#2557A7]'>
              <li className='py-3'><FormattedMessage id="RECRUITER.MESSAGES.VIEWCANDIDATEDETAILS.DETAILS" defaultMessage="View candidate details" /></li>
              <li className='py-3'><FormattedMessage id="RECRUITER.MESSAGES.MESSAGING.POLICY.TITLE" defaultMessage="Messaging policy and terms" /></li>
              </ul>
              </ModelWrapper>
                  }
               </div> */}
        </div>
    </div>
<hr className="border-2 border-neutral-200 my-4" />
<div className="flex flex-col h-[60vh] justify-between">
<div ref={messagesEndRef}  className='flex-1 h-[60vh] overflow-auto mb-3 '>
<div className='text-center mb-10'>
Applied {DateFormat(props?.data?.createdAt)}
</div>
<div className="flex items-center justify-center mb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black font-satoshiBold">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
 
{allmessage?.sort((a, b)=> {
  const adate = new Date(a?.createdAt)
  const bdate = new Date(b?.createdAt)

  const differenceInMilliseconds = adate.getTime() - bdate.getTime();
return differenceInMilliseconds
 }).map((item)=> (
   <div>
    {item?.sender_id === companyId ?(
      <div className='flex justify-end items-center space-x-2 px-2'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> You </div>
    <div className='text-sm'>
    {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
  {item?.message}
    </div>
    {item?.file_id &&
                <ChatImg item={item}></ChatImg>
           }
    </div>
</div>
    ):(
      <div className='flex justify-start items-center space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
    {props?.data?.job_id?.company_id?.company_name?.slice(0,1)?.toUpperCase()} 
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='text-sm'>
   {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
{item?.message}
    </div>
     {item?.file_id &&
                <ChatImg item={item}></ChatImg>
           }
    </div>
</div>
)}
</div>
 ))} 

</div>
</div>
<ChatBottom handleImageClick={handleImageClick} imageUrl={imageUrl} uploadChange={handleImage} Send={sendMessage} Clear={()=> setMessage('')} onChange={(e)=> setMessage(e.target.value)} value={message} />

</div></div>
</>
  )
}

