import React from "react";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import EmailLink from "sr/helpers/ui-components/EmailLink";

export const CandidateProfile = ({candidate, training, medical, employment}) => {
    const address_line_1 = candidate?.job_id?.job_advanced_id?.reporting_address?.address_line_1;
  const address_line_2 = candidate?.job_id?.job_advanced_id?.reporting_address?.address_line_2;
  const reportingCity = candidate?.job_id?.job_advanced_id?.reporting_address?.city;
  const candidateAddressLine1 = candidate?.cleaner_id?.address?.address_line_1;
  const candidateAddressLine2 = candidate?.address?.address_line_2
  const candidateCity = candidate?.address?.city;
  return (<>
    <div className='flex space-x-5 mb-5'>
    <div className='w-40 h-40'>
     <div className='transform lg:transform-none -translate-x-1/2'>
     <img alt="Profile" className="rounded" src="/media/icons/displayProfilePlaceholder.png" />
 </div></div>
<div className='py-5'>
<h1 className='text-3xl font-satoshiBold text-[#595959]'>{candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.last_name}</h1>
<p><EmailLink email={candidate?.cleaner_id?.email} subject="Hello" body="Hi, how are you?"></EmailLink>{candidateAddressLine1 || candidateAddressLine2 || candidateCity ? ` - ` : ''}{candidateAddressLine1} {candidateAddressLine2} {candidateCity}</p>
<p>Applied to {candidate?.job_id?.job_title}{address_line_1 || address_line_2 || reportingCity ? ` - ` : ''}{address_line_1} {address_line_2} {reportingCity}
</p>
</div>
</div>
<div>
     <h1 className='text-xl font-satoshiBold pb-2'>Training</h1>
     {training !='' ? training?.map((item, index)=> (
     <div key={index} className='pb-4 mb-4 border-b border-neutral-200'>
         <span className='text-base font-satoshiBold text-black'>{item?.training}</span>
           <div className='text-base'>{DateFormat(item?.date_attended)}
           <p>{item?.description}</p>
         </div>
     </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Training'}}></FormattedMessage></p>
}
     <div>
     <h1 className='text-xl font-satoshiBold pb-2'>Medical Condition</h1>
     {
       medical !='' ? medical?.map((item, index)=> (
     <div className='pb-4 mb-4 border-b border-neutral-200'>
         <span className='text-base font-satoshiBold text-black'>Condition - {item?.condition}</span>
           <div className='text-base'>Since When - {DateFormat(item?.since_when)}  </div>
           <p>{item?.description}</p>
     </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Medical Condition'}}></FormattedMessage></p>

}
     <div>
     <h1 className='text-xl font-satoshiBold pb-2'>Employment</h1>
     {
     employment != '' ?
     employment?.map((item, index)=> (
     <div key={index} className='pb-4 mb-4'>
         <span className='text-base font-satoshiBold text-black'>{item?.job_title} </span>
           <div className='text-base'>{item?.company_name}</div>
           <div className='text-base'>{item?.company_location}</div>
           <div className='text-base'>{item?.currently_employed ? DateFormat(item?.working_since_start) : DateFormat(item?.working_since_start) + '-' + DateFormat(item?.working_since_end) }</div>
     
    </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Employment'}}></FormattedMessage></p>

}
    
    </div></div>
</div> 
</>
);
};
