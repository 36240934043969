import React, { useEffect, useState } from "react";
import { ChecklistTask } from "../../ChecklistTemplate/ChecklistTemplatedata";
import { Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { Button } from "sr/helpers/ui-components/company/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { useFormik } from "formik";
import tasklistSchema from "sr/utils/schemas/company/customer/TasklistSchema";
import { updateTasklist } from "sr/utils/api/company/customer/updateTasklist";
import { useLocation, useParams } from "react-router-dom";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { createTasklist } from "sr/utils/api/company/customer/createTasklist";
import { updateChecklist } from "sr/utils/api/company/customer/updateChecklist";
export const Task = (props) => {
  const [loader, setLoader] = useState(false);
  const [selectType, setselectType] = useState();
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [showEdit, setshowEdit] = useState(null);
  const params = useParams();
  const [checklistId, setchecklistId] = useState(null);
  const [tasks, setTasks] = useState<any>([]);
  useEffect(() => {
    const { id, checklistId } = params;
    setchecklistId(checklistId);
  }, [params]);
  const initialValues = {
    id: showEdit,
    name: task,
    description: description,
    type: selectType,
  };

  const fetchTask = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (showEdit) queryParams.append("id", showEdit.toString());
      const res = await getTask(queryParams);
      if (res.success === true) {
        setTask(res?.data?.name);
        setDescription(res?.data?.description);
        setselectType(res?.data?.type);
      }
    } catch (e) {}
  };
  const fetchAllTask = async () => {
    try {
      setLoader(true);
      const queryParams = new URLSearchParams();
      queryParams.append("status", "active");
      if (checklistId)
        queryParams.append("checklist_id", checklistId.toString());
      const res = await getTask(queryParams);
      if (res.success === true) {
        if(res?.data?.length===1){
        const cPayload = {
          id:checklistId,
          status:'active'
        }
        const cres = await updateChecklist(cPayload)
      }
        setTasks(res?.data);
        setLoader(false);
        props?.getTasks(res?.data)
      } else {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (checklistId) {
      fetchAllTask();
    }
  }, [checklistId]);
  useEffect(() => {
    if (checklistId) {
      fetchAllTask();
    }
  }, [props?.taskadded]);
  const { errors, handleSubmit } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: tasklistSchema,
    onSubmit: async (values) => {
      try {
        const res = await updateTasklist(values);
        if (res.success === true) {
          fetchAllTask();
          setshowEdit("");
        } else {
        }
      } catch (e) {}
    },
  });
  const HandleEdit = (id) => {
    if (id) {
      setshowEdit(id);
    } else {
      setshowEdit(null);
    }
  };
  useEffect(() => {
    if (showEdit) {
      fetchTask();
    }
  }, [showEdit]);
  const intl = useIntl();
  const HandleDeleteTask = async (id) => {
    const formData = {
      id: id,
      status: "draft",
    };
    const res = await updateTasklist(formData);
    if (res?.success === true) {
      fetchAllTask();
    }
  };
  return (
    <ContentWithSpinner loader={loader}>
      {tasks?.length > 0 ? (
        <table className="w-full text-left condidate-table">
          <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.TASK"
                  defaultMessage="Task"
                />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.DESCRIPTION"
                  defaultMessage="Description"
                />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.TYPE"
                  defaultMessage="Type"
                />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.ACTION"
                  defaultMessage="Action"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks?.map((data, index) => (
              <tr key={index} className="bg-white border-b  hover:bg-gray-50">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {showEdit === data?.id ? (
                    <Input
                      InputDivClass="mt-0"
                      onChange={(e) => setTask(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: "RECRUITER.CUSTOMER.INPUT.CHECKLIST.TASK.PLACEHOLDER",
                        defaultMessage: "Enter Task",
                      })}
                      type={"text"}
                      value={task}
                      inputClass="border border-zinc-800 rounded-lg h-11"
                    ></Input>
                  ) : (
                    <div className="text-base">{data.name}</div>
                  )}
                </th>
                <td className="px-6 py-4">
                  {showEdit === data?.id ? (
                    <textarea
                      onChange={(e) => setDescription(e.target.value)}
                      id="Description"
                      value={description}
                      rows={2}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0"
                      placeholder={intl.formatMessage({
                        id: "RECRUITER.CUSTOMER.INPUT.TASK.DESCRIPTION.PLACEHOLDER",
                        defaultMessage: "Write your Description here...",
                      })}
                    ></textarea>
                  ) : (
                    data.description
                  )}
                </td>

                <td className="px-6 py-4">
                  {showEdit === data?.id ? (
                    <DropDown
                      dropdowndiv="text-gray-700 mt-0"
                      labelMargin="mb-1"
                      value={selectType}
                      onChange={(e) => setselectType(e.target.value)}
                      className="border border-zinc-800 rounded-lg h-11"
                    >
                      <option value="">
                        <FormattedMessage
                          id="RECRUITER.CUSTOMER.CHECKLIST.TASK.INPUT.SELECT.TYPE"
                          defaultMessage="Select Type"
                        />
                      </option>
                      <option value="Mandatory">Mandatory</option>
                      <option value="Optional">Optional</option>
                    </DropDown>
                  ) : (
                    data.type
                  )}
                </td>
                <td className="px-6 py-4">
                  {showEdit === data?.id ? (
                    <div className="flex justify-center w-auto">
                      <Button
                        onClick={handleSubmit}
                        justify="justify-center"
                        text={
                          <FormattedMessage
                            id="RECRUITER.CUSTOMER.CHECKLIST.TASK.SAVE_BUTTON"
                            defaultMessage="Save"
                          />
                        }
                      ></Button>
                      <Button
                        onClick={() => HandleEdit("")}
                        justify="justify-center"
                        text={
                          <FormattedMessage
                            id="RECRUITER.CUSTOMER.CHECKLIST.TASK.CANCEL_BUTTON"
                            defaultMessage="Cancel"
                          />
                        }
                      ></Button>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-4">
                      <div className="border border-neutral-200 rounded-lg inline-flex  divide-x w-auto">
                        <div
                          onClick={() => HandleEdit(data?.id)}
                          className="py-1.5 px-2 group cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-6 mt-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                          <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                            <FormattedMessage
                              id="ECOMMERCE.COMMON.ICON.TOOLTIP.EDIT"
                              defaultMessage="Edit"
                            />
                          </span>
                        </div>
                        <div
                          onClick={() => HandleDeleteTask(data?.id)}
                          className="py-1.5 px-2 group cursor-pointer relative hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 text-black"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                            <FormattedMessage
                              id="ECOMMERCE.COMMON.ICON.TOOLTIP.DELETE"
                              defaultMessage="Delete"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <p className="text-center">You haven't added any tasks</p>
        </>
      )}
    </ContentWithSpinner>
  );
};
