import React, { useEffect, useState } from "react";
import { Button, Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import getStripCustomerId from "sr/helpers/apiFunctions/strip/getStripCustomerId";
import { getCardIcon } from "sr/helpers/functions/getCardIcon";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { fetchBalance } from "sr/helpers/apiFunctions/fetchBalance";
import { toast } from "react-toastify";
import { updateWalletBalance } from "sr/utils/api/wallet/updateWalletBalance";
import { createStripPayment } from "sr/utils/api/strip/createStripPayment";
export const Balance = () => {
  const [userId, setUserId] = useState()
  const [cardDetails, setCardDetails] = useState<any>([])
  const [isProcessing, setIsProcessing] = useState(false);
  const [stripId, setStripId] = useState()
    const [paymentDetails, setPaymentDetails] = useState(null)
  const [paymentId, setPaymentId] = useState()
const [balance, setBalance] = useState(0)
const [loader, setLoader] = useState(true)
const [amount, setAmount] = useState(0)
  useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserId(user_id)
  },[])
  useEffect(()=> {
    if(userId) {
    getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
    }
  },[userId])
  useEffect(()=>{
    
          if(userId){
            fetchBalance({userId:userId, setData:setBalance, setLoader:setLoader})
          }
      },[userId])
      const handlePayment = async ()=>{
            try {
            setLoader(true)
              const payload = {
                customer:paymentDetails?.customer,
                payment_method:paymentDetails?.id,
                currency:'USD',
                amount:Number(amount)*100
              }
              const res = await createStripPayment(payload)
              if(res?.success===true){
                const payloadwallet = {
                  to:userId,
                  tokenId:'1271231',
                  amount:Number(amount),
                  data:'initial top up'
                }
                const walletres = await updateWalletBalance(payloadwallet)
                if(walletres?.success===true){
                  setLoader(false)
                  fetchBalance({userId:userId, setData:setBalance, setLoader:setIsProcessing})
                }
                toast.success('Amount Added Successfully')
              }
          }
          catch(e){
      
          }
        }
        useEffect(()=>{
                setPaymentDetails(cardDetails && cardDetails[0])
            },[cardDetails])
  return (
    <ContentWithSpinner loader={isProcessing} height="h-screen">
  <div className="py-3">
<div className="border border-gray-border rounded-xl flex w-full bg-white text-black pt-10 pb-10">
  <div className="mx-auto">
    <div className="text-2xl font-satoshiBold mb-5"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TITLE" defaultMessage="Available Account Balance" /></div>
    <div className="text-2xl text-center font-satoshiBold">${balance}</div>
    <div className='my-5 justify-center '>
    {cardDetails?.map((item)=> (
      <RadioInput icon={getCardIcon(item?.card?.brand)} name={''} onChange={()=> setPaymentDetails(item)} checked={item?.id===paymentDetails?.id} label={`**** **** **** ${item?.card?.last4}`} value={item?.id}></RadioInput>
           ))
         }
   </div>
   {paymentDetails &&<>
    <Input  InputDivClass="mt-0"
     inputClass="border border-zinc-800 rounded-lg h-11 mb-5" value={amount} onChange={(e)=>setAmount(e.target.value)} type="number"></Input>
   <div onClick={handlePayment} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" >Add Money</div>
   </>}
   </div>
    </div>
   
  
</div>
</ContentWithSpinner>
  );
};
