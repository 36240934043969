/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { App } from 'app/App'
import { useAuth } from 'app/pages/module/auth/core/Auth'
import AuthPage from 'app/pages/module/auth/Login'
import SigninPage from 'app/pages/module/auth/Login1'
import ErrorsPage from 'app/pages/module/errors/ErrorsPage'
import { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import CreateCustomer from 'app/pages/customer/CreateCustomer'
import RegisterCleaner from 'app/pages/cleaner/RegisterCleaner'
import VerifyOTP from 'app/pages/module/auth/VerifyOTP'
import CleanerProfile from 'app/pages/cleaner/CleanerProfile'
import RegisterCompany from 'app/pages/company/RegisterCompany1'
import CompanyProfile from 'app/pages/company/CompanyProfile1'
import JobList from 'app/pages/jobs/JobList'
import JobDetails from 'app/pages/jobs/JobDetails'
import SavedJobs from 'app/pages/jobs/SavedJobs'
import  Companies from 'app/pages/company-display/Companies'
import CompanyDetails from 'app/pages/company-display/CompanyDetails'
import MapDisplay from 'app/pages/map-display/MapDisplay'
import { UserSetting } from 'app/pages/settings/Settings'
import { RegisterAsIndividual } from 'app/pages/individual/account/RegisterAsIndividual'
import { ForgetPassword } from 'app/pages/module/auth/ForgetPassword'
import { ResetPassword } from 'app/pages/module/ResetPassword'
import { IndividualJobs } from 'app/pages/jobs/Individual-jobs/IndividualJobs'
import AppliedJobs from 'app/pages/jobs/AppliedJobs'
import { AppliedIndividualJobs } from 'app/pages/jobs/Individual-jobs/AppliedIndividualJobs'
import IndividualSavedJobs from 'app/pages/jobs/Individual-jobs/IndividualSavedJobs'
import IndividualJobDetail from 'app/pages/jobs/Individual-jobs/IndividualJobDetail'
import { MyJobs } from 'app/pages/jobs/Myjobs/MyJobs'
import { MyJobDetails } from 'app/pages/jobs/Myjobs/MyJobDetails'
import { PendingJobs } from 'app/pages/jobs/Myjobs/PendingJobs'
import { CleanerCompanyProfile } from 'app/pages/company/profile/CleanerCompanyProfile'
import { IndividualCleanerProfile } from 'app/pages/individual/jobs/IndividualCleanerProfile'
import { LayoutSplashScreen } from 'sr/layout/master-layout'
import { Message } from 'app/pages/cleaner/message/Messages'
import { WorkOrderList } from 'app/pages/jobs/workorder/WorkOrderList'
import { WorkOrderDetails } from 'app/pages/jobs/workorder/WorkOrderDetails'
import { RegisterasSupervisor } from 'app/pages/supervisor/RegisterasSupervisor'
import { SearchJob } from 'app/pages/jobs/SearchJob'
import { CreditCardProvider } from 'app/pages/component/CreditCardContext'
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  //const { currentUser } = useAuth()
  const currentUser = false;
  const {isAuthenticate, isAuthReady, logout, userType} = useAuth()
  if (!isAuthReady) {
    return <LayoutSplashScreen />
  }
  return (
    // <CreditCardProvider>
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {/* <Route path='/*' element={<ErrorsPage />} /> */}
          <Route path='error/*' element={<ErrorsPage />} />
          {/* <Route path='logout' eleme--nt={<Logout />} /> */}
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
              <Route index element={<Navigate to='/reports' />} />
            </>
          ) : (
            //   <>
            //     <Route path='*' element={<Navigate to='/homed' />} />
            //     <Route path='auth/*' element={<AuthPage />} />
            //     <Route path='/createcompany' element={<CreateCompany />} />
            //     <Route path='/createcustomer' element={<CreateCustomer />} />
            //     <Route path='/createcleaner' element={<CreateCleaner />} />
            //     <Route path='/createjob' element={<CreateJob />} />
            //     <Route path='/jobs' element={<Jobs />} />
            //     <Route path='homed/*' element={<DummyHome />} />
            //   </>
            // )}
            <>
              <Route path='/create-company' element={<RegisterCompany />} />
              <Route path='/create-customer' element={<CreateCustomer />} />
              <Route path='/create-cleaner' element={<RegisterCleaner />} />
              <Route path='/create-cleaner/:id' element={<RegisterCleaner />} />
              <Route path='create-supervisor/:id' element={<RegisterasSupervisor />} />
              <Route path='/cleaner-profile' element={<CleanerProfile />} />
              <Route path='/cleaner/message' element={<Message />} />
              <Route path='/forget-password' element={<ForgetPassword />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/reset-password/:session' element={<ResetPassword />} />
              <Route path='/company-profile' element={<CompanyProfile />} />
              <Route path='/verify-otp' element={<VerifyOTP />} />
              <Route path='/cleaner-jobs' element={<JobList />} />
              <Route path='/cleaner/work-order' element={<WorkOrderList />} />
              <Route path='/cleaner/work-order-details/:id' element={<WorkOrderDetails />} />
              <Route path='/job-detail/:id' element={<JobDetails />} />
              <Route path='/saved-jobs' element={<SavedJobs />} />
              <Route path='/applied-jobs' element={<AppliedJobs />} />
              <Route path='/individual-jobs' element={<IndividualJobs/>} />
              <Route path='/companies' element={<Companies />} />
              <Route path='/company-details/:id' element={<CompanyDetails />} />
             <Route path='/search/:query' element={<SearchJob />} />
              <Route path='/map' element={<MapDisplay />} />
              <Route path='/user/settings' element={<UserSetting />} />
              <Route path='/registerasindividual' element={<RegisterAsIndividual />} />
              <Route path='/individual/job-detail/:id' element={<IndividualJobDetail />} />
              <Route path='/individual/applied-jobs' element={<AppliedIndividualJobs />} />
              <Route path='/individual/saved-jobs' element={<IndividualSavedJobs />} />
              <Route path='/my-jobs' element={<MyJobs />} />
              <Route path='/my-job-details/:id' element={<MyJobDetails />} />
              <Route path='/pending-jobs' element={<PendingJobs />} />
              <Route path='/company/profile' element={<CleanerCompanyProfile />} />
              <Route path='/' element={<Navigate to='/auth' />} />
            {isAuthenticate &&
              <Route path='/*' element={<PrivateRoutes />} />
            }
            {!isAuthenticate && <>
           <Route path='*' element={<Navigate to='/auth' />} />
              <Route index element={<Navigate to='/reports' />} />
              <Route path='/auth' element={<SigninPage />} />
            </>
          }
        </> 
          )}
        </Route>
       
      </Routes>
    </BrowserRouter>
    // </CreditCardProvider>
  )
}

export { AppRoutes }
