import React, { useEffect, useState } from "react";
import { fetchBalance } from "sr/helpers/apiFunctions/fetchBalance";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";

export const Balance = () => {
  const [balance, setBalance] = useState(0)
  const [userId, setUserId] = useState<string>('')
  const [loader, setLoader] = useState(true)
  useEffect(()=>{
      const {user_id} = getUserDetails()
      setUserId(user_id)
  },[])
  useEffect(()=>{

      if(userId){
        fetchBalance({userId:userId, setData:setBalance, setLoader:setLoader})
      }
  },[userId])
  return (
    <ContentWithSpinner loader={loader}>
    <div className="border border-gray-border rounded-xl flex w-full bg-white text-black pt-10 pb-10">
      
    <div className="mx-auto">
        <div className="text-2xl font-satoshiBold mb-5">Available Balances</div>
        <div className="text-2xl text-center font-satoshiBold">${balance}</div>
    </div>
</div>  
</ContentWithSpinner>
);
};
