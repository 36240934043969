import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { ModelWrapper } from "../../component/ModelWrapper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "sr/helpers";
import { updateJob } from "sr/utils/api/individual/job/updateJob";

export const SelectandContinue = (props:any) => {
  const [startTime, setStartTime] = useState('')
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [hour, setHour] = useState()
  const [minute, setMinute] = useState('00')
  const [from, setFrom] = useState<string>()
  const [to, setTo] = useState<string>()
  const [jobId, setJobId] = useState<string>()
  const [loader, setLoader] = useState()
  let shouldRender = false
const navigate = useNavigate()
  const param = useParams()
  useEffect(()=>{
const {from, to, id} = param
setFrom(from)

setTo(to)
setJobId(id)
  },[param])
  const HandleRender = (e)=> {
        shouldRender = true
  }

  const handleTime = (e, type)=> {
    console.log(e.target.value);
        
      if(type==='hour') {

          setHour(e.target.value)
          setIsFocused(false)
      }
      if(type==='minute') {
        if(!hour) {
          toast.error('First select hour')
          return
        }
        setMinute(e.target.value)
        setIsFocused1(false)
    }
    if(shouldRender) {
      e.target.blur()
    }

  }
  const hour1 = Number(from?.split(':')[0])
  const hour2 = Number(to?.split(':')[0])
  const timenumber = []
  if(hour1<hour2) {
   for(let i = hour1; i<hour2; i++) {
    if(i<=9) {
        const str = `0${i}`
        timenumber?.push(str)
    }
    else {
      timenumber?.push(i)
    }
  } 
}
else {
  for(let i = hour1; i<=23; i++) {
    if(i<=9) {
      const str = `0${i}`
      timenumber?.push(str)
  }
  else {
    timenumber?.push(i)
  }
  }
  for(let i =0; i<hour2; i++) {
    if(i<=9) {
      const str = `0${i}`
      timenumber?.push(str)
  }
  else {
    timenumber?.push(i)
  }
  }
}
  const handleChange = async ()=> {
    if(!hour && !minute){
      toast.error('Please select start time')
      return
    }
    try {
    const payLoad = {
        id:jobId,
        start_time:`${hour}:${minute}`,
        cleaner_id:props?.item?.id,
        work_status:'active'
    }
    const res = await updateJob(payLoad)
    if(res?.success===true) {
        navigate(`/individual/confirm/${jobId}`)
    }
  }
  catch(e){

  }
  }
  useEffect(()=>{
setHour(timenumber[0])
  },[timenumber])
  useEffect(()=>{
console.log(hour);

  },[hour])
  
  return (
        <div className="h-[50%] w-full mx-auto">
          <div>
          <div className="flex items-start justify-between p-5 rounded-t">

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  
                  >
                    <span onClick={()=> props?.setModel(false)} className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
          <h1 className="text-2xl font-bold text-center my-4"><FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.TITLE" defaultMessage="Choose your task date and start time" /></h1>
    <div className="flex space-x-3 items-center px-8">
      <img className="w-10 h-10 rounded-full" src={props?.profileImg ? props?.profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}></img>
      <div className="text-sm font-satoshiBold">{props?.item?.first_name} {props?.item?.last_name} </div>
    </div>
    <div className="mt-10 ">
          <p className="font-satoshiBold text-lg flex justify-center  mb-3">Start Time </p>
          
        <div className="flex justify-center items-center text-lg space-x-1">
          <select onChange={(e)=> {HandleRender(e); handleTime(e, 'hour')}}  size={isFocused ? 10 : null} onFocus={() => setIsFocused(true)} // Trigger when focused
      onBlur={() => setIsFocused(false)} // Reset when focus is lost
      className={`appearance-none ${isFocused ? 'absolute top-[190px]':null}`} // Apply styles dynamically

 >
          {timenumber?.map((item) => (
  <option key={item} value={item}>
    {item}
  </option>
))}

          </select>
          <span>:</span>
          <select value={minute} onChange={(e)=> {HandleRender(e); handleTime(e, 'minute')}} onFocus={() => setIsFocused1(true)}  onBlur={() => setIsFocused1(false)}  size={isFocused1 ? 10 : null} 
          className={`appearance-none ${isFocused1 ? 'absolute top-[190px]':null}`}>
            {Array.from({ length:  60}, (_, i) => i).map((item)=> (
            <option value={item<=9?`0${item}`:item}>
              {item<=9?`0${item}`:item}
            </option>
            )) }
          </select>
        </div>
        </div>
        
        </div>

        <div onClick={handleChange} className="my-10 text-center font-bold bg-[#2557A7] px-5 py-2 flex justify-center w-20 mx-auto  text-white cursor-pointer hover:bg-navy rounded-lg">
          <FormattedMessage id="INDIVIDUAL.CLEANERS.CONTINUE.BUTTON" defaultMessage="Continue" />
        </div>
        </div>
  )
  
  ;};
