import React, { useEffect, useRef, useState } from "react";
import ChecklistForm from "../../forms/ChecklistForm";
import { FormattedMessage } from "react-intl";
import { Button } from "sr/helpers/ui-components/company/Button";
import { Checkbox } from "sr/helpers/ui-components/CheckBox";
import TaskForm from "../../forms/TaskForm";
import { AllChecklist } from "../../../ChecklistTemplate/AllChecklist";
import { Task } from "../../Tables/Task";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { useParams } from "react-router-dom";
import { createTasklists } from "sr/utils/api/company/customer/createTasklists";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { updateChecklist } from "sr/utils/api/company/customer/updateChecklist";

export const AddChecklist = (props) => {
  const [GlobalTemplate, setGlobalTemplate] = useState(false);
  const [copyTasks, setCopyTasks] = useState([]);
  const [addMore, setAddMore] = useState(false);
  const [model, setModel] = useState(false);
  const [checklistId, setchecklistId] = useState(null);
  const [taskadded, setTaskAdded] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  useEffect(() => {
    const { id, checklistId } = params;
    setchecklistId(checklistId);
    setCustomerId(id);
  }, [params]);
  useEffect(() => {
    const userData = getUserDetails();
    setCompanyId(userData?.user_id);
  }, []);

  interface TabRef {
    submitForm: () => void;
  }
  const [modelChecklistAdded, setModelChecklistAdded] = useState(false);
  const childRef = useRef<TabRef>(null);
  const HandleFormSubmit = () => {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm();
    }
  };
  const dataAddedHandle = (data) => {
    if (data === "Checklist") {
      setModelChecklistAdded(true);
    }
    if (data === "Task") {
      setTaskAdded(taskadded + 1);
      setAddMore(false);
    }
  };
  const ChecklistHandle = async (tasks) => {
    setLoader(true);
    let formData = [];
    for (let i = 0; i < tasks?.length; i++) {
      const data = {
        company_id: companyId,
        customer_id: customerId,
        checklist_id: checklistId,
        name: tasks[i]?.name,
        type: tasks[i]?.type,
        description: tasks[i]?.description,
        status: "active",
      };
      formData.push(data);
    }
    setCopyTasks(tasks);
    try {
      const res = await createTasklists(formData);
      if (res.success === true) {
        const cPayload = {
          id:checklistId,
          status:'active'
        }
        const cres = await updateChecklist(cPayload)
        if(cres?.success===true) {
        setTaskAdded(taskadded + 1);
        setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
    }
  };
  const saveTaskHandle = () => {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm();
    }
  };

  return (
    <>
      {!modelChecklistAdded ? (
        <>
          <ChecklistForm ref={childRef} dataAddedHandle={dataAddedHandle} customer_Id={props?.customer_Id}/>
          <Button
            text={
              <FormattedMessage
                id="RECRUITER.CUSTOMER.CHECKLIST.SAVE.BUTTON"
                defaultMessage="Save Checklist"
              />
            }
            onClick={HandleFormSubmit}
          ></Button>
        </>
      ) : (
        <>
          {!props?.isEditCheckList && (
            <>
              {copyTasks.length === 0 && (
                <div className="mt-5">
                  <Checkbox
                    id="Global"
                    label={
                      <FormattedMessage
                        id="RECRUITER.CUSTOMER.CHECKLIST.GLOBAL.TITLE"
                        defaultMessage="Import from global Checklist"
                      />
                    }
                    onChange={() => setGlobalTemplate(!GlobalTemplate)}
                    value={GlobalTemplate}
                  ></Checkbox>
                </div>
              )}
              {GlobalTemplate ? null : (
                <>
                  <TaskForm ref={childRef} dataAddedHandle={dataAddedHandle} />
                  <Button
                    text={
                      <FormattedMessage
                        id="RECRUITER.CUSTOMER.CHECKLIST.SAVE.TASK_BUTTON"
                        defaultMessage="Save Task"
                      />
                    }
                    onClick={HandleFormSubmit}
                  ></Button>
                </>
              )}
            </>
          )}
        </>
      )}
      {!props?.isEditCheckList && GlobalTemplate && copyTasks.length === 0 ? (
        <AllChecklist ChecklistHandle={ChecklistHandle} />
      ) : null}
      {modelChecklistAdded && (
        <ContentWithSpinner loader={loader}>
          <div className="overflow-x-auto mt-10 rounded-[20px] border border-neutral-200">
            <Task onClick={() => setModel(true)} taskadded={taskadded} />
          </div>
        </ContentWithSpinner>
      )}
      {addMore && <TaskForm ref={childRef} dataAddedHandle={dataAddedHandle} />}
      {((modelChecklistAdded && props?.isEditCheckList && addMore) ||
        (copyTasks?.length > 0 && addMore)) && (
        <Button
          onClick={saveTaskHandle}
          text={
            <FormattedMessage
              id="RECRUITER.CUSTOMER.CHECKLIST.SAVE.TASK.BUTTON"
              defaultMessage="Save Task"
            />
          }
        ></Button>
      )}
      {((modelChecklistAdded && props?.isEditCheckList && !addMore) ||
        (copyTasks?.length > 0 && !addMore)) && (
        <Button
          onClick={() => setAddMore(true)}
          text={
            <FormattedMessage
              id="RECRUITER.CUSTOMER.CHECKLIST.TASK.BUTTON"
              defaultMessage="Add More Task"
            />
          }
        ></Button>
      )}
    </>
  );
};
