import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { getTraining } from "sr/utils/api/cleaner/getTraining";

interface ParamsType {
cleanerId?:string
id?:string
setLoader?:(params:any)=> void
setData:(params:any)=> void
}

const fetchTraining = async (param:ParamsType) => {
  try {
    param?.setLoader && param?.setLoader(true)
        const res = await getTraining(param?.cleanerId, param?.id)
      if(res?.success===true) {
        param?.setData(res?.data)
        param?.setLoader && param?.setLoader(false)
      }
      }
  catch(e:any) {
    HandleApiError(e)
    param?.setLoader && param?.setLoader(false)
  }
};
export default fetchTraining;