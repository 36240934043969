import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatBottom } from "app/pages/cleaner/message/ChatBottom";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { ModelWrapperWithButton } from "app/pages/individual/component/ModelWrapperWithButton";
import { ChatImg } from "app/pages/individual/jobs/component/ChatImg";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import { formatISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { Input, toAbsoluteUrl } from "sr/helpers";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import getTimeFromDate from "sr/helpers/functions/getTimeFromDate";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { scrollToBottom } from "sr/helpers/functions/scrollToBottom";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { lastScene } from "sr/utils/api/chat/lastScene";
import { getMessage } from "sr/utils/api/cleaner/getMessage";
import { getIndividual } from "sr/utils/api/individual/getIndividual";
import { getJob } from "sr/utils/api/individual/job/getJob";
import { updateJob } from "sr/utils/api/individual/job/updateJob";
import { uploadFile } from "sr/utils/api/uploadFile";
import { ViewJobDetails } from "./components/ViewJobDetails";
import { CHAT_TYPE } from "sr/i18n/messages/setting";
import { settingMessageDisplay } from "sr/helpers/functions/settingMessageDisplay";
import { useSocket } from "app/pages/component/SocketContext";
import { transferBalance } from "sr/utils/api/wallet/transferBalance";
export const IndividualChat = () => {
    const [userId, setUserId] = useState()
    const [data, setData] = useState(null)
    const [jobId, setJobId] = useState<string>('')
    const messagesEndRef = useRef()
  const [allmessage, setAllMessage] = useState([])
    const [allonlineUser, setAllOnlineUser] = useState(null)
    const [message, setMessage] = useState(null)
      const [imageId, setImageId] = useState('')
      const [imageUrl, setImageUrl] = useState('')
       const [loader, setLoader] = useState('fetch')
       const [showModel, setShowModel] = useState('')
       const [lastOnline, setLastOnline] = useState()
       const [workHours, setWorkHours] = useState(null)
       const [expenses, setExpenses] = useState()
       const [] = useState()
  const socket = useSocket()
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    const param = useParams()
      useEffect(()=> {
    const {jobId} = param
    setJobId(jobId)
      },[param])
      const fetchJob = async ()=> {
        const queryParams = new URLSearchParams()
        queryParams.append('id', jobId)
      try {
      const res = await getJob(queryParams)
      if(res?.success===true) {
        setData(res?.data)
      }
      else {
      
      }
      }
      catch(e:any) {
        HandleApiError(e)
      }
      }
       useEffect(()=> {
      
      if(jobId) {
        fetchJob()
        fetchChat(jobId)
      }
        },[jobId])

     useEffect(() => { 
      if (!userId) return; 
      if(socket){
        
        socket.emit('register', userId);
              socket.emit('getonlineusers', userId);
              socket.off('sendmessage');

      }
              
            socket.on('sendmessage', ({ from, message }) => {
              console.log(message);
              
              if(message?.recipient_id===userId){
                console.log(message);
                
                
              const currentDateTime = formatISO(new Date());
              const newmessage = {createdAt:currentDateTime, ...message}
              setAllMessage((preview)=> [...preview, newmessage])    
              }
            });
      
            socket.on('getonlineusers', ({users }) => {
              console.log(users);    
              setAllOnlineUser(users);

          })                                
          return () => {
            socket.off('sendmessage');
          };
          }, [userId]);
   const sendMessage = () => {
               const payload = {
                   message: message,
                   recipient_id: data?.individual_id?._id,
                   sender_id:userId,
                   job_id:data?.id,
                   type:'chat',
                   file_id:imageId
                   // Ensure this resolves to the correct recipient user ID
               };
               socket.emit('sendmessage', payload); // Match the event name in the backend
               setMessage(''); // Clear the input
               const currentDateTime = formatISO(new Date());
               const newmessage = {createdAt:currentDateTime, ...payload}
               setAllMessage((preview)=> [...preview, newmessage])
               setImageId('')
               setImageUrl('')
   
           }

            const fetchChat = async (job_id)=> {
                   const queryParams = new URLSearchParams()
                   queryParams?.append('job_id', job_id)
               try {
               const res = await getMessage(queryParams)
               if(res?.success===true) {
               setAllMessage(res?.data)
                 setLoader('')
               }
               else {
               setLoader('')
               }
               }
               catch(e) {
               setLoader('')
               }
                  }

    const handleImage = async (e)=> {
      setLoader('apicall')
    const formData = new FormData()
    formData.append('individual_id', userId);
    formData.append('chat_img', e.target.files[0]);
    const result = await uploadFile(formData);
        if(result?.success===true) {
          setImageId(result?.data?.files[0].key)
          setImageUrl(URL.createObjectURL(e.target.files[0]))
          setLoader('')
        }
        else {
          setLoader('')
        }
    
    
    }
    const handleImageClick = ()=> {
      setImageId('')
      setImageUrl('')
    }
    useEffect(()=> {
        scrollToBottom(messagesEndRef)
           },[allmessage])
           const handleAccept = async ()=> {
            setLoader('apicall')

            const payload = {
              id:jobId,
              work_status:'scheduled'
            }
            try {
                const res = await updateJob(payload)
                if(res?.success===true) {
                  const payload = {
                              message: `${data?.cleaner_id?.first_name} ${data?.cleaner_id?.last_name} accepted the job`,
                              recipient_id: data?.individual_id?._id,
                              sender_id:userId,
                              job_id:data?.id,
                              type:'job_accepted'
                          };
                          socket.emit('sendmessage', payload); 
                          const currentDateTime = formatISO(new Date());
                                      const newmessage = {createdAt:currentDateTime, ...payload}
                                      setAllMessage((preview)=> [...preview, newmessage])
                                      setLoader('')
                                      fetchJob()
                          
                }
            }
            catch(e){
              setLoader('')
            }

           }
           
           const handleReject = async  ()=> {
            setLoader('apicall')
            const payload = {
              id:jobId,
              work_status:'rejected'
            }
            try {
                const res = await updateJob(payload)
                if(res?.success===true) {
                  const payload = {
                              message: `${data?.cleaner_id?.first_name} ${data?.cleaner_id?.last_name} rejected the job`,
                              recipient_id: data?.individual_id?._id,
                              sender_id:userId,
                              job_id:data?.id,
                              type:'job_rejected'
                          };
                          socket.emit('sendmessage', payload); 
                          const currentDateTime = formatISO(new Date());
                                      const newmessage = {createdAt:currentDateTime, ...payload}
                                      setAllMessage((preview)=> [...preview, newmessage])
                                      fetchJob()
                                      setLoader('apicall')
                          
                }
            }
            catch(e){
              setLoader('')
            }
           }
    const onCheckModel = ()=> {
      setShowModel('')
    }
    useEffect(()=> {
const fetchLastScene = async ()=> {
  const queryParams = new URLSearchParams()
  queryParams.append('user_id', data?.individual_id?._id)
  queryParams.append('status', 'offline')
try {
const res = await lastScene(queryParams)
  if(res?.success===true){
      console.log(res?.data);
      
  }
}
catch(e){

}
}
if(data?.individual_id?._id){
  fetchLastScene()
}
    },[data?.individual_id?._id])
    const handleCompleteJob = async ()=> {
      setLoader('apicall')
try {
const payload = {
  id:jobId,
  work_completion_time:workHours,
    addition_expenses:expenses,
  work_status:'completed'
}
const res = await updateJob(payload)
if(res?.success===true){
  // const restransfer = await transferBalance({to:userId, from:workorder?.company_id?._id, tokenId:'1271231', amount:workorder?.pay_type_rate, data:''})

  const payload = {
    message: `${data?.cleaner_id?.first_name} ${data?.cleaner_id?.last_name} completed the job`,
    recipient_id: data?.individual_id?._id,
    sender_id:userId,
    job_id:data?.id,
    work_completion_time:workHours,
    addition_expenses:expenses,
    type:'end_job'
};
socket.emit('sendmessage', payload); 
const currentDateTime = formatISO(new Date());
            const newmessage = {createdAt:currentDateTime, ...payload}
            setAllMessage((preview)=> [...preview, newmessage])
            setShowModel('')
            setLoader('')

}

}
catch(e){
  setLoader('')
}
    }
    const handleStart = async ()=>{
      setLoader('apicall')
      try {
      const payload = {
        id:jobId,
        work_status:'in progress'
      }
      const res = await updateJob(payload)
      if(res?.success===true){
        const payload = {
          message: `${data?.cleaner_id?.first_name} ${data?.cleaner_id?.last_name} started the job`,
          recipient_id: data?.individual_id?._id,
          sender_id:userId,
          job_id:data?.id,
          type:'start_job'
      };
      socket.emit('sendmessage', payload); 
      const currentDateTime = formatISO(new Date());
                  const newmessage = {createdAt:currentDateTime, ...payload}
                  setAllMessage((preview)=> [...preview, newmessage])
                  setShowModel('')
                  fetchJob()
                  setLoader('')
      }
    }
    catch(e){

    }
    }
  return (<>
      <AuthNavComponent isLoggedin={true}></AuthNavComponent>
      <ContentWithSpinner height="h-screen" loader={loader==='fetch'}>
      {loader==='apicall' && <LoaderFullScreen />} 
    <main className="pt-3">
    <div className="bg-white max-w-[80%] mx-auto">
       <div className='flex justify-start items-center py-3 px-3 space-x-3'>
       <div className='text-white h-16  w-16 flex items-center justify-center relative'>
         <img className="rounded-full" src={toAbsoluteUrl('/media/img/news-2.jpg')} alt="user_img"></img>
         <span className="bg-[#3fbb46] absolute bottom-1 w-4 h-4 rounded-lg right-2 "></span>
         </div>
           <div>
           <h1 className='text-2xl font-satoshiBold text-[#595959]'>{data?.individual_id?.first_name} {data?.individual_id?.last_name}</h1>
           <p className='font-satoshi'>{allonlineUser && allonlineUser[data && data?.cleaner_id?._id] ? 'Online':'Recently seen'}</p>
           </div>
       </div>
   <hr className="border-2 border-neutral-200" />
   <div className="flex flex-col h-[75vh] justify-between">
     <div className="px-3 py-3">
   <div className="flex justify-between items-center">
     <div>
        <div className="flex justify-between items-center space-x-3">
   <h1 className='text-2xl font-satoshiBold text-[#595959]'>{data?.title}</h1>
   <p onClick={()=> setShowModel('jobDetail')} className="text-sm font-bold font-satoshiBold text-[#275df5] cursor-pointer">View Job Details</p>
   </div>
   <p className='font-satoshi'>{data?.streetAddress} {data?.unitorapt}</p>
   </div>
   <div>
   <div className="flex space-x-3">
     {/* <button className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Change Rate</button> */}
     {/* <button className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Change Date</button> */}
     {data?.work_status==='scheduled'  &&
     <button onClick={handleStart} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Start</button>
     }
     {data?.work_status==='in progress'  &&
     <button onClick={()=> setShowModel('completeJob')} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Complete</button>
     }
     {data?.work_status==='active' &&
<button onClick={handleAccept} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Accept</button>
}
{data?.work_status==='completed' &&
     <button  className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Completed</button>
}
{data?.work_status==='rejected'  ?
     <button className="rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white ">Rejected</button>
:
<button onClick={handleReject} className="rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white ">Reject</button>

    }
    </div>
     </div>
   </div>
     </div>
     <hr className="border-2 border-neutral-200" />
   <div ref={messagesEndRef} className='flex-1 h-[60vh] overflow-auto mb-3'>
   <div className='text-center mb-10 mt-3'>
   {DateFormat(data?.createdAt)}
   </div>
   <div className="flex items-center justify-center mb-4">
     <div className="border-t flex-1 mr-4"></div>
     <span className="text-black font-satoshiBold">Today</span>
     <div className="border-t flex-1 ml-4"></div>
   </div>
   <div>
    {allmessage?.sort((a, b)=> {
     const adate = new Date(a?.createdAt)
     const bdate = new Date(b?.createdAt)
   
     const differenceInMilliseconds = adate.getTime() - bdate.getTime();
   return differenceInMilliseconds
    }).map((item, index)=> (
      <div key={index}>
        
       {item?.sender_id === userId ?(
        item?.type==='chat' ?
         <div className='flex justify-end items-center space-x-2 px-2'>
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
       <div>
       <div className='flex-1 flex justify-start items-center space-x-2'>
       <div className='font-satoshiBold text-base'> You </div>
       <div className='text-sm'>
        {getTimeFromDate(item?.createdAt)}
       </div>
       </div>
       <div className='text-[#595959]'>
     {item?.message}
       </div>
              {item?.file_id &&
                   <ChatImg item={item}></ChatImg>
              }
       </div>
   </div>
   :
   (
    <div className="text-center">
   <div className="rounded-lg inline-block font-bold bg-[#2557A7] px-5 py-0.5  text-white mb-2">{settingMessageDisplay(item?.type)}</div>
   </div>
   )
       )
       :
       item?.type==='chat' ? 
       (
         <div className='flex justify-start items-center space-x-2 px-2 pb-6'>
       <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
       {data?.job_id?.company_id?.company_name?.slice(0,1)?.toUpperCase()} 
       </div>
       <div>
       <div className='flex-1 flex justify-start items-center space-x-2'>
       <div className='text-sm'>
      {getTimeFromDate(item?.createdAt)}
       </div>
       </div>
       <div className='text-[#595959]'>
   {item?.message}
       </div>
        {item?.file_id &&
                   <ChatImg item={item}></ChatImg>
              }
       <div />
       </div>
   </div>
   ):
   ( Object.values(CHAT_TYPE)?.includes(item?.type) &&
    <div className="text-center">
   <div className="rounded-lg inline-block font-bold bg-[#2557A7] px-5 py-0.5  text-white mb-2">{item?.message}</div>
   </div>
   )
   }
   </div>
    ))} 
     
     </div>
   </div>
   <ChatBottom uploadChange={handleImage} imageUrl={imageUrl} handleImageClick={handleImageClick} Send={sendMessage} Clear={()=> setMessage('')} onChange={(e)=> setMessage(e.target.value)} value={message} />
   </div></div>
   </main>
   </ContentWithSpinner>
   {
    showModel && 
    <ModelWrapperWithButton onCheckModel={onCheckModel} showModal={showModel} Save={handleCompleteJob} nofooter = {showModel==='jobDetail'?true:false}>
      {showModel === 'jobDetail' &&
      <ViewJobDetails data={data}></ViewJobDetails>
}
{showModel ==='completeJob' && 
<>
<Input
                  label={
                    <FormattedMessage
                      id="MYJOB.INPUT.WORK.COMPLETION.TIME"
                      defaultMessage="Work Completion Time"
                    />
                  }
                  onChange={(e) => setWorkHours(e.target.value)}
                  type="number"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-5 w-80  pr-2 pb-2 mx-auto"
                  placeholder="Number of hours"
                  value={workHours}
                />
    <div className='relative w-80 mx-auto'>
    <div className='absolute top-[34px] inset-0 z-50 w-6 h-6'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33" />
</svg>


      </div>
                <Input  type={'text'}
                onChange={(e)=> setExpenses(e.target.value)}
                value={expenses}
     inputClass="rounded-lg"
     labelClass="mt-2 text-black"
     InputDivClass="mt-5 w-80  pr-2 pb-2 mx-auto"
       min={1}
       label={<FormattedMessage id="CLEANER.INDIVIDUAL.CHAT.COMPLETE.INPUT.ADDITIONAL"></FormattedMessage>} ></Input>
</div>
                </>}
    </ModelWrapperWithButton>
   }
   </>
    );};
