import { Link } from 'react-router-dom'
import { ActionBtn } from './ActionBtn'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { WorkOrderStatus } from 'sr/i18n/messages/setting'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { useAuth } from 'app/pages/module/auth'
import { useEffect, useState } from 'react'
export const TableRow = ({item, setEditId, setDeleteId, setShowDelete, isCompletionDate=false, type, editPermission, rejectPermission, active}) => {
const {userType} = useAuth()

  return (
    <>
        <tr className="bg-white border-b  hover:bg-gray-50">
          
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-base">
            <Link to={`${userType==='company' ? item?.workorder_status==='scheduled' ? `/work-order-details/${item?.id}`:`/work-order-progress/${item?.id}` : `/supervisor/manage-work-order/${item?.id}` }`}>
            {item?.title}
           </Link>
            </th>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {item?.customer_id?.name}
            </td>
            <td className="px-6 py-4">
               {item?.customer_location_id?.address?.address_line_1} {item?.customer_location_id?.address?.address_line_2} {item?.customer_location_id?.address?.city}
            </td>
          {type === 'one time'  && <>

            <td className="px-6 py-4">
          {DateFormat(item?.one_time_date)}
            </td>
            {isCompletionDate &&
            <td className="px-6 py-4">
          {DateFormat(item?.completion_date)}
            </td>
}
            </>}
            <td className="px-6 py-4">
  ${item?.pay_type_rate}
  </td>
            <td className="px-6 py-4">
           { getLabelByValue(item?.workorder_status, WorkOrderStatus)}
            
            </td>
            {((type ==='recurring') || (type==='one time' && (active==='scheduled'|| active==='in progress'))) &&
            <td className="px-6 py-4">
         
            <ActionBtn rejectPermission={rejectPermission} editPermission={editPermission}  editonClick={()=> setEditId(item?.id)} rejectonClick={()=> {setShowDelete(true); setDeleteId(item?.id)}}/>
           
            </td>
}
        </tr>
  </>)
}
