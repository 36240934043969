import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { Jobs } from 'app/pages/individual/jobs/jobs'
import { useAuth } from 'app/pages/module/auth'
import CleanerProfileNew from 'app/pages/cleaner/CleanerProfileNew'
import { AppliedWorkOrder } from 'app/pages/jobs/Myjobs/AppliedWorkOrder'
import { IndividualJobPost } from 'app/pages/individual/jobs/job-post'
import { LocationManage } from 'app/pages/individual/basicdetails/LocationManage'
import { ChecklistManage } from 'app/pages/individual/basicdetails/ChecklistManage'
import CleanerProfile from 'app/pages/cleaner/CleanerProfile'
import { JobPostNew } from 'app/pages/individual/jobs/JobPostNew'
import { AccountSetting } from 'app/pages/individual/AccountSetting/AccountSetting'
import { JobPostDetails } from 'app/pages/individual/jobs/JobPostDetails'
import { CleanerProfiles } from 'app/pages/individual/jobs/CleanerProfiles'
import { Confirm } from 'app/pages/individual/jobs/Confirm'
import { ConfirmAndBook } from 'app/pages/individual/jobs/ConfirmAndBook/ConfirmAndBook'
import { SavedWorkOrder } from 'app/pages/jobs/workorder/SavedWorkOrder'
import { CompanyRoutes } from './component/CompanyRoutes'
import { SupervisorRoutes } from './component/SupervisorRoutes'
import { ReviewWorkOrder } from 'app/pages/jobs/Myjobs/ReviewWorkOrder'
import { IndividualCleanerProfile } from 'app/pages/individual/jobs/IndividualCleanerProfile'
import { Calendar } from 'app/pages/jobs/Myjobs/calendar/Calendar'
import { ConfirmAndChat } from 'app/pages/individual/jobs/ConfirmAndBook/ConfirmAndChat'
import { IndividualChat } from 'app/pages/jobs/Individual-jobs/IndividualChat'
import { CleanerWallet } from 'app/pages/cleaner/CleanerWallet'
import { CreditCardProvider, useCreditCard } from 'app/pages/component/CreditCardContext'
import { Settings } from 'app/pages/company/settings/Settings'
import { LayoutSplashScreen } from 'sr/layout/master-layout'
type WithChildren = {
  children: any
}

const PrivateRoutes = () => {
  // const UploadModule = lazy(() => import('../modules/profile/ProfilePage'))
  const {isAuthenticate, isAuthReady, logout, userType} = useAuth()
//   const {isCardAdded, isLoading} = useCreditCard()
  
//  if(isLoading){
//   return <LayoutSplashScreen />
//  }
  return (
  
    <Routes>
      <Route>
    
        {userType === 'company' && <>
          {/* <Route path='/company/settings:tab' element={<Settings />} /> */}
          {/* {isCardAdded ? <> */}
{CompanyRoutes?.map((data, index) => (
                <Route key={index} path={data.path} element={<data.element />} />
            ))
          }
            <Route path='/*' element={<Navigate to='/jobs-listing' />} />
{/* </>
:
<Route path='/*' element={<Navigate to='/company/settings/payment' />} />
} */}
          
              </>
}
              
         {  userType === 'cleaner' && <>

         
              <Route path='/cleaner-profile-page' element={<CleanerProfileNew />} />
              <Route path='/cleaner/applied-work-order' element={<AppliedWorkOrder />} />
              <Route path='/saved-work-order' element={<SavedWorkOrder />} />
              <Route path='/review-work-order/:id' element={<ReviewWorkOrder />} />
              <Route path='/*' element={<Navigate to='/cleaner-jobs' />} />
              <Route path='/cleaner/work-order/calendar' element={<Calendar />} />
              <Route path='/cleaner/individual/chat/:jobId' element={<IndividualChat />} />
              <Route path='/cleaner/wallet' element={<CleanerWallet />}></Route>
              </>
}
{userType=== 'supervisor' && 
<Route path='/*' element={<SupervisorRoutes />} />}

{
  userType==='individual' && <>
  {/* <Route path='/individual/Account/:tab' element={<AccountSetting />} /> */}

  {/* {isCardAdded ?  */}
  <>
             <Route path='/individual/jobs' element={<Jobs />} />
              <Route path='/individual/job-post' element={<IndividualJobPost />} />
              <Route path='/individual/location' element={<LocationManage />} />
              <Route path='/individual/tasklist' element={<ChecklistManage />} />
              <Route path='/individual/tasklist/:id' element={<ChecklistManage />} />
              <Route path='/individual/cleaner-profile/:from/:to' element={<CleanerProfile />} />
              <Route path='/individual/job-post-new' element={<JobPostNew />} />
              <Route path='/individual/job-post-new' element={<JobPostNew />} />
              <Route path='/individual/job-post-details' element={<JobPostDetails />} />
              <Route path='/individual/job-post-details/:title' element={<JobPostDetails />} />
              <Route path='/individual/Account' element={<AccountSetting />} />
              <Route path='/individual/cleaner-profiles/:from/:to/:id' element={<CleanerProfiles />} />
              <Route path='/individual/confirm/:id' element={<Confirm />} />
              <Route path='/individual/cleaner-profile-details/:id/:jobid/:from/:to' element={<IndividualCleanerProfile />} />
              <Route path='/individual/confirm-and-book/:id' element={<ConfirmAndBook />} />
              <Route path='/individual/confirm-and-chat/:id' element={<ConfirmAndChat />} />
              <Route path='/individual/Account' element={<AccountSetting />} />

              <Route path='/*' element={<Navigate to='/individual/jobs' />} />
  </>
  {/* :
  <Route path='/*' element={<Navigate to='/individual/Account/payment' />} />

} */}
</>
}
      </Route>
      
    </Routes>
   
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  TopBarProgress.config({
    barColors: {
      '0': '#000',
    },
    barThickness: 4,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
