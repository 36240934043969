import React from "react";
import { Link } from "react-router-dom";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { WorkOrderStatus } from "sr/i18n/messages/setting";

export const TableRow = ({data}) => {
  return (
    <>
    
    <tr className="bg-white border-b  hover:bg-gray-50">
      
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
       <Link to={`/my-job-details/${data?.id}`}>
            <div className='text-base cursor-pointer'>{data?.title}</div>
            </Link>
        </th>
        <td className="px-6 py-4">
<div className="text-base cursor-pointer">
    {data?.customer_id?.name}
</div>
        </td>
        <td className="px-6 py-4">
            {data?.customer_location_id?.address?.address_line_1} {data?.customer_location_id?.address?.address_line_2} {data?.customer_location_id?.address?.city} {data?.customer_location_id?.address?.state}
        </td>
      

        <td className="px-6 py-4">
    {DateFormat(data?.one_time_date)}
        </td>


        <td className="px-6 py-4">
        {DateFormat(data?.completion_date)}
        </td>
        <td className="px-6 py-4">
  ${data?.pay_type_rate}
  </td>
        <td className="px-6 py-4">
            {getLabelByValue(data?.workorder_status, WorkOrderStatus)}
        </td>
    </tr>
</>
     );
};
