import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapper } from '../../../component/ModelWrapper'
import { ChatBottom } from 'app/pages/company/component/Messages/ChatBottom'
import { FormattedMessage } from 'react-intl'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import { createMessage } from 'sr/utils/api/cleaner/createMessage'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { TimeFormat } from 'sr/helpers/functions/TimeFormat'
import { io } from 'socket.io-client'
import { formatISO } from 'date-fns'
import { scrollToBottom } from 'sr/helpers/functions/scrollToBottom'
import { useSocket } from 'app/pages/component/SocketContext'
import getTimeFromDate from 'sr/helpers/functions/getTimeFromDate'
export const ChatPopup = ({candidate}) => {
    const [modelShow, setModelshow] = useState(false)
    const [chatboxShow, setChatboxShow] = useState(false)
    const [message, setMessage] = useState('')
    const [companyId, setCompanyId] = useState()
    const [allmessage, setAllMessage] = useState(null)
    const [serverMessage, setServerMessage] = useState('');
    const ref = useRef(null)
    useEffect(()=> {
      const userData =  getUserDetails()
      setCompanyId(userData?.user_id)
    }, [])
    const socket = useSocket()
    const HandleModelByProps = (data) => {
        setModelshow(data)
    }
    useEffect(() => {
      if (!companyId) return; 
if(socket){  
  socket.emit('register', companyId);
  socket.off('sendmessage');

}
socket.on('sendmessage', ({ from, message }) => {
  console.log(message);
  
  if(message?.recipient_id===companyId && message?.sender_id===candidate?.cleaner_id?._id && message?.job_id===candidate?.job_id?._id){
    console.log(message);
    
    const currentDateTime = formatISO(new Date());
    const newmessage = {createdAt:currentDateTime, ...message}
    setAllMessage((preview)=> [...preview, newmessage])    
    }
});
return () => {
  socket.off('sendmessage');
};


    }, [companyId, candidate]);
  
    const sendMessage = () => {
      const payload = {
        message:message,
        recipient_id:candidate?.cleaner_id?.id,
        sender_id:companyId,
        job_id:candidate?.job_id?._id,
      }
      console.log(payload);
      
      socket.emit('sendmessage', payload); // Send a message to the server
      setMessage('');
      const currentDateTime = formatISO(new Date());
      const newmessage = {createdAt:currentDateTime, ...payload}
      setAllMessage((preview)=> [...preview, newmessage])
    };

    useEffect(()=> {
    },[companyId, candidate])
   const handleCleaner = ()=> {
    console.log();
    
    setMessage('')
   }
   const fetchChat = async (job_id, sender_id, recipient_id)=> {
   const filter =  {
      "$and":[{"$or":[{"$and":[{"recipient_id":recipient_id}, {"sender_id":sender_id}]},  {"$and":[{"recipient_id":sender_id}, {"sender_id":recipient_id}]}]}]
    }
    const queryParams = new URLSearchParams()
    queryParams?.append('job_id', job_id)
    queryParams.append('filter', JSON.stringify(filter))
try {
const res = await getMessage(queryParams)
if(res?.success===true) {
setAllMessage(res?.data)
}
else {

}
}
catch(e) {

}
   }
   useEffect(()=> {
    console.log(candidate?.job_id?._id);
    
if(candidate?.job_id?._id) {
  fetchChat(candidate?.job_id?._id, companyId, candidate?.cleaner_id?._id)
}
   },[candidate])
   useEffect(()=> {
    scrollToBottom(ref)
       },[allmessage])

  return (
    <div className='fixed right-5 bottom-0'>
        <div className={`w-[20rem]	 ${chatboxShow ? 'h-[28rem]':'h-14'} shadow-boxshadowpage rounded-t-[18px] `}>
    <div className='flex bg-[#2D2D2D] justify-between items-center px-3 text-white py-4 rounded-t-[18px] space-x-6'>
        <div onClick={()=> setChatboxShow(!chatboxShow)} className='cursor-pointer flex items-center space-x-3 mr-16 '>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
  <path fillRule="evenodd" d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
</svg>
<span className='font-satoshiBold'> {candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.last_name}</span>
        </div>
        <div className='flex items-center text-white'>
    <div onClick={()=> setModelshow(!modelShow)} className='p-2 hover:bg-[#595959] rounded-lg cursor-pointer relative'>
              
               </div></div>
    </div>
    <div className='bg-white'>
    <div className="flex flex-col  justify-between h-96">
<div ref={ref} className='flex-1 h-96  overflow-auto mb-3'>
    <div className='py-8 text-center'>
    {DateFormat(candidate?.createdAt)}
    </div>
    <div className="flex items-center justify-center pb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black font-satoshiBold">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
 {
  allmessage?.sort((a, b) => {
      const dateA = new Date(a?.createdAt);
      const dateB = new Date(b?.createdAt);
      return dateA.getTime() - dateB.getTime();
        })?.map((data, index)=> (<>
  {data?.sender_id === companyId ?(
<div className='flex justify-end space-x-2 px-2 pb-6'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-8 h-8 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> You </div>
    <div className='text-sm'>
    {getTimeFromDate(data?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
{data?.message}
    </div>
    </div></div>
  ) : (
<div className='flex justify-start space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
        {candidate?.cleaner_id?.first_name?.slice(0, 1)?.toUpperCase()}
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> {candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.first_name}</div>
    <div className='text-sm'>
    {getTimeFromDate(data?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
   {data?.message}
    </div></div>
</div>
  )}</> 
    ))}
</div>
</div>
<ChatBottom value={message} onChange = {(e)=> setMessage(e.target.value)} Send={sendMessage} Clear={handleCleaner}/>
</div>
</div>
    </div></div>
  )
}
