import React, { ForwardRefRenderFunction, Ref, forwardRef, useEffect, useState } from 'react'
import { GeneralForm } from '../forms/GeneralForm'
import { useFormik } from 'formik'
import CustomerSchema from 'sr/utils/schemas/company/customer/CustomerSchema';
import { createCustomer } from 'sr/utils/api/company/customer/createCustomer';
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCustomer } from 'sr/utils/api/company/customer/getCustomer';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { updateCustomer } from 'sr/utils/api/company/customer/updateCustomer';
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen';
interface TabRef {
  submitForm: () => void;
}
export interface GeneralTabType {
  dataAddedHandle?: (arg: any) => void | null;
}
const GeneralTab: ForwardRefRenderFunction<TabRef, GeneralTabType> = ({dataAddedHandle=null}, ref) => {
  const [data, setdata] = useState<any>({})
  const [predata, setpredata] = useState<any>({})
  const [companyId, setCompanyId] = useState()
  const [customerId, setCustomerId] = useState(null)
  const [loader, setLoader] = useState<string>('')
  const navigate = useNavigate()
  const getData = (data)=> {
      setdata(data)
  }
  const params = useParams()
  const location = useLocation()
useEffect(()=> {
const {id} = params
setCustomerId(id)
},[params])
  useEffect(()=> {
    const userData = getUserDetails()
    setCompanyId(userData?.user_id)
  },[])

  useEffect(()=> {
    const fetchCustomerData = async () => {
      try {
      setLoader('fetchData')
    const queryParams = new URLSearchParams()
    if (customerId) queryParams.append('id', customerId)
      const result = await getCustomer(queryParams)
    if(result?.success===true) {
      setpredata({
    customerName:result?.data?.name,
    phoneNo:result?.data.mobile_number,
    email:result?.data?.email, 
    type:result?.data?.type,
    remarks:result?.data?.remarks
   })
    setLoader('')
    }
    else {
      setLoader('')
    }
    }
    catch(e){
     setLoader('')
      
    }
  }
  if(customerId) {
    fetchCustomerData()
  }
  }, [customerId])
  const initialValues = {
    company_id:companyId,
    name:data?.customerName,
    mobile_number:data?.phoneNo,
    email:data?.email, 
    type:data?.type,
    remarks:data?.remark,
    status:'draft'
}
const initialValuesPut = {
  id:customerId,
    name:data?.customerName,
    mobile_number:data?.phoneNo,
    email:data?.email, 
    type:data?.type,
    remarks:data?.remark,
    status:'draft'
}
const {submitForm, errors} = useFormik({
        initialValues:customerId ? initialValuesPut:initialValues,
        enableReinitialize:true,
        validationSchema:CustomerSchema,
        onSubmit:async (values)=> {
          setLoader('submit')
          try {
let result;
if(customerId) {
  result = await updateCustomer(values)
}
else {
result = await createCustomer(values)
}
if(result.success===true) {
  dataAddedHandle(true)
  setLoader('')
  if(!customerId) {
  navigate(`${location.pathname}/${result?.data?.id}`)
  }
}
          }
          catch(e) {

          }
        }
})
React.useImperativeHandle(ref, () => ({
  submitForm,
}));
  return (
    <div className='py-4'>
      {loader==='submit' && <LoaderFullScreen />}
      <ContentWithSpinner loader={loader==='fetchData'} height='h-60'>
        <GeneralForm errors={errors} getData={getData} allData={predata}/>
        </ContentWithSpinner>
        </div>
  )
}
export default forwardRef(GeneralTab);