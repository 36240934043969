import './styles.scss'
import ListItem from '@tiptap/extension-list-item'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, {useState, useEffect} from 'react'

const MenuBar = ({ editor, children }) => {
  if (!editor) {
    return null
  }

  return (
    <>
   <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
     <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
            <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
                <div className="flex items-center space-x-1 sm:pr-4"></div>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={` ${editor.isActive('bold') ? 'is-active' : ''} p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5 text-black"><path fillRule="evenodd" d="M8 8h6.5a1.5 1.5 0 010 3H8V8zm-2 3V6h8.5a3.5 3.5 0 012.45 6 3.5 3.5 0 01-2.45 6H6v-7zm2 2v3h6.5a1.5 1.5 0 000-3H8z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Bold</span>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={`${editor.isActive('italic') ? 'is-active' : ''} p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5 text-black"><path fillRule="evenodd" d="M10 6.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2.858l-3.232 8h2.09a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2.752l3.233-8H10.5a.5.5 0 01-.5-.5v-1z" clipRule="evenodd"></path></svg>
              <span className="sr-only">Italic</span>
      </button>
  
    
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${editor.isActive('bulletList') ? 'is-active' : ''} p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}
      >
 <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5 text-black"><path fillRule="evenodd" d="M4 7a1 1 0 100-2 1 1 0 000 2zm4.5-2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-12zM8 11.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-1zM4 13a1 1 0 100-2 1 1 0 000 2zm4 4.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-1zM4 19a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Bullet List</span>
      </button>
      </div>
      </div>
      <div>{ children}</div>
      </div>
    </>
  )
}

const TextEditor = ({setdescription, description}) => {
  const editor = useEditor({
    extensions: [

      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: 'list-disc' //Solution: https://github.com/ueberdosis/tiptap/issues/731
          },
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: description || '',
    onUpdate:({editor})=> {
      const html = editor.getHTML()
      setdescription(html)
    }
  })

  return (
    <div className="py-2 bg-white rounded-b-lg dark:bg-gray-800">
          <label htmlFor="editor" className="sr-only">Publish post</label>
      <MenuBar editor={editor}>
      <EditorContent className='w-full px-2 text-sm text-gray-800 bg-white border-0 outline-none' editor={editor} /> 
      </MenuBar>
      
    </div>
  )
}

export default TextEditor;