import React, {useEffect, useState} from "react";
import { MainWrapper } from "app/pages/company/component/MainWrapper";
import { TopBar } from "app/pages/company/component/TopBar";
import { FormattedMessage } from "react-intl";
import { ModelWrapper } from "app/pages/company/component/ModelWrapper";
import { Checkbox } from "sr/helpers/ui-components/CheckBox";
import { ChatBox } from "./ChatBox";
import { Noselected } from "./Noselected";
import Footer from "app/pages/module/Footer";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getMessage } from "sr/utils/api/cleaner/getMessage";
import { getApplication } from "sr/utils/api/cleaner/getApplication";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import Application from "app/pages/component/types/cleaner/application";

export const Message = () => {
  const [modelShow, setmodelShow] = useState(false)
  const [cleanerId, setCleanerId] = useState<string>()
  const [loader, setLoader] = useState(true)
  const [applications, setApplications] = useState<Application[]>([])
  const [currentJobId, setCurrentId] = useState<string>()
  const [currentData, setCurrentData] = useState<Application>()
  useEffect(()=> {
      const {user_id}  = getUserDetails()
      setCleanerId(user_id)
  },[])
  const HandleModelByProps = (data)=> {
    setmodelShow(data)
  }


   const fetchAppliedJobs = async ()=> {
    setLoader(true)
    try {
        const queryParams = new URLSearchParams();  
       queryParams.append('cleaner_id', cleanerId.toString());
        const result = await getApplication(queryParams)
        if(result.success===true) {
            setApplications(result?.data)
            setLoader(false)
            }
            else {
            setLoader(false)
            }
    }
    catch(e) {
setLoader(false)
    }
}
useEffect(()=> {
 
    if(cleanerId) {
        fetchAppliedJobs()
    }
},[cleanerId])
useEffect(()=> {
setCurrentId(applications?.length && applications[0]?.job_id?._id)
setCurrentData(applications?.length && applications[0])
},[applications?.length])
  return (<>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <ContentWithSpinner loader={loader} height="h-screen">
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
    <div className={`flex border-y h-full card-1 bg-white border border-gray-border rounded-xl`}>
        <div className='py-3 w-[33%] border-r-2 border-neutral-300 h-[60vh] overflow-x-auto'>
        <div className='flex items-center mb-3 px-4'>
<div className='rounded-l-lg font-bold bg-[#2557A7] px-3 py-1  text-white cursor-pointer'>
<FormattedMessage id="RECRUITER.MESSAGES.TOGGLE.BUTTON.INBOX" defaultMessage="Inbox" />
</div>
<div className='rounded-r-lg font-satoshiBold px-24 py-1  text-[#2557A7] cursor-pointer bg-white border border-neutral-200'>
<FormattedMessage id="RECRUITER.MESSAGES.TOGGLE.BUTTON.UNREAD" defaultMessage="Unread" />(15)
</div>
    </div>
    <div className='flex items-center space-x-3 relative px-4'>
        <div onClick={()=> setmodelShow(!modelShow)} className='flex-1 flex items-center py-2 px-2 justify-between border rounded-lg border-black hover:shadow-inputshadow hover:border-[#2557A7]'>
        <span><b><FormattedMessage id="RECRUITER.MESSAGES.FILTER.TITLE" defaultMessage="Filter by job" />:</b> <FormattedMessage id="RECRUITER.MESSAGES.FILTER.COMMON.TYPE" defaultMessage="All Jobs" /></span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></div>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

    
    {modelShow && 
    <ModelWrapper HandleModelByProps={HandleModelByProps} topPosition='top-5' border='border border-black'>
    <div className=' w-full px-4 py-4'>
<Checkbox id='Web' label='Web Developer - Lucknow, Uttar Pradesh' name='Web' value='Web Developer - Lucknow, Uttar Pradesh'/>
    </div>
    </ModelWrapper>
}
        </div>
        <hr className='border-2 border-neutral-200 mt-4'></hr>
        {applications?.map((data)=> (
        <div onClick={()=> {setCurrentId(data?.job_id?._id); setCurrentData(data)}} className={`${currentJobId === data?.job_id?._id ? 'bg-[#ecf5ff]':'bg-white hover:bg-[#faf9f8]'}  p-4 border-b border-neutral-200  cursor-pointer`}>
<div className='flex items-center justify-between space-x-2'>
<span className='text-gray-600 font-sm'>
Applied to {data?.job_id?.job_title}
</span>
<span className='text-gray-600 font-sm'>
{DateFormat(data?.createdAt)}
</span>
</div>
<p className='font-satoshiBold'>{data?.job_id?.company_id?.company_name}</p>
<span className='text-gray-600 font-sm'>
Hello, I feel like I'd be a great fit for... 
</span>
        </div> ))}
        </div>
<div className='flex-1 w-[65%] h-[80vh] '>
    {currentJobId ? <ChatBox data={currentData}/>:
    (
        (<Noselected />)
    )
    }
</div>
    </div></div>
    </ContentWithSpinner>
    <Footer></Footer>
    </> );
};
