import React, { useEffect, useRef, useState } from "react";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { MainWrapper } from "../../component/MainWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { CandidateStatus, usaCities } from "sr/i18n/messages/setting";
import { Link } from "react-router-dom";
import { WhiteBtn } from "../component/WhiteBtn";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { useGet } from "../../Hooks/Api/useGet";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";
import { updateSupervisor } from "sr/utils/api/company/workorder/updateSupervisor";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import { Pagination } from "app/pages/component/Pagination";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import Form from "./components/Form";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { useAuth } from "app/pages/module/auth";
import { Spinner } from "sr/helpers";
import { resendSupervisor } from "sr/utils/api/company/workorder/resendSupervisor";
import { AddressDisplay } from "sr/helpers/functions/AddressDisplay";
export const Supervisor = () => {
    const [location, setLocation] = useState()
    const [page, setPage] = useState<number>()
    const [companyId, setCompanyId] = useState<string>()
    const intl = useIntl()
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(0)
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState<any>()
    const [id, setId] = useState()
    const [modalShow, setShowModal] = useState(false)
    const [customerLocations, setCustomerLocations] = useState([])
    const [resendLoader, setResendLoader] = useState(false)
    const {userType} = useAuth()
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setCompanyId(user_id)
},[]) 
const childRef:any = useRef()

const fetchSupervisor = async (companyId)=>{
  try {
  setLoader(true)
const queryParams = new URLSearchParams()
if (companyId) queryParams.append('company_id', companyId)
  queryParams.append('status!', 'deleted')
 const res = await getSupervisor(queryParams)
 if(res?.success===true) {
  setData(res?.data)
  setLoader(false)
 }
}catch(e) {
  setLoader(false)
}

}
useEffect(()=>{
if(companyId) {
    fetchSupervisor(companyId)
}
}, [companyId])
const DeleteSupervisor = async (id)=> {
const payload = {
  id:id,
  status:'deleted'
}
const res = await updateSupervisor(payload)
if(res?.success===true) {
SuccessMessage('API.SUCCESS.SUPERVISOR.DELETE')
    setData(data?.filter((item)=> item?.id !== id ))    
}
}
const HandleEdit = (id)=> {
  setShowModal(true)
  setId(id)
}
const dataAddedHandle = (data)=> {
  if(data === true) {
    setShowModal(false)
    fetchSupervisor(companyId)
     }
    }
     const onCheckModel= ()=> {
      setShowModal(false)
    }
    const HandleEditSave = ()=> {
          if (childRef.current && childRef.current.submitForm) {
            childRef.current.submitForm()
          }
        }
        useEffect(()=> {
          const loadCustomerLocation = async ()=> {
          await fetchCustomerLocations({companyId:companyId,  setCustomerLocations:setCustomerLocations})
          }
          if(companyId && userType==='company'){
            loadCustomerLocation()
          }
          },[companyId])
          const HandleResend = async (id)=> {
            setResendLoader(true)
            try {
                const res = await resendSupervisor({id:id})
                if(res?.success) {
                  setResendLoader(false)
                }
                else {
                  setResendLoader(false)
                }

            }
            catch(e) {
setResendLoader(false)            }
          }
  return (
  <div>
  <MainWrapper>
    <div className="py-3">
      <ContentWithSpinner  loader={loader} height="h-screen">
      <div className='mb-2'>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.INPUT.SUPERVISOR.TITLE" defaultMessage="Supervisor" /></h1>
    <p><FormattedMessage id="RECRUITER.CONTRACTOR.MANAGE.SUPERVISOR.TEXT" defaultMessage="Use this page to manage your supervisor" /></p>
   </div>
   <div className='flex justify-between'>
        <div className='flex w-1/6 space-x-4'>
        <DropDown labelClass='text-base' value={location} label={intl.formatMessage({
    id: 'RECRUITER.WORKORDER.FILTER.LOCATION',
    defaultMessage: 'Location',
  })}
 dropdowndiv='mt-1' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION" defaultMessage="Select Location" /></option>
    {customerLocations?.map((item, index)=> (
    <option key={index} value={item?.value}>{AddressDisplay(item?.address)}</option>
  ))
}
  </DropDown>
</div>
  <div className='flex'>
    <Link to="/add-supervisor">
  <WhiteBtn mainClass="mt-5"  title={<FormattedMessage id="RECRUITER.WORK.ORDER.SUPERVISOR.ADD.BTN" defaultMessage="Add Supervisor" />}></WhiteBtn>
  </Link>

  </div>
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.NAME" defaultMessage="Name" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.MOBILENO" defaultMessage="Mobile No" />
                </th>
                {/* <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.ADDRESS" defaultMessage="Address" />
                </th> */}

                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.STATUS" defaultMessage="Status" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.CONTRACTOR.TABLE.COL.ACTION" defaultMessage="Action" />
                </th>
            </tr>
        </thead>
        <tbody>
          {data?.map((item, index)=> (
         <tr className="bg-white border-b  hover:bg-gray-50">
         <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
       <Link to={`/supervisor-details/${item?.id}`}>
             <div className='text-base cursor-pointer'>{item?.first_name} {item?.last_name}</div> 
             </Link>
         </th>
         <td className="px-6 py-4">
           {item?.mobile_number}
         </td>
         {/* <td className="px-6 py-4">
             {item?.address?.address_line_1} {item?.address?.address_line_2} {item?.address?.city}
         </td> */}
         <td className="px-6 py-4">
        {getLabelByValue(item?.status, CandidateStatus)}
         </td>
        
         <td className="px-6 py-4">
        
         <div className='flex items-center space-x-4 cursor-pointer'>
      {item?.status==='pending' &&
         <button onClick={()=> HandleResend(item?.id)} className='rounded-lg font-bold bg-[#2557A7] px-5 py-3  text-white cursor-pointer hover:bg-navy'>
{resendLoader  ? <Spinner className='flex w-full justify-center'/> :  <FormattedMessage id='RECRUITER.SUPERVISOR.RESEND.EMAIL'></FormattedMessage>  }
</button>
}
         <div onClick={()=> HandleEdit(item?.id)} className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
</svg>

</div>
            <div onClick={()=> DeleteSupervisor(item?.id)} className='p-2 group cursor-pointer relative rounded-lg inline-flex divide-x w-auto border border-neutral-200'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
              </svg>
              <span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
                Delete Supervisor
              </span>
            </div>
          </div>
        </td>

     </tr>
))}
          
        </tbody>
    </table>
    <Pagination page={page} totalPage={totalPage} setPage={setPage} />
</div>
        </ContentWithSpinner></div>
        <ModelWrapperWithButton showModal={modalShow} onCheckModel={onCheckModel} Save={HandleEditSave}>
   <Form type='popup' id={id} ref={childRef} dataAddedHandle={dataAddedHandle}/>
   </ModelWrapperWithButton>
        </MainWrapper>

  </div>);
};
