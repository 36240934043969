import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const updateWalletBalance = async (putData) => {
    try {
  return await post<ApiResponse<[]>>('/wallet/fabric/mint', putData).then((res)=>{
        if(res?.success===true) {
                    return res
        }
        else {
                return null
        }
  })
}
catch(e){

}
};
