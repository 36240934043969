import { useGet } from "app/pages/company/Hooks/Api/useGet";
import React, { ReactNode, useEffect, useState } from "react";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { getIndividual } from "sr/utils/api/individual/getIndividual";
import { createStripCustomer } from "sr/utils/api/strip/createStripCustomer";
import {
    useStripe,
    useElements,
    CardElement,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
  } from '@stripe/react-stripe-js';
import { createStripIntent } from "sr/utils/api/strip/createStripIntent";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { toast } from "react-toastify";
import { getCardIcon } from "sr/helpers/functions/getCardIcon";
import getStripCustomerId from "sr/helpers/apiFunctions/strip/getStripCustomerId";
import CreateIntent from "sr/helpers/apiFunctions/strip/createIntent";
import { getCompany } from "sr/utils/api/company/getCompany";
import { Input } from "sr/helpers";
  
export const PaymentForm = () => {
    const [userId, setUserid] = useState()
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const cardElement = elements?.getElement(CardElement);
    const cardnumberElement = elements?.getElement(CardNumberElement)
    const cardcvv = elements?.getElement(CardCvcElement)
    const cardExpiry = elements?.getElement(CardExpiryElement)
    const [stripId, setStripId] = useState()
    const [cardDetails, setCardDetails] = useState<any>([])
    const [data, setData] = useState<any>()
    const [nameOnCard, setNameOnCard] = useState('')
    const [customerError, setCustomError] = useState('')

    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserid(user_id)
        },[])
        const fetchData = async (userId) => {
          
          try {
            const queryParams = new URLSearchParams(); 
            if (userId) queryParams.append('id', userId); 
          const result = await getCompany(queryParams)
          if(result?.success === true) {
              setData(result?.data)
              console.log(result?.data);
              
          }
      }
      catch(error) {
          
      }
      }
          
          useEffect(()=> {
                if(userId) {
                  fetchData(userId)
                  getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
                }
          },[userId])
    const CreateStripCustomer = async (e)=> {
      if(!nameOnCard){
        setCustomError('INPUT.VALIDATION.INDIVIDUAL.CARD.NAME')
      }
      e.preventDefault();
          if (stripId) {
            CreateIntent({customerId:userId, 
              cardnumberElement:cardnumberElement, 
              stripe:stripe, elements:elements, 
              setIsProcessing:setIsProcessing, 
            stripId:stripId,
            setCardDetails:setCardDetails,
            email:data?.email,
            currency:'USD',
            cardcvv:cardcvv,
              cardExpiry:cardExpiry,
              setNameOnCard:setNameOnCard

            })
          }
      else {
      
        const payload = {
          cleanpig_id:userId,
          type:"company"
        }
            const res = await createStripCustomer(payload)
            if(res?.success===true) {
                if(res?.data?.customer) {
                  CreateIntent({customerId:userId, 
              cardnumberElement:cardnumberElement, 
              stripe:stripe, elements:elements, 
              setIsProcessing:setIsProcessing, 
            stripId:res?.data?.customer,
            setCardDetails:setCardDetails,
            email:data?.email,
            currency:'USD',
            cardcvv:cardcvv,
              cardExpiry:cardExpiry,
              setNameOnCard:setNameOnCard
            })
            
                }
                
            }
          }
    }
    
    
    
const stripeInputStyles = {
  base: {
    '::placeholder': {
      color: '#888',              // Placeholder color
    },


  },
  focus: {
    borderColor: '#666',          // Border color on focus
  },
  invalid: {
    color: '#e5424d',             // Color for invalid input
    borderColor: '#e5424d',       // Border color for invalid input
  },
};
useEffect(()=>{
  setCustomError('')
  },[nameOnCard])

    
  return (
    <>
      {isProcessing && <LoaderFullScreen />}
    <div className="py-3">
    <div className="pb-5">
      {/* <p><span className="font-satoshiBold"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TITLE" defaultMessage="Available Account Balance" />:</span>$0</p>
      <p>*<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TEXT" defaultMessage="Account balances are automatically applied when a task is Completed" />
  </p> */}
      </div>
      </div>
      <form onSubmit={CreateStripCustomer}>
      {elements ? (
         <>
          <Input
                           label={'Name On Card'}
                           placeholder={'Name On Card'}
                           InputDivClass="mt-0"
    inputClass="border border-zinc-800 rounded-lg h-11"
                           type={"text"}
                           onChange={(e)=> setNameOnCard(e.target.value)}
                           labelClass={'font-satoshiBold'}
                          error={customerError}
                           value={nameOnCard}
                         ></Input>
                         <label htmlFor="card-number" className="block text-normal font-medium font-satoshiBold mt-3">
      Card Number:
    </label>
  
         <div id="card-number" className="border border-zinc-800 rounded-lg h-11 mb-3 flex items-center w-full px-2">
          
            <CardNumberElement options={{ style: stripeInputStyles }} className="w-full"/>
            </div>
  
            <div className="flex space-x-3 w-full">
            <div className="w-1/2">
            <label htmlFor="card-expiry" className="block text-normal font-medium font-satoshiBold ">
      Expiry Date
    </label>
            <div id="card-expiry" className="border border-zinc-800 rounded-lg h-11 flex items-center w-full px-2">
            <CardExpiryElement options={{ style: stripeInputStyles }} className="w-full"></CardExpiryElement>
            </div>
            </div>
              <div className="w-1/2">
            <label htmlFor="card-cvc" className="block text-normal font-medium font-satoshiBold">
      CVC
    </label>
  
            <div id="card-cvc" className="border border-zinc-800 rounded-lg h-11 mb-3 flex items-center w-full px-2">
            <CardCvcElement options={{ style: stripeInputStyles }} className="w-full"></CardCvcElement>
            </div>
            </div>
          
            </div>
            <div className="flex justify-center">
            <button className="rounded-lg font-bold bg-[#2557A7] mt-5   px-5 py-2.5 hover:bg-navy  text-white  cursor-pointer" type="submit" disabled={!stripe || !elements}>
              Submit
            </button></div>
            </> ) : (
        <p>Loading payment form...</p>
      )}
    </form>
    {cardDetails?.map((item)=> (
    <div className="flex items-center space-x-3 py-3 pl-3">
            {getCardIcon(item?.card?.brand)}
            <span>**** **** **** {item?.card?.last4}</span>
        </div>
        ))
      }

      </>
  );
};
