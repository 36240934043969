import React, {useState} from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { Input, index, toAbsoluteUrl } from "sr/helpers";
import InputField from "sr/helpers/ui-components/InputField";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { FormDetails } from "sr/helpers/ui-components/FormDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { ModelWrapper } from "./component/ModelWrapper";
import { Checkbox } from "sr/helpers";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import { businessTypes, intentType } from "sr/i18n/messages/setting";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import { useFormik } from "formik";
import { registerCompany } from "sr/utils/api/company/registerCompany";
import { useDispatch } from "react-redux";
import companyRegisterSchema from "sr/utils/schemas/company/companyRegister";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "sr/utils/api/sendOTP";
import { login } from "sr/utils/api/login";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { useAuth } from "../module/auth";
import PhoneNumberFormat from "sr/helpers/functions/PhoneNumberFormat";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import { CreateWallet } from "sr/utils/api/wallet/CreateWallet";
export default function RegisterCompany() {
  const navigate = useNavigate()
  const intl = useIntl();
  const [businessType, setBusinessType] = useState([])
  const [intent, setIntent] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [mobileNo, setMobileNo]= useState()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [companyName, setCompanyName] = useState()
  const [loader, setLoader] = useState(false)
const [allowMessage, setAllowMessage] = useState(false)
const {persistToken} = useAuth()
const initialValues = {
  username: username,
  password: password,
  email: email,
  mobile_number: mobileNo,
  company_name: companyName,
  business_type: businessType,
  intent: intent,
  candidate_msg: allowMessage
};
   const {errors, submitForm} = useFormik({  
      initialValues:initialValues,  
      enableReinitialize:true,
      validationSchema:companyRegisterSchema,
      onSubmit:async (values)=> {
        try {
          setLoader(true)
        const result = await registerCompany(values)
          if(result) {
            const otpresult = await sendOTP({username:result?.username})
            const data = {
              username:username, password:password
            }
            setLoader(false)
              const loginresult = await login(data)
              if(loginresult?.success===true) {
                const data = {
                  userType:loginresult?.data?.user.type,
                  user_id:loginresult?.data?.auth.user_id,
                  jwt:loginresult?.data?.auth.jwt
                }
                const walletres = await CreateWallet({owner_id:loginresult?.data?.auth.user_id, owner_type:'COMPANY'})
              localStorage.setItem('auth', JSON.stringify(loginresult?.data?.auth))
              localStorage.setItem('auth', JSON.stringify(data))
      persistToken()
      navigate('/verify-otp')
              }

         
          }
          else {
            setLoader(false)
          }
        }
        catch(e:any) {
          setLoader(false)
          
        }

      }
   })
   
  return (
    <>
      <AuthNavComponent></AuthNavComponent>
      <main>
        <div className="lg:container mx-auto lg:max-w-6xl lg:mt-8 mt-8 px-4 lg:px-0">
          <div className="lg:flex block">
            <FormDetails />
            <div className="lg:flex-1 lg:flex block w-full flex-col h-fit lg:mx-12 mx-auto lg:p-6  px-4 py-4 bg-white border border-gray-border rounded-xl">
              <h1 className="font-satoshiBold text-xl">
                <FormattedMessage
                  id="ECOMMERCE.COMMON.CREATE_AN_ACCOUNT.TEXT"
                  defaultMessage="Create an Account"
                />
              </h1>
           
              <div className="max-w-lg">
                <form>
                <Input
                  onChange={(e:any)=> setCompanyName(e.target.value)}
                  error={errors.company_name}
                  value={companyName}
                    label={intl.formatMessage({
                      id: "RECRUITER.REGISTER.INPUT.COMPANYNAME",
                      defaultMessage: "Company Name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "RECRUITER.REGISTER.INPUT.COMPANYNAME.PLACEHOLDER",
                      defaultMessage: "Enter your Company Name",
                    })}
                    type={"text"}
                  ></Input>
                  <Input
                  value={username}
                  onChange={(e:any)=> setUsername(e.target.value)}
                  error={errors.username}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME",
                      defaultMessage: "Username",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME.PLACEHOLDER",
                      defaultMessage: "Enter your user name",
                    })}
                    type={"text"}
                  ></Input>
                  <Input
                  onChange={(e:any)=> setPassword(e.target.value)}
                  value={password}
                  error={errors.password}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.PASSWORD",
                      defaultMessage: "Password",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                      defaultMessage: "Enter your password",
                    })}
                    type={"password"}
                  ></Input>
                  <Input
                  onChange={(e)=> setMobileNo(e.target.value)}
                  value={InputNumberFormat(mobileNo)}
                  error={errors.mobile_number}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.MOBILENO",
                      defaultMessage: "Mobile Number",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
                      defaultMessage: "Enter your Mobile Number",
                    })}
                    type={"tel"}
                  ></Input>
                  <Input
                  error={errors.email}
                   onChange={(e:any)=> setEmail(e.target.value)}
                   value={email}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.EMAIL.ADDRESS",
                      defaultMessage: "Email Address",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                      defaultMessage: "Enter your email id",
                    })}
                    type={"email"}
                  ></Input>
                 
                  <div className="relative w-full">
                    <div onClick={()=> setShowModal(!showModal)}>
                  <ReactSelect 
                  label={
                    <FormattedMessage
                      id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE"
                      defaultMessage="Select Business Type"
                    />
                  }
                  placeholder="Select Business Type"
                type="text"
                onChange={(selectedOptions) => setBusinessType(selectedOptions.map(option => option.value))}
                error={errors.business_type}
                value={businessType}
                isMulti={true}
                options={businessTypes}
                defaultValue={businessTypes?.filter((item) => businessType?.includes(item?.value))}
               />
                  </div>
                  </div>
                  <div className="mt-10 mb-1 text-gray-700 text-sm font-satoshiBold"><FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.INTENT" defaultMessage="Select Intent"/>
                  </div>
                  <div className="flex justify-start space-x-3">
                    {intentType.map((data, index)=> (
                  <Checkbox labelClass='font-normal' key={index} checked={intent.includes(data.value)}  label={data.label} id={data.value} name={data.value} value={data.value} onChange={(e)=> CheckBoxHandle(e, intent, setIntent)} />
                  ))}  
                  </div>
                  <div className='mt-5'>
                    <p className="mt-10 mb-1 text-gray-700 text-sm font-satoshiBold"><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.ALLOWCANDIDATE.TITLE" defaultMessage="Do you want to allow candidates who apply to message you?" /></p> 
                    <div className="flex space-x-6">
                           <RadioInput name='Yes' value={true} label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.ALLOWCANDIDATE.YES" defaultMessage="Yes" />} checked={allowMessage} onChange={()=> {setAllowMessage(true)}}  /> 
                           <RadioInput name='No' value={false} label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.ALLOWCANDIDATE.NO" defaultMessage="No" />} checked={allowMessage===false} onChange={()=> {setAllowMessage(false)}}  />
                                    </div></div>
                  {/* <Link to="/verify-otp" replace> */}
                    
                  {/* </Link> */}
                </form>
                <ButtonAuth loader={loader}
                      onClick={() => {
                        submitForm()
                        localStorage.setItem("RegisterAs", "Company");
                      }}
                    >
                      <FormattedMessage
                        id="AUTH.GENERAL.CREATE.ACCOUNT.BUTTON"
                        defaultMessage="Create Account"
                      />
                    </ButtonAuth>
                <div className="mt-10 flex justify-center">
                  <FormattedMessage
                    id="AUTH.ALREADY.HAVE.ACCOUNT"
                    defaultMessage="Already have an account?"
                  />
                  &nbsp;&nbsp;
                  <Link to="/auth" replace>
                    <a className="cursor-pointer hover:text-pink underline">
                      {" "}
                      <FormattedMessage
                        id="AUTH.SIGN_IN_HERE"
                        defaultMessage="Sign in here"
                      />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
