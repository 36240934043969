import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import { jobTypes } from "app/pages/company/jobpost/component/Jsonalldata";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Input } from "sr/helpers";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { addConJobType } from "sr/utils/api/company/contractor/addConJobType";
import { updateConJobType } from "sr/utils/api/company/contractor/updateConJobType";
import { contractorJobTypeSchema } from "sr/utils/schemas/company/contractor/contractorJobTypeSchema";

const EditJobType = (props) => {
    const [jobType, setJobType] = useState(null)
    const [rate, setRate] = useState()
    const intl = useIntl()
    const [loader, setLoader] = useState(false)

    const initialValues = {
        job_type:jobType, 
        rate:rate,
        id:props?.currentData?.id
    }
    const {errors, handleSubmit} = useFormik({
            initialValues:initialValues,
            enableReinitialize:true,
            validationSchema:contractorJobTypeSchema,
            onSubmit:async (values)=> {
                setLoader(true)
                    try {
                        const res = await updateConJobType(values)
                                            if(res?.success===true) {
                                             setLoader(false)   
                                             props?.onCheckModel(false)
                                            }
                    }
                    catch(e){
                                setLoader(false)
                    }
            }
    })
useEffect(()=> {
setJobType(props?.currentData?.job_type)
setRate(props?.currentData?.rate)
console.log(props?.currentData);

},[props?.currentData])

return (
  <ModelWrapperWithButton Save={handleSubmit} showModal={props?.showModal} onCheckModel={props?.onCheckModel}>
    {loader && <LoaderFullScreen />}
    {jobTypes?.find((item)=>item?.value===jobType) && 
    <ReactSelect label={intl.formatMessage({
        id:'CLEANER.PROFILE.JOBTYPE.TITLE',
        defaultMessage:'Job Type'
    })} 
    type="text"
    onChange={(selectedOptions) => setJobType(selectedOptions?.value)}

    placeholder={intl.formatMessage({
        id:'CLEANER.PROFILE.JOBTYPE.INPUT.PLACEHOLDER',
        defaultMessage:'Enter Job Type'
    })}
    error={errors.job_type}
    value={jobType}
    options={jobTypes}
    defaultValue={jobTypes?.find((item)=>item?.value===jobType)}
   />
}
<Input
onChange={(e:any)=> setRate(e.target.value)}
error={errors.rate}
value={rate}
name="rate"
label={intl.formatMessage({
id: 'CLEANER.PROFILE.JOBTYPE.RATE',
defaultMessage: 'Hourly Rate',
})}
type="number"
placeholder="$"
/>
</ModelWrapperWithButton> );
};
export default EditJobType;