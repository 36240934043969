import { HandleApiError } from "sr/helpers/functions/HandleApiError"
import { createStripIntent } from "sr/utils/api/strip/createStripIntent"
import addCreditCard from "./addCreditCard"

interface ParamsType {
customerId:string
currency:string
cardnumberElement:any
    stripe:any
    elements:any
    email:string
    cardcvv:any,
    cardExpiry:any,
    setCardDetails:(params:any)=> void
    setIsProcessing:(params:any)=> void
    stripId:string
    setNameOnCard:any
}
const CreateIntent = async (params:ParamsType)=> {
    try {
      const payload = {
        currency: params?.currency,
        customer:params?.stripId
      }
        const res = await createStripIntent(payload)
        if(res?.success===true) {
          addCreditCard({clientSecret:res?.data?.client_secret, 
            setCardDetails:params?.setCardDetails, 
            email:params?.email, 
            setIsProcessing:params?.setIsProcessing, 
            stripId:params?.stripId, stripe:params?.stripe,
             elements:params?.elements,setNameOnCard:params?.setNameOnCard, customerId: params?.customerId, cardnumberElement:params?.cardnumberElement, cardcvv:params?.cardcvv, cardExpiry:params?.cardExpiry,})
             
        }
    }
    catch(e) {
HandleApiError(e)
    }
  }
export default CreateIntent;  