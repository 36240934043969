export const settingMessageDisplay = (type)=> {
    if(type==='job_accepted') {
      return 'You have accepted the job'
    }
    if(type===`job_rejected`){
      return 'You have rejected the job'
    }
    if (type==='start_job'){
      return 'You have started the job'
    }
    if(type===`end_job`){
      return 'You have completed the job'
    }
}