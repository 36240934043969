import React, { useEffect, useRef, useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import getTimeFromDate from "sr/helpers/functions/getTimeFromDate";
import { ChatBottom } from "../../component/Messages/ChatBottom";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { Input, toAbsoluteUrl } from "sr/helpers";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { FormattedMessage } from "react-intl";
import { io } from "socket.io-client";
import { formatISO } from "date-fns";
import { useParams } from "react-router-dom";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { getJob } from "sr/utils/api/individual/job/getJob";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { getIndividual } from "sr/utils/api/individual/getIndividual";
import { getMessage } from "sr/utils/api/cleaner/getMessage";
import { updateJob } from "sr/utils/api/individual/job/updateJob";
import { uploadFile } from "sr/utils/api/uploadFile";
import { getFile } from "sr/utils/api/getFile";
import { ChatImg } from "../component/ChatImg";
import { scrollToBottom } from "sr/helpers/functions/scrollToBottom";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { Rating } from "./component/Rating";
import { createfeedBack } from "sr/utils/api/company/workorder/createfeedBack";
import { CHAT_TYPE } from "sr/i18n/messages/setting";

export const ConfirmAndChat = () => {
  const [message, setMessage] = useState('')
  const [allmessage, setAllMessage] = useState([])
  const messagesEndRef = useRef()
  const [data, setData] = useState<any>()
  const [userId, setUserid] = useState()
  const [model, setModel] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [rate, setRate] = useState(null)
  const [jobId, setJobId] = useState<string>('')
  const [loader, setLoader] = useState('fetch')
  const [imageId, setImageId] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [allonlineUser, setAllOnlineUser] = useState(null)
  const [showRating, setShowRating] = useState(false)
  const socket = io(process.env.REACT_APP_SOCKET); // Connect to the server
       const {loader:userloader, getData:fetchData, data:userData} = useGet()

  useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserid(user_id)
  },[])
  useEffect(()=> {
            const fetchDetails = async (userId) => {
                const queryParams = new URLSearchParams(); 
                if (userId) queryParams.append('id', userId); 
                await fetchData(()=> getIndividual(queryParams))
              }
              if(userId) {
                fetchDetails(userId)
              }
          },[userId])
  const param = useParams()
  useEffect(()=> {
const {id} = param
setJobId(id)
  },[param])
  const onCheckModel = ()=> {
    setModel('')
  }
  const fetchJob = async ()=> {
    const queryParams = new URLSearchParams()
    queryParams.append('id', jobId)
  try {
  const res = await getJob(queryParams)
  if(res?.success===true) {
    setData(res?.data)
  }
  else {
  
  }
  }
  catch(e:any) {
    HandleApiError(e)
  }
  }
  useEffect(()=> {

if(jobId) {
  fetchJob()
  fetchChat(jobId)
}
  },[jobId])
  useEffect(()=> {
      setStartDate(data?.schedule_date)
  },[data?.schedule_date])

  useEffect(() => { 
    if (!userId) {
      return
          }
        if (socket){
          socket.emit('register', userId);
          socket.emit('getonlineusers', userId);
          socket.off('sendmessage');

        }
        socket.on('sendmessage', ({ from, message }) => {
          if(message?.recipient_id===userId){
          const currentDateTime = formatISO(new Date());
          const newmessage = {createdAt:currentDateTime, ...message}
          setAllMessage((preview)=> [...preview, newmessage])    
          }
        });
        socket.on('getonlineusers', ({users }) => {
            setAllOnlineUser(users);
        })        
        return () => {
          socket.off('sendmessage');
        };
           
      }, [userId]);
       const sendMessage = () => {
            const payload = {
                message: message,
                recipient_id: data?.cleaner_id?._id,
                sender_id:userId,
                individual_job_id:data?.id,
                file_id:imageId,
                sender_id_type:'individual',
                type:'chat'
                // Ensure this resolves to the correct recipient user ID
            };
            console.log(payload);
            
            socket.emit('sendmessage', payload); // Match the event name in the backend
            setMessage(''); // Clear the input
            const currentDateTime = formatISO(new Date());
            const newmessage = {createdAt:currentDateTime, ...payload}
            setAllMessage((preview)=> [...preview, newmessage])
            setImageId('')
            setImageUrl('')
        }
        const checkMessage = (message)=> {
            if(message=== `${userData?.first_name} ${userData?.last_name} wants Hire you`){
              return `You wants to hire ${data?.cleaner_id?.first_name} ${data?.cleaner_id?.last_name}`
            }
            if(message===`${userData?.first_name} ${userData?.last_name} changed schedule date to ${DateFormat(startDate)}`){
              return `You changed the schedule date to ${DateFormat(startDate)}`
            }
        }
            const fetchChat = async (individual_job_id)=> {
                           const queryParams = new URLSearchParams()
                           queryParams?.append('job_id', individual_job_id)
                       try {
                       const res = await getMessage(queryParams)
                       if(res?.success===true) {
                       setAllMessage(res?.data)
                         setLoader('')
                       }
                       else {
                       setLoader('')
                       }
                       }
                       catch(e) {
                       setLoader('')
                       }
                          }
const handleChange = async ()=> {
    const payload = {
      id:jobId,
    }
    const res = await updateJob(model==='changeDate' && {schedule_date:startDate, ...payload})
    if(res?.success===true) {
        if(model==='changeDate'){
          const payload = {
            message: `${userData?.first_name} ${userData?.last_name} changed schedule date to ${DateFormat(startDate)}`,
            recipient_id: data?.cleaner_id?._id,
            sender_id:userId,
            individual_job_id:data?.id,
            type:'date_change',
            sender_id_type:'individual'
        };
        socket.emit('sendmessage', payload); 
            setMessage(''); // Clear the input
            const currentDateTime = formatISO(new Date());
            const newmessage = {createdAt:currentDateTime, ...payload}
            setAllMessage((preview)=> [...preview, newmessage])
            setModel('')

        }
  } 
}
const handleImage = async (e)=> {
  setLoader('imageupload')
const formData = new FormData()
formData.append('individual_id', userId);
formData.append('chat_img', e.target.files[0]);
const result = await uploadFile(formData);
    if(result?.success===true) {
      setImageId(result?.data?.files[0].key)
      setImageUrl(URL.createObjectURL(e.target.files[0]))
      setLoader('')
    }
    else {
      setLoader('')
    }


}
const handleImageClick = ()=> {
  setImageId('')
  setImageUrl('')
}
useEffect(()=> {
        scrollToBottom(messagesEndRef)
           },[allmessage, showRating])

           const handleData = async (data)=> {
const feedbackpayload = {
    // workorder_id:workorder?.id,
    // supervisor_id:userId,
    // contractor_id:workorder?.contractor_id?._id,
    internal_supervisor_rating:data?.internalRating,
    internal_supervisor_feedback:data?.internalFeedback,
    external_supervisor_feedback:data?.externalFeedback,
    external_supervisor_rating:data?.externalFeedback
  }
  const feedbackres = await createfeedBack(feedbackpayload)
  if(feedbackres?.success===true) {
    const payload = {
      message: `${userData?.first_name} ${userData?.last_name} gives rating`,
      recipient_id: data?.cleaner_id?._id,
      sender_id:userId,
      individual_job_id:data?.id,
      type:'rating',
      sender_id_type:'individual'
  };
  socket.emit('sendmessage', payload); 
      setMessage(''); // Clear the input
      const currentDateTime = formatISO(new Date());
      const newmessage = {createdAt:currentDateTime, ...payload}
      setAllMessage((preview)=> [...preview, newmessage])
      setModel('')
      setShowRating(false)
  }
  else {

  }
           }

  return (
    <MainWrapper>
      <ContentWithSpinner loader={loader==='fetch'} height="h-screen">
 <main className="pt-3">
  {loader==='imageupload' && <LoaderFullScreen />} 
 <div className="bg-white max-w-[80%] mx-auto">
    <div className='flex justify-start items-center py-3 px-3 space-x-3'>
    <div className='text-white h-16  w-16 flex items-center justify-center relative'>
      <img className="rounded-full" src={toAbsoluteUrl('/media/img/news-2.jpg')} alt="user_img"></img>
      <span className="bg-[#3fbb46] absolute bottom-1 w-4 h-4 rounded-lg right-2 "></span>
      </div>
        <div>
        <h1 className='text-2xl font-satoshiBold text-[#595959]'>{data?.cleaner_id?.first_name} {data?.cleaner_id?.last_name}</h1>
        <p className='font-satoshi'>{allonlineUser && allonlineUser[data && data?.cleaner_id?._id] ? 'Online':'Recently seen'}</p>
        </div>
    </div>
<hr className="border-2 border-neutral-200" />
<div className="flex flex-col h-[75vh] justify-between">
  <div className="px-3 py-3">
<div className="flex justify-between items-center">
  <div>
<h1 className='text-2xl font-satoshiBold text-[#595959]'>{data?.title}</h1>
<p className='font-satoshi'>{data?.streetAddress} {data?.unitorapt}</p>
</div>
<div>
<div className="flex space-x-3">
{/* <button onClick={()=> setModel('changeRate')} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Change Rate</button> */}
{data?.work_status === 'completed' ?
<button onClick={()=> setShowRating((show)=> !show)} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Give Rating</button>

:
<button onClick={()=> setModel('changeDate')} className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Change Date</button>
}
  {/* <button  className="rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Accept</button> */}
  <button className="rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white ">Cancel</button></div>
  </div>
</div>
  </div>
  <hr className="border-2 border-neutral-200" />
<div ref={messagesEndRef} className='flex-1 h-[60vh] overflow-auto mb-3'>
<div className='text-center mb-10 mt-3'>
{DateFormat(data?.createdAt)}
</div>
<div className="flex items-center justify-center mb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black font-satoshiBold">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div className="text-center">
<div></div>
 {allmessage?.sort((a, b)=> {
  const adate = new Date(a?.createdAt)
  const bdate = new Date(b?.createdAt)

  const differenceInMilliseconds = adate.getTime() - bdate.getTime();
return differenceInMilliseconds
 }).map((item)=> (
  <div>
       {item?.sender_id === userId ?(
        item?.type==='chat' ?
         <div className='flex justify-end items-center space-x-2 px-2'>
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
       <div>
       <div className='flex-1 flex justify-start items-center space-x-2'>
       <div className='font-satoshiBold text-base'> You </div>
       <div className='text-sm'>
        {getTimeFromDate(item?.createdAt)}
       </div>
       </div>
       <div className='text-[#595959]'>
     {item?.message}
       </div>
       {item?.file_id &&
            <ChatImg item={item}></ChatImg>
       }
       </div>
   </div>
   :
   (Object.values(CHAT_TYPE)?.includes(item?.type) &&
    <div className="text-center">
   <div className="rounded-lg inline-block font-bold bg-[#2557A7] px-5 py-0.5  text-white mb-2">{checkMessage(item?.message)}

   </div>
   </div>
   )
       )
       :
       item?.type==='chat' ? 
       (
         <div className='flex justify-start items-center space-x-2 px-2 pb-6'>
       <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
       {data?.job_id?.company_id?.company_name?.slice(0,1)?.toUpperCase()} 
       </div>
       <div>
       <div className='flex-1 flex justify-start items-center space-x-2'>
       <div className='text-sm'>
      {getTimeFromDate(item?.createdAt)}
       </div>
       </div>
       <div className='text-[#595959]'>
   {item?.message}
       </div>
       {item?.file_id &&
            <ChatImg item={item}></ChatImg>
       }
       <div />
       </div>
   </div>
   ):
   (
    Object.values(CHAT_TYPE)?.includes(item?.type) &&
    <div className="text-center">
   <div className="rounded-lg inline-block font-bold bg-[#2557A7] px-5 py-0.5  text-white mb-2">{item?.message}</div>
   </div>
   )
   }
   </div>
 ))} 
 {showRating &&
  <Rating handleData={handleData}></Rating>
 }
  </div>
</div>
<ChatBottom handleImageClick={handleImageClick} imageUrl={imageUrl} uploadChange={handleImage} Send={sendMessage} onChange={(e)=> setMessage(e.target.value)} value={message} Clear={()=>setMessage('')}/>
</div></div>
</main>
</ContentWithSpinner>
{model &&
<ModelWrapperWithButton showModal={model} onCheckModel={onCheckModel} Save={handleChange}>
{
  model ==='changeDate' &&
  <ReactDatePicker
                onChange={(date)=> setStartDate(date)}
                selected={startDate}
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.INPUT.SCHEDULEDATE"
                    defaultMessage="Schedule Date"
                  />
                }
                inputClass="rounded-lg"
                labelClass="mt-2 text-black"
                InputDivClass="mt-0 w-26 pr-2 pb-2"
                type="date"
                placeholder="Schedule date"
              ></ReactDatePicker>
}

{
  model ==='changeRate' && 
 <div className="flex items-center pb-3 mb-3 space-x-3">                                     
                    <Input
                      inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      label={
                        <FormattedMessage
                          id="INDIVIDUAL.JOBS.PAYTYPE.RATE.INPUT.PLACEHOLDER"
                          defaultMessage="Enter Rate"
                        />
                      }
                      onChange={(e) => setRate(e.target.value)}
                      type="number"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-0 w-26 pr-2 pb-2"                        
                      prefix="$"
                      value={rate}
                    />
              
                  {/* {errors?.pay_type ? (
                    <p className="mt-1 text-red-400 md:text-sm sm:text-[.65rem]">
                      {validationErrorHandle(errors?.pay_type)}
                    </p>
                  ) : (
                    ""
                  )} */}
                </div>
}
</ModelWrapperWithButton>
}
  </MainWrapper>

);
};
