import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const CreateWallet = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/wallet/fabric/create', postData).then((res)=> {
    if(res?.success===true) {

    }
    else {

    }

  })
}
catch(e){

}
};
