import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import CompanyBranchInfo from "../../CompanyBranchInfo";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCompany } from "sr/utils/api/company/getCompany";
import { getBranchInfo } from "sr/utils/api/company/getBranchInfo";
import { BranchInfoView } from "./BranchInfoView";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentLoader from "react-content-loader";
import { deleteBranchInfo } from "sr/utils/api/company/deleteBranchInfo";
export const BranchInfoSettings = () => {
    const [showModal, setShowModal] = useState(false)
    const [companyId, setCompanyId] = useState()
    const [data, setData] = useState([])
    const [id, setId] = useState()
    const childRef = useRef(null);
    const [showView, setShowView] = useState(false)
    const [singleData, setSingleData] = useState()
    const [loader, setLoader] = useState(false)
    const [dataAdded, setDataAdded] = useState(false)
    const divRef = useRef(null);

  const handleButtonClick = () => {
    if (childRef.current && childRef.current.submitForm) {
      console.log(childRef.current.submitForm());
    }
  };
  const dataAddedHandle = (data)=> {
    if(data === true) {
      setShowModal(false)
      setDataAdded(true)
      setId(null)
      
    }}
    const HandleModel = (data) => {
        setShowModal(data)
        setShowView(data)
        setId(null)
    }
    useEffect(()=> {
        console.log(id);
        
    },[id])
    useEffect(()=> {
        const getdetails = getUserDetails()
        setCompanyId(getdetails.user_id)
      },[])
      const fetchData = async () => {
        setLoader(true)
          const result = await getBranchInfo(companyId, undefined)
          if(result.success === true) {
              setData(result.data)
              console.log(result.data);
              setLoader(false)
              setDataAdded(false)
              
          }
          else {
            setLoader(false)
          }
      }
    useEffect(()=> {
    
        if(companyId) {
        fetchData()
        }
    },[companyId, dataAdded])

    const HandleBranchDelete = async (id)=> {
      const formData = {
        id:id
      }
      const res = await deleteBranchInfo(formData)
      if(res.success===true) {
        fetchData()
      }
    }

  return (<>
    <div className='mb-3 pb-3 border-b border-neutral-200'>
    <div className='mt-5 flex space-x-5 justify-end'>
                                        <button
                                        onClick={()=> setShowModal(true)}
                                            className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                                        >Add Branch
                                        </button>
                                        </div>
                                        <ContentWithLoader loading={loader} content={ <ContentLoader 
    speed={2}
    width={956}
    height={600}
    viewBox="0 0 900 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="-590" y="17" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-161" y="4" rx="3" ry="3" width="52" height="9" /> 
    <rect x="-607" y="61" rx="3" ry="3" width="410" height="9" /> 
    <rect x="-821" y="118" rx="3" ry="3" width="380" height="9" /> 
    <rect x="-647" y="90" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="-219" cy="13" r="20" /> 
    <rect x="-564" y="-18" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-266" y="202" rx="3" ry="3" width="131" height="22" /> 
    <rect x="-580" y="-34" rx="0" ry="0" width="4" height="40" /> 
    <rect x="-524" y="22" rx="0" ry="0" width="157" height="3" /> 
    <rect x="-460" y="73" rx="0" ry="0" width="15" height="46" /> 
    <rect x="-541" y="79" rx="0" ry="0" width="161" height="44" /> 
    <rect x="-530" y="40" rx="0" ry="0" width="165" height="55" /> 
    <rect x="-488" y="92" rx="0" ry="0" width="200" height="63" /> 
    <rect x="26" y="40" rx="0" ry="0" width="900" height="36" /> 
    <rect x="158" y="102" rx="0" ry="0" width="2" height="1" /> 
    <rect x="-715" y="125" rx="0" ry="0" width="557" height="60" /> 
    <rect x="-531" y="97" rx="0" ry="0" width="476" height="30" /> 
    <rect x="-594" y="182" rx="0" ry="0" width="373" height="128" /> 
    <rect x="209" y="185" rx="0" ry="0" width="0" height="21" /> 
    <rect x="-563" y="123" rx="0" ry="0" width="84" height="2" /> 
    <rect x="-637" y="124" rx="0" ry="0" width="172" height="4" /> 
    <rect x="-566" y="133" rx="0" ry="0" width="141" height="12" /> 
    <rect x="-571" y="120" rx="0" ry="0" width="60" height="5" /> 
    <rect x="24" y="147" rx="0" ry="0" width="2" height="12" /> 
    <rect x="-598" y="191" rx="0" ry="0" width="132" height="11" /> 
    <rect x="-558" y="139" rx="0" ry="0" width="186" height="7" /> 
    <rect x="-647" y="187" rx="0" ry="0" width="498" height="30" /> 
    <rect x="-415" y="158" rx="0" ry="0" width="72" height="8" /> 
    <rect x="-635" y="159" rx="0" ry="0" width="481" height="32" /> 
    <rect x="124" y="187" rx="0" ry="0" width="2" height="26" /> 
    <rect x="-238" y="170" rx="0" ry="0" width="67" height="23" /> 
    <rect x="28" y="83" rx="0" ry="0" width="900" height="38" /> 
    <rect x="28" y="128" rx="0" ry="0" width="900" height="35" /> 
    <rect x="32" y="11" rx="0" ry="0" width="469" height="16" /> 
    <rect x="24" y="9" rx="0" ry="0" width="900" height="20" />
  </ContentLoader>}>
 <div  className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
           
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Branch Name
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Type
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Phone No
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Address 1
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Address 2
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                Action
                </th>
            </tr>
        </thead>
        <tbody>
        {data?.map((item, index)=> (
         <tr key={index} ref={divRef} className="bg-white border-b  hover:bg-gray-50">
        
         <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
        
             <div className='text-base cursor-pointer'>{item?.branch_name}</div> 
           
         </th>
         <td className="px-6 py-4">
         {item?.type}
         </td>
         <td className="px-6 py-4">
           {item?.phone_number}
         </td>
         <td className="px-6 py-4">
         {item?.address?.address_line_1?.slice(0, 19)}...
         
         </td>
         <td className="px-6 py-4">
         {item?.address?.address_line_2}
         
         </td>
        
         <td className="px-6 py-4">
        
         <div className='flex items-center space-x-4'>
                <div className='border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                  <div onClick={()=> {setShowModal(true);
                setId(item?.id)}}  className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
</svg>
</div>
<div onClick={()=> {setShowView(true)
setSingleData(item)}} className={`py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6] ${item?.isDefaultBranch ? 'hover:rounded-r-lg' : 'hover:rounded-l-lg'}`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
</div>
{!item?.isDefaultBranch && (
  <div onClick={()=> HandleBranchDelete(item?.id)} className='py-1.5 px-2 cursor-pointer hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]'>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
)}



                </div></div>
        </td>

     </tr>
))}
          
        </tbody>
    </table>
</div>
</ContentWithLoader>
        </div>
   <ModelWrapperWithButton Save={handleButtonClick} showModal = {showModal} onCheckModel = {HandleModel}>
   {id ? <CompanyBranchInfo typec={'setting'} method={'put'} id={id} ref={childRef} dataAddedHandle={dataAddedHandle}></CompanyBranchInfo>:<CompanyBranchInfo typec={'setting'} ref={childRef} dataAddedHandle={dataAddedHandle}></CompanyBranchInfo>}
   </ModelWrapperWithButton>
   <ModelWrapperWithButton nofooter={true} showModal = {showView} onCheckModel = {HandleModel}>
   {data && <BranchInfoView data={singleData} ></BranchInfoView>}
   </ModelWrapperWithButton>
   </> );
};
