import React, { ForwardRefRenderFunction, forwardRef, useEffect, useState } from 'react'
import { Input } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getIndividual } from 'sr/utils/api/individual/getIndividual'
import { useFormik } from 'formik'
import { changePassword } from 'sr/utils/api/changePassword'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
 const PasswordChange: ForwardRefRenderFunction<any, any> = ({HandleData, HandleModel}, ref) => {
  interface data {
    company_name: string;
    password: string;
  }
  const [password, setPassword] = useState()
  const [currentPassword, setCurrentPassword] = useState()
  const [userId, setUserId] = useState()
  const [id, setId] = useState()
  const [loader, setLoader] = useState(true)
  const PasswordChangeSchema = Yup.object({
    password:Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
    old_password:Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER')
  })
  const initialValue = {
    user_id:id,
    password:password,
    old_password:currentPassword
  }
  const intl = useIntl()
  useEffect(()=> {
    const {user_id} = getUserDetails()
    console.log(user_id);
    
    setUserId(user_id)
    },[])

    useEffect(()=> {
      const fetchData = async () => {
        setLoader(true)
        try {
          const queryParams = new URLSearchParams(); 
          if (userId) queryParams.append('id', userId); 
        const result = await getIndividual(queryParams)
        if(result?.success === true) {
          setId(result?.data?.user_id)
          setLoader(false)
        }
    }
    catch(error) {
    }
    }
    if(userId) {
      fetchData()
    }
    },[userId])
    const {errors, submitForm} = useFormik({
      initialValues:initialValue,
      enableReinitialize:true,
      validationSchema:PasswordChangeSchema,
      onSubmit: async (value)=> {
            const res = await changePassword(value)
            if(res?.success===true) {
              HandleModel(false)
            }
      }
    })
    
    React.useImperativeHandle(ref, () => ({
      submitForm
    }));
  return (
    <>
    {loader && <LoaderFullScreen />}
   <Input
error={errors?.password}
onChange={(e)=> {setPassword(e.target.value)}}
  type="password"
  InputDivClass="mt-0"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.INPUT.NEWPASS',
    defaultMessage: 'New password',
  })}
  
/>
<Input
error={errors?.old_password}
onChange={(e)=> {setCurrentPassword(e.target.value)}}
  type="password"
  InputDivClass="mt-5"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.INPUT.CURRENTPASS',
    defaultMessage: 'Current password',
  })}
  
/>    </>
  )
}
export default forwardRef(PasswordChange)