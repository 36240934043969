import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createStripPayment = async  (postData) => {
    try{
  return await post<ApiResponse<[]>>('/stripepayments/intent/create', postData).then((res)=>{
if(res?.success===true){
return res
}
else{

}
  })
}
catch(e){

}
};
