import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { remove } from "sr/utils/axios";

export const deleteConJobType = async (payLoad) => {
    try {
  return await remove<ApiResponse<[]>>('/contractor/jobtype', payLoad).then((res)=> {
        if(res?.success===true) {
                SuccessMessage('API.SUCCESS.CONTRACTOR.JOBTYPE.DELETE')
                return res
        }
        else {
                return null
        }
  })
}
catch(e) {
    HandleApiError(e)
}
};
