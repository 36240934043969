import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "./Form";
import { Button } from "sr/helpers/ui-components/company/Button";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { useFormik } from "formik";
import { employment } from "app/pages/company/jobpost/component/Jsonalldata";
import { useParams } from "react-router-dom";
import { getContractorHire } from "sr/utils/api/company/contractor/getContractorHire";
import { contractorHireSchema } from "sr/utils/schemas/company/contractor/contractorHireSchema";
import { updateContractorHire } from "sr/utils/api/company/contractor/updateContractorHire";

export const General = (props) => {
    const [joiningDate, setJoiningDate] = useState(new Date());
    const [currentEmployment, setCurrentEmployment] = useState()
    const [destination, setDestination]= useState()
    const [hourlyRate, setHourlyRate] = useState()
    const [id, setId] = useState('')
    const [hireId, setHireId] = useState('')
    const childRef:any = useRef()
    const params = useParams()
    const [errors, setErrors] = useState(null)
    const initialValue = {
        joining_date:joiningDate,
        employment_type:currentEmployment,
          destination:destination,
          rate:hourlyRate,
          id:hireId
      }


    
    useEffect(()=> {
        const {id} = params
        setId(id)
  },[params])
const getHireDetails = async ()=> {
    const queryParams = new URLSearchParams()
    queryParams?.append('contractor_id', id)
try {
const res = await getContractorHire(queryParams)
if(res?.success===true) {
    console.log(res?.data);
    
setJoiningDate(res?.data?.joining_date)
setCurrentEmployment(res?.data?.employment_type)
setHourlyRate(res?.data?.rate)
setDestination(res?.data?.destination)
setHireId(res?.data?.id)
}
}
catch(e){
    
}
}
useEffect(()=> {
if(id) {
    getHireDetails()
}
},[id])
const intl = useIntl()

const HandleEditSave = async ()=> {
  if (childRef.current && childRef.current.submitForm) {
    childRef.current.submitForm()
  }
    try {
      const validData = contractorHireSchema.validateSync(initialValue, { abortEarly: false });
       const res = await updateContractorHire(initialValue)
       if(res?.success===true){
        getHireDetails()
       }
    }
    catch (err) {
      const fieldErrors = {};
err.inner.forEach((error) => {
  fieldErrors[error.path] = error.message;
});

      setErrors(fieldErrors)
    
    }
}
const dataAddedHandle = (data)=> {
  if(data === true) {
    
  }
}

  return (<>
    <Form id={props?.data?.id} type="popup" ref={childRef} dataAddedHandle={dataAddedHandle}></Form>
    <ReactDatePicker
    error = {errors?.joining_date}
              onChange={(date) => setJoiningDate(date)}
              selected={joiningDate}
              label={
                <FormattedMessage
                  id="RECRUITER.CANDIDATES.HIRE.JOINING.DATE"
                  defaultMessage="Joining Date"
                />
              }
              inputClass="rounded-lg"
              labelClass="mt-2 text-black"
              InputDivClass="mt-10 w-26 pr-2"
              type="date"
              placeholder="Joining Date"
            ></ReactDatePicker>
    <Input  error={errors?.destination} labelClass="mt-2 text-black text-normal font-satoshiBold" value={destination} onChange={(e)=> setDestination(e.target.value)} type="text" label="Destination" placeholder="Enter Destination" InputDivClass="mt-10"></Input>        
<DropDown error={errors?.employment_type} labelClass={'mt-2 text-black text-normal font-satoshiBold'} dropdowndiv="mb-5 mt-10" label={<FormattedMessage id="CLEANER.JOBS.FILTER.EMPLOYMENTTYPE" ></FormattedMessage>} value={currentEmployment} onChange={(e:any)=> setCurrentEmployment(e.target.value)}>
{employment?.map((item)=> (
<option value={item?.value}>{item?.label}    
</option>
))}
</DropDown>
<Input 
                 onChange={(e:any)=> setHourlyRate(e.target.value)}
                  label = "Pay Rate (Hourly)"
                  placeholder="Enter Pay Rate (Hourly)"
                  type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'
                    InputDivClass = 'h-full w-full mt-10'
                    labelClass={'mt-2 text-sm text-normal font-satoshiBold'}
                    value={hourlyRate}
                    min={1}
                    error={errors?.rate}
                 />
    <Button onClick={HandleEditSave} text={<FormattedMessage id="RECRUITER.CUSTOMER.SAVE.BUTTON" defaultMessage="Save" />}></Button>
    
    </>
);
};
