import { getWalletBalance } from "sr/utils/api/wallet/getWalletBalance"

 interface typeParams {
    userId?:string,
    setLoader?:(params:any)=> void
    setData?:(params:any)=> void
 }
 export const fetchBalance = async (params:typeParams)=>{
    if (params?.setLoader) params?.setLoader(true)
        try {
            const queryParams = new URLSearchParams()
            queryParams.append('tokenId', '1271231')
              queryParams.append('id', params?.userId)
              const walletres = await getWalletBalance(queryParams)
              if(walletres?.success===true){
                if (params?.setLoader) params?.setLoader(false)
                  if (params?.setLoader) params?.setData(walletres?.data?.balance)
                
              }
              else {
              }
            }
              
            catch(e){
              
            }
      }