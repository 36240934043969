import React, {useState, useEffect, useRef} from "react";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
interface propsType {
  setStartTime:(e:any)=> void,
  setEndTime:(e:any)=> void
}
export const CleanerFilter = (props:propsType) => {
    const [minRange, setMinRange] = useState(10)
    const [maxRange, setMaxRange] = useState(40)
    const minRangeRef = useRef<HTMLInputElement>(null);
    const maxRangeRef = useRef<HTMLInputElement>(null);
    const [leftStyle, setleftStyle] = useState("")
    const [rightStyle, setrightStyle] = useState("")
    const intl = useIntl()
  const TimeData = [
    {label:"Morning (8:00 - 12:00)", value:'8:00-12:00'},
     {label:"Afternoon (12:00 - 17:00)", value:'12:00 - 17:00'}, 
     {label:"Evening (17:00 - 21:00)", value:'17:00 - 21:00'},
    ]
const [TimeofDay, setTimeofDay] = useState("")
  useEffect(()=> {
    if(TimeofDay) {
      const time = TimeofDay.split(':')
      props?.setStartTime(time[0])
      props?.setEndTime(time[1])
    }
  }, [TimeofDay])
useEffect(()=> {
  const gapRange = 10;
if(maxRange-minRange<gapRange) {
if(minRangeRef.current) {
  setMinRange(maxRange-gapRange)
}
else {
  setMaxRange(minRange+gapRange)
}
}
else {
setleftStyle((minRange/100)*100+"%")
setrightStyle((1-maxRange/100)*100+"%")
}
},[minRange, maxRange])
const timeHandle = (value)=> {

}

  return (
    <div className="col-span-4 bg-white border border-neutral-300 rounded-lg px-3 mb-3 lg:mb-0 h-fit">
    <div className="p-5">
      <p className="text-base font-satoshiBold pb-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.TIMEOFDAY." defaultMessage="Time of Day" /></p>
        {TimeData.map((item, index) => (
            <RadioInput
    key={index}
  name="filter"
  value={item.value}
  checked={TimeofDay === item.value}
  onChange={(e)=> setTimeofDay(e.target.value)}
  label={item.label}
/>
        ))}
     
    </div>
        <p className="border-b text-center leading-[1.5px] border-neutral-300"></p>
 
  <div className="border-b border-neutral-300 p-5">
      <p className="text-base font-satoshiBold pb-8"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.PRICE.TITLE." defaultMessage="Price" /></p>
     <div className="w-full rounded-full relative bg-gray-200 h-1.5">
        <div className={`bg-[#2557A7] rounded-full absolute left-0 right-0 h-1.5`} style={{left:leftStyle, right:rightStyle}}></div>
     </div>
     <div className="relative  mb-3">
        <input ref={minRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input"  value={minRange} type="range" min="0" max="100" onChange={(e:any)=> setMinRange(e.target.value)}></input>
        <input ref={maxRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input" value={maxRange} type="range" min="0" max="100" onChange={(e:any)=> setMaxRange(e.target.value)}></input>
     </div>
     <div className="flex justify-between w-full">
      <span>${minRange}</span>
      <span>${maxRange}</span>
     </div>
     <p className="text-center pt-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.PRICE.TEXT" defaultMessage="The average hourly rate is" /><span className="font-satoshiBold"> $56.69/hr</span></p>
      </div>
      <div className=" border-b border-neutral-300 p-5">
      </div>
    </div>
  );
};
