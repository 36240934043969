export const TableRow = () => {
  return (
    <>
    
    <tr className="bg-white border-b  hover:bg-gray-50">
      
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
     12/04/2024
        </th>
        <td className="px-6 py-4">
<div className="text-base cursor-pointer">
   Completed
</div>
        </td>
        <td className="px-6 py-4">
           earned from individual Job Completed Bathroom Cleaner
        </td>
      

        <td className="px-6 py-4">
    Shobhit Sinha
        </td>


        <td className="px-6 py-4">
        FRM45622
        </td>
        <td className="px-6 py-4">
20$
  </td>
    </tr>
</>
     );
};
