import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { ActionBtn } from './ActionBtn'
import { JobPostEdit } from './JobPostEdit'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { individualJobStatus } from 'sr/i18n/messages/setting'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getJobType } from 'sr/utils/api/cleaner/getJobType'
import { updateJob } from 'sr/utils/api/individual/job/updateJob'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { toast } from 'react-toastify'
export const TableRow = ({item, setData}) => {
    const [editModel, setEditModel] = useState(false)
    const [rate, setRate] = useState()
    const [loader, setLoader] = useState(false)
    const HandleModelByProps = (data) => {
        setEditModel(data)
       }
       const fetchJobType = async ()=> {
        try {
        const res = await getJobType(item?.cleaner_id?._id, null)
        if(res?.success===true) {
            res?.data?.map(item1 => {
             if(item1?.job_type[0]===item?.title){
              setRate(item1?.rate)
             }
              
        })

        }
        else {
            return null
        }
    }
    catch(e) {

    }

       }
       useEffect(()=> {
if(item?.cleaner_id?._id) {
    fetchJobType()
}
       },[])
       const handleDelete = async ()=> {
        setLoader(true)
        try {
        const payload = {
                    id:item?.id,
                    status:'deleted'
        }
        const res = await updateJob(payload)
        if(res?.success===true){
setLoader(false)
setData((oldData)=>oldData?.filter((data)=>data?.id !== res?.data?.id))
toast.success('Deleted Successfully')

        }
        else {
            setLoader(false)
        }
    }
    catch(e){
            setLoader(false)
    }


       }
  return (
    <>
      {loader && <LoaderFullScreen />}
        <tr className="bg-white border-b  hover:bg-gray-50">
            
            <td className="w-4 p-4">
             {item?.title}
            </td>
          
            <td className="px-6 py-4">
                {`${item?.streetAddress} ${item?.unitorapt}`}
            </td>
           
            <td className="px-6 py-4">
          {rate &&  `$${rate}`}
            </td>
    
            <td className="px-6 py-4">
            {DateFormat(item?.schedule_date)}
            </td>
            <td className="px-6 py-4">
            {item?.start_time}
            </td>
            <td className="px-6 py-4">
           
            {getLabelByValue(item?.work_status, individualJobStatus)}
            </td>
            <td className="px-6 py-4">
           
            <ActionBtn deleteOnClick={handleDelete} editonClick={()=> setEditModel(!editModel)} jobId={item?.id}/>
           </td>   
        </tr>
        {editModel ?
    <JobPostEdit editModel={editModel} HandleModelByProps={HandleModelByProps}/>: null}
  </>)
}
