import * as Yup from 'yup'

const CustomerSchema = Yup.object({
    name:Yup.string().required('INPUT.VALIDATION.CUSTOMER.NAME'),
    mobile_number:Yup.string().required('INPUT.VALIDATION.CUSTOMER.MOBILE.NO'),
    email:Yup.string().email('INPUT.VALIDATION.CUSTOMER.EMAIL.INVALID').required('INPUT.VALIDATION.CUSTOMER.EMAIL').test(
        'no-spaces',
        'INPUT.VALIDATION.CUSTOMER.EMAIL.SPACE',
        (value) => !/\s/.test(value || '')),
    type:Yup.string().required('INPUT.VALIDATION.CUSTOMER.TYPE'),
    remarks:Yup.string()
})

export default CustomerSchema;